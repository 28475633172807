@use "sass:math";

//// import all variables as first ////
@import "00_Variables/Variables";

// Configuration -- import by Variables.scss
// @import "bootstrap/scss/functions";
// @import "bootstrap/scss/variables";
// @import "bootstrap/scss/mixins";
// @import "bootstrap/scss/utilities";

// Layout & components
// @import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
// @import "bootstrap/scss/type";
// @import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
//@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
// @import "bootstrap/scss/button-group";
// @import "bootstrap/scss/nav";
// @import "bootstrap/scss/navbar";
// @import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
// @import "bootstrap/scss/badge";
// @import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
// @import "bootstrap/scss/list-group";
// @import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
// @import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
// @import "bootstrap/scss/spinners";
// @import "bootstrap/scss/offcanvas";
// @import "bootstrap/scss/placeholders";

// Helpers
@import "bootstrap/scss/helpers/clearfix";
@import "bootstrap/scss/helpers/colored-links";
@import "bootstrap/scss/helpers/ratio";
@import "bootstrap/scss/helpers/position";
@import "bootstrap/scss/helpers/stacks";
@import "bootstrap/scss/helpers/visually-hidden";
@import "bootstrap/scss/helpers/stretched-link";
@import "bootstrap/scss/helpers/text-truncation";
@import "bootstrap/scss/helpers/vr";

// Utilities
@import "bootstrap/scss/utilities/api";


//// import mmenu ////
@import 'mmenu-light/dist/mmenu-light';


//// import fancybox ////
@function max($numbers...) {
	@return m#{a}x(#{$numbers});
}

@function min($numbers...) {
	@return m#{i}n(#{$numbers});
}

@import '@fancyapps/fancybox/dist/jquery.fancybox.min';
@import '@splidejs/splide/css';

//// import design ////
@import "05_Overrides_BootstrapComponents/Button/CheesefinderButton";
@import "05_Overrides_BootstrapComponents/Button/Button";
@import "05_Overrides_BootstrapComponents/Forms";
@import "05_Overrides_BootstrapComponents/Pagination";
@import "05_Overrides_BootstrapComponents/Icons";
@import "05_Overrides_BootstrapComponents/Pagination";
@import "05_Overrides_BootstrapComponents/Paragraph";
@import "05_Overrides_BootstrapComponents/Tooltip";


@import "10_Layout/Global";
@import "10_Layout/HtmlElements";
@import "10_Layout/Header";
@import "10_Layout/HeaderMobile";
@import "10_Layout/Footer";
@import "10_Layout/Headlines";
@import "10_Layout/Grid";
@import "10_Layout/Animation";
@import "10_Layout/Container";

@import "20_ContentElements/Spaces";
@import "20_ContentElements/Divider";
@import "20_ContentElements/Tables";
@import "20_ContentElements/Uploads";
@import "20_ContentElements/RteClasses";
@import "20_ContentElements/Image";
@import "20_ContentElements/Lists";

@import "30_Extensions/News";
@import "30_Extensions/CookieHint";
@import "30_Extensions/Form";
@import "30_Extensions/Popup";

// TODO: test
@import "20_ContentElements/Tags";
@import "20_ContentElements/StatisticDoughnut/Doughnut";
@import "20_ContentElements/ImageAndText"; // all text image items
@import "20_ContentElements/Box2images/Box2images";
@import "20_ContentElements/ImageText/ImageText";
@import "20_ContentElements/BigImage/BigImage";
@import "20_ContentElements/Quote/Quote";
@import "20_ContentElements/TextSmallImage/TextSmallImage";
@import "20_ContentElements/DropdownShare/DropdownShare";

@import "20_ContentElements/Card/Card";
@import "20_ContentElements/Card/CheeseCard";
@import "20_ContentElements/Card/StoryCard";
@import "20_ContentElements/Card/ReciepCard";

@import "20_ContentElements/CheeseAttribute/CheeseAttribute";
@import "20_ContentElements/Contactbox/Contactbox";
@import "20_ContentElements/Gallery/Gallery";
@import "20_ContentElements/DownloadContent/DownloadContent";
@import "20_ContentElements/Focusteaser/Focusteaser";
@import "20_ContentElements/TeaserCheesefinder/TeaserCheesefinder";

@import "20_ContentElements/Splide/Splide";
@import "20_ContentElements/Splide/SplideCheese";
@import "20_ContentElements/Splide/SplideReciep";

@import "20_ContentElements/Sponsors/Sponsors";
@import "20_ContentElements/Finder/Finder";
@import "20_ContentElements/recipeIngredient/recipeIngredient";
@import "20_ContentElements/recipeIngredient/reciperating";
@import "20_ContentElements/Headersearch/Headerserach";
@import "20_ContentElements/Storyteaserbig/Storyteaserbig";
@import "20_ContentElements/Accordion/Accordion";
@import "20_ContentElements/Textblock/Textblock";
@import "20_ContentElements/Anchorlinks/Anchorlinks";
@import "20_ContentElements/Forms/Forms";
@import "20_ContentElements/Forms/Multiselect";
@import "20_ContentElements/ModalChangePlatform/ModalChangePlatform";
@import "20_ContentElements/Vision/Vision";
@import "20_ContentElements/Search/Search";
@import "20_ContentElements/Infoblock/Infoblock";
@import "20_ContentElements/ContestOverlay/ContestOverlay";
@import "20_ContentElements/Popup/Popup";
@import "20_ContentElements/Glossar/Glossar";
@import "20_ContentElements/VideoPortraitFormat/VideoPortraitFormat";

@import "20_ContentElements/Infographic/Infographic";


@import "40_Menus/MainMenu";
@import "40_Menus/MainSubMenu";
@import "40_Menus/MobileMenu";
@import "40_Menus/MetaMenu";
@import "40_Menus/LangSwitch";
@import "40_Menus/Breadcrumb";
@import "40_Menus/FooterMenu";

// hotfix disable https://secure.adnxs.com image
body > img[width="1"]{
		display: none;
}

// move to seperate file
@include media-breakpoint-down(md) {
    .row,
    :root {
        --bs-gutter-x: 20px;
    }
}
