.element-statistic-doughnut {
  .statistics-title {
    margin-bottom: 20px;
  }

  .chart-wrapper {
    position: relative;
    container-type: inline-size;
  }

  .chart-container {
    position: relative;
    margin: auto;
    width: 100%;
    aspect-ratio: 1;
  }
}
