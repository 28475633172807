/* Create a custom radio button */
$checkbox-offset: 40px;
$checkbox-width: 16px;
$checkbox-border: 2px;
$checkbox-whitespace: 1px;

// multicheckbox
.MultiCheckbox >.control-label,
.control-label--rdaio{
    font-size: var(--body-md);
    margin-top: $spacer;
    margin-bottom: $spacer;
}

.multiselect-dropdown,
.radio,
.form-check{
    display: block;
    padding: 0;
    .form-check-label{
        font-size: var(--body-md);
        font-weight: var(--body-medium);
        line-height: var(--body-mid);
        cursor: pointer;
    }
    input[type="radio"],
    input[type="checkbox"]{
        width: 0;
        height: 0;
        opacity: 0;
        float: left;
        display: inline;
    }
    label > span,
    .custom-control-label{
        padding: 0 0 0 #{$checkbox-offset};
        float: left;
    }
    input[type="radio"],
    input[type="checkbox"]{
        & ~ span,
        & ~ .custom-control-label{
            position: relative;
            display: block;
            width: 100%;

            font-size: var(--body-xs);
            font-weight: var(--body-medium);
            
            &::after,
            &::before{
                content: "";
                position: absolute;
            }
            &::after{
                top: #{$checkbox-border + $checkbox-whitespace};
                left: #{ $checkbox-border + $checkbox-whitespace};
                height: #{$checkbox-width - $checkbox-border * 2 - $checkbox-whitespace * 2};
                width: #{$checkbox-width - $checkbox-border * 2 - $checkbox-whitespace * 2};
                background-color: var(--color-body);
                border-radius: 1px;

                transform: scale(0);
                transition: transform .2s cubic-bezier(0.39, 0.575, 0.565, 1);
            }
            &::before{
                top: 0;
                left: 0;
                height: $checkbox-width;
                width: $checkbox-width;
                border: $checkbox-border solid var(--color-body);
                border-radius: 3px;
                
            }
        }
        
        &:hover ~ span::after,
        &:hover ~ .custom-control-label::after{
            transform: scale(.75);
        }
        
        &:checked ~ span::after,
        &:checked ~ .custom-control-label::after{
            transform: scale(1);
        }
    }
}

label.radio-label span{
    float: initial;
    padding: 0;
}