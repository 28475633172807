.card--story{
    margin-top: #{$spacer * 6};
}
.card__text--story{
    margin-top: #{$spacer * 3};
}
.content-storycards__title,
.content-reciepecards__title{
    display: flex;
    align-items: center;
    margin-bottom: 0;
    @include media-breakpoint-up(md) {
        max-width: 50%;
        width: auto;
    }
}
