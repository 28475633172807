// Override if you use it in your theme

:root {
	--bs-primary: var(--color-primary);
	--bs-secondary: var(--color-secondary);

	--bs-font-sans-serif: 'ABC Monument Grotesk', Arial, Verdana, Helvetica, sans-serif;
	--bs-headings-font-family: 'Blacker Display', Times, serif;
	--bs-body-font-family: var(--bs-font-sans-serif);
	--bs-body-font-size: 1rem;
	--bs-body-font-weight: 400;
	--bs-body-line-height: 140%;
	--bs-body-color: var(--color-body);
	--bs-body-bg: var(--color-grey);

	// js replace enable browserzoom
	--viewport-width: 100%; 
	--viewport-height: 100%; 

	--body-xxs: 10px;
	--body-xs: 12px;
	--body-sm: 14px;
	--body-md: 16px;
	--body-lg: 18px;
	--body-xl: 21px;

	--body-regular: 400;
	--body-medium: 500;

	--body-high: 140%;
	--body-mid: 130%;
	--body-mid-low: 120%;
	--body-low: 100%;

	--body-size: var(--body-md);
	--body-weight: var(--body-regular);
	--body-height: var(--body-high);
}


/*
// CSS Variables
//

:root {
	--bs-blue: #0d6efd;
	--bs-indigo: #6610f2;
	--bs-purple: #6f42c1;
	--bs-pink: #d63384;
	--bs-red: #dc3545;
	--bs-orange: #fd7e14;
	--bs-yellow: #ffc107;
	--bs-green: #198754;
	--bs-teal: #20c997;
	--bs-cyan: #0dcaf0;
	--bs-white: #fff;
	--bs-gray: #6c757d;
	--bs-gray-dark: #343a40;
	--bs-gray-100: #f8f9fa;
	--bs-gray-200: #e9ecef;
	--bs-gray-300: #dee2e6;
	--bs-gray-400: #ced4da;
	--bs-gray-500: #adb5bd;
	--bs-gray-600: #6c757d;
	--bs-gray-700: #495057;
	--bs-gray-800: #343a40;
	--bs-gray-900: #212529;

	--bs-primary: #0d6efd;
	--bs-secondary: #6c757d;
	--bs-success: #198754;
	--bs-info: #0dcaf0;
	--bs-warning: #ffc107;
	--bs-danger: #dc3545;
	--bs-light: #f8f9fa;
	--bs-dark: #212529;

	--bs-primary-rgb: 13, 110, 253;
	--bs-secondary-rgb: 108, 117, 125;
	--bs-success-rgb: 25, 135, 84;
	--bs-info-rgb: 13, 202, 240;
	--bs-warning-rgb: 255, 193, 7;
	--bs-danger-rgb: 220, 53, 69;
	--bs-light-rgb: 248, 249, 250;
	--bs-dark-rgb: 33, 37, 41;
	--bs-white-rgb: 255, 255, 255;
	--bs-black-rgb: 0, 0, 0;
	--bs-body-rgb: 33, 37, 41;

	--bs-font-sans-serif: 'IBM Plex Sans', Arial, Verdana, Helvetica, sans-serif;
	--bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
	--bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
	--bs-body-font-family: var(--bs-font-sans-serif);
	--bs-body-font-size: 1rem;
	--bs-body-font-weight: 400;
	--bs-body-line-height: 1.5;
	--bs-body-color: #212529;
	--bs-body-bg: #fff;
}
*/
