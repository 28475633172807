.textblock__title{
    margin-bottom: 60px;
    @include media-breakpoint-down(md){
        margin-bottom: 30px;
    }
}
.textblock__text{
    ul,
    p{
        font-size: var(--body-lg);
        max-width: 800px;
        @include media-breakpoint-down(md){
            font-size: var(--body-md);
        }
    }
}