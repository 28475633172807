// contactbox
.contactbox{
	position: relative;
	padding-top: 81px;
	padding-bottom: 81px;
	&:after{
		content: "";
		position: absolute;
		top: 0;
		height: 100%;
		width: calc(100% - var(--bs-gutter-x));
		left: calc(var(--bs-gutter-x) * .5);
		background: var(--color-white);
		z-index: -1;
	}
}
.contactbox--map{
	background: none;
	&:after{
		content: "";
		width: calc(100% - var(--bs-gutter-x));
		height: 100%;
		position: absolute;
		top: 0;
		left: calc(var(--bs-gutter-x) * .5);
		background: var(--color-white);
		z-index: -1;
	}
}
.contactbox__inner{
	width: 100%;
	@include media-breakpoint-down(lg) {
		padding: 0 calc(var(--bs-gutter-x) * 2);
	}
	@include media-breakpoint-up(lg) {
		@include make-col(4, 5);
		margin-left: calc(20% - var(--bs-gutter-x) * .5);
	}
}
.contactbox__image,
.contactbox__text,
.contactbox__link,
.contactbox__social{
	margin: 0 0 #{$spacer * 4};
	@include media-breakpoint-down(lg) {
		margin: 0 0 #{$spacer * 2};
	}
	ul{
		
	}
}
ul.contactbox__list{
	font-size: var(--body-sm);
	padding: 0;
	margin-bottom: #{$spacer};
	li{
		list-style: none;
	}
}
.contactbox__btn-social-link{
	@include media-breakpoint-down(lg) {
		margin-right: calc(var(--bs-gutter-x) * .5);
		margin-bottom: #{$spacer};
	}
}
.contactbox__link{
	margin-bottom: 0;
}
.contactbox__image{
	width: 50%;
    max-width: 200px;
}

// google maps
#map{
    width: calc( 100% + var(--container-padding-x));
    height: 100%;
	min-height: 70vh;
    
    @include media-breakpoint-down(md){
        height: 70vh;
        width: calc( 100% + var(--container-padding-x) * 2);
        margin-left: calc(var(--container-padding-x) * -1);
        margin-top: 90px;
    }
}