html,
body {
  font-size: var(--body-md);
  font-kerning: normal;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  font-smoothing: antialiased !important;
  font-feature-settings: 'ss01' on;

  @include media-breakpoint-down(md) {
    font-size: var(--body-sm);
  }
  &.no-scroll {
    overflow: hidden;
  }
}
html {
  overflow-x: hidden;
}

body {
  position: relative;
  overflow: initial; // sticky
}

// browserzoom
header .headercontent,
header .headersearch-container,
main,
footer {
  max-width: var(--viewport-width);
  margin-left: auto;
  margin-right: auto;
}
// browserzoom

.page {
  position: relative;
}

.hiddenText {
  position: absolute;
  text-indent: -9999px;
  overflow: hidden;
  width: 0;
}

p {
  font-size: var(--body-size);
  font-weight: var(--body-weight);
  line-height: var(--body-height);
}

@media (hover: none) {
  a:hover {
    color: var(--color-body);
  }
}

@mixin unerlineAnimaiton {
  text-decoration: none;
  background: linear-gradient(var(--color-active), var(--color-active)) bottom/0 0.05em no-repeat;
  background-position: left bottom;
  word-break: break-word;
  overflow-wrap: break-word;
  transition: background-size 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  @include hoverDeviceQuery {
    &:hover {
      background-size: 100% 0.05em;
      color: var(--color-active);
    }
  }
}

// Several lines underline
// https://codepen.io/dnruben/pen/MNdOdw
a.underline,
a.mainmenu__link-sub,
.admin-form-header__breadcrump a,
.accoriondownloadlist a,
footer a,
a.link--body {
  color: var(--color-body);
  @include unerlineAnimaiton;
}

.form-check-label a,
p:not(.btn--rte) a:not([class*='tooltip--link']),
p:not(.btn--rte) a:not([class*='btn']) {
  color: var(--color-active);
  @include unerlineAnimaiton;
}
