.modal-change-platform{

}
.modal-dialog-change-platform{
    background: var(--color-white);
    max-width: 700px;
    @include media-breakpoint-down(md){
        max-width: 500px;
    }
}
.modal-content-change-platform{
    border: 0;
    border-radius: 0;
}
.modal-col{
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(md){
        flex-direction: row;
        justify-content: stretch;
    }
}
.modal-header{
    border-bottom: none;
}
.modal-header-change-platform{
    padding: 40px 40px 20px;
    border: 0;
    @include media-breakpoint-up(md){
        width: 100%;
    }
}
.modal-header-change-platform-business{
    padding: 40px;
    border: 0;
    justify-content: flex-start;
    align-items: flex-start;
    @include media-breakpoint-up(md){
        width: auto;
    }
}
.modal-title-change-platform{
    font-size: var(--body-xl);
    font-weight: var(--body-regular);
}
.modal-body-change-platform{
    font-size: var(--body-md);
    border: 0;
    padding: 0 40px 40px 40px;
}
.modal-body-change-platform-business{
    font-size: var(--body-md);
    border: 0;
    padding: 0 40px 40px 40px;
    @include media-breakpoint-up(md){
        padding: 40px;
    }
    h5{
        padding-bottom: 15px;
    }
}
.modal-footer-change-platform-business,
.modal-footer-change-platform{
    border: 0;
    align-items: flex-end;
    justify-content: flex-start;
    @include media-breakpoint-up(md){
        padding: 0 40px 40px 0;
        justify-content: flex-end;
    }
}
.modal-footer-change-platform{
    padding: 0 40px 40px 40px;
}
.modal-footer-change-platform-business{
    justify-content: flex-start;
    @include media-breakpoint-down(md){
        padding: 0 40px 40px;
    }

}