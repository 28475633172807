.metaMenu {
	padding: 13px 0 14px;
	ul {
		margin: 0;
		padding: 0;
	}
	li{
		@include media-breakpoint-down(md){
			margin-bottom: 12px;
		}
	}
	.no,
	.act {
		list-style: none;

		a {
			display: inline-block;
			font-size: var(--body-lg);
			color: var(--color-body);
			padding: $metamenu-padding;
			transition: transform 0.18s cubic-bezier(0.26, 0.26, 0.01, 1);
			@include hoverDeviceQuery {
				&:hover,
				&:focus {
					color: var(--color-body);
					transform: translateX(1rem);
				}
			}
		}
	}

	.act {
		a {
			color: $metamenu-color-hover-active;
		}
	}
}
.icon--metamenu{
	height: 22px;
	margin-right: 16px;
}