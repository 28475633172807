.icon-hidden svg,
.icon-hidden{
	height: 0;
	width: 0;
}

.icon{
	fill: var(--color-body);
}
.icon-primary {
	fill: var(--color-primary);
}
.icon-story {
	fill: var(--color-story);
}
.icon-inspiration {
	fill: var(--color-inspiration);
}
.icon-cheese {
	fill: var(--color-cheese);
}

.icon-bodyColor {
	fill: var(--color-body);
}
.icon-white {
	fill: var(--color-white);
}
.icon-grey {
	fill: var(--color-grey);
}
.icon-black {
	fill: var(--color-black);
}

// animated
#mouse-wheel{
	animation: mousewheeloop 1s linear;
	animation-iteration-count: infinite;
}

@keyframes mousewheeloop {
	0% {transform: translateY(0); opacity: 0;}
	20% {transform: translateY(0); opacity: 1;}
	80% {transform: translateY(1px); opacity: 1;}
	100% {transform: translateY(1px); opacity: 0;}
}
