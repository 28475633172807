//infoblock content
.imagetext-infoblock{
    position: relative;
    padding: 45px 0;
    @include media-breakpoint-up(md){
        padding: 60px 0;
    }
}
.imagetext-infoblock::before{
    content: "";
    position: absolute;
    top: 0;
    left: calc(var(--container-padding-x) * -1);
    width: calc(100% + var(--container-padding-x) * 2);
    height: 100%;
    background: var(--color-white);
    z-index: -1;
    @include media-breakpoint-up(md){
        left: 0;
        width: 100%;
        padding-top: #{$spacer * 3};
        padding-bottom: #{$spacer * 3};
    }
}
.infoblock__title{
    h2{
        margin: 0;
        max-width: 600px;
    }
    @include media-breakpoint-down(md){
        margin-bottom: #{$spacer};
    }
}
.infoblock__text{
    max-width: 600px;
    *:last-child{
        margin: 0;
    }
}