@include media-breakpoint-down(md) {
	.headerSecondRow {
		height: $mobile-header-height;
	}

	.header--mobileMenu {
		text-align: right;

		@include media-breakpoint-down(xs) {
			padding-left: 0;
		}

		ul {
			padding: 0;
			margin: 0;
		}

		li {
			display: inline-block;
			list-style: none;
		}
	}

	// toggle element
	.mobileMenu--toggle {
		border: 0;
		cursor: pointer;
		display: inline-block;
		height: 48px;
		line-height: 48px;
		z-index: 99999;
		padding-top: 26px;

		&:focus {
			outline: none;
		}

		%main-nav--span-all {
			backface-visibility: hidden;
			background-color: $mobileMenu-toggle-color;
			width: 22px;
			will-change: transform;
		}

		%main-nav--span-pseudo {
			content: '';
			height: 1px;
			left: 0;
			position: absolute;
			top: -7px;
			transition: top .22s ease .22s, transform .22s ease;
		}

		span {
			@extend %main-nav--span-all;

			display: block;
			height: 1px;
			margin: auto;
			position: relative;
			transition: .3s all ease;

			&::before,
			&::after {
				@extend %main-nav--span-all;
				@extend %main-nav--span-pseudo;
			}

			&::after {
				top: 7px;
			}
		}
	}

	// if menu open
	.mm-ocd-opened {
		.mobileMenu--toggle {
			pointer-events: none;

			span {
				background: transparent;
				transition: background .22s 0s ease;

				&::before,
				&::after {
					top: 0;
					width: 22px;
					transition-delay: 0s, .22s;
				}

				&::before {
					transform: rotate(45deg);
				}

				&::after {
					transform: rotate(-45deg);
				}
			}
		}
	}
}
