/********************** CHEESFINDER BUTTONS **********************/
$cheese-button-size: 82px;
.btn.btn-block{
	width: 82px;
	height: 82px;

	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--color-white);

	// override btn default
	border: 2px solid var(--color-grey);
	border-radius: 0;
	@include media-breakpoint-down(md){
		width: 63px;
		height: 63px;
	}
	.icon{
		width: 20px;
		height: 20px;
	}

	&.btn-block--dark{
		background: var(--color-body);
		border-color: var(--color-body);
		.icon{
			fill: var(--color-white);
		}
	}
}
.btn-row{
	.btn.btn-block{
		margin: 0;
	}
}