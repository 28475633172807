.pagination {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 20px -5px;
	padding: 0;

	.page-item {
		display: flex;
		padding: 0 5px;
		margin-bottom: 10px;

		&::before {
			display: none;
		}

		&.current {
			a {
				background-color: var(--color-grey-60);
				color: var(--color-white);
			}
		}
	}
}

.frame-default {
	ul.pagination {
		margin: 20px -5px;
		padding: 0;
	}
}
