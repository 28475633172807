//// classes for icons - needs before or after element ////
// example
/*
@mixin icon--template {
    content: str-replace(url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 13.6'%3E%3Cpath fill='#{$body-color}' d=''/%3E%3C/svg%3E"), "#", "%23");
}
*/

@mixin icon--default-settings {
	position: absolute;
	width: auto;
	height: auto;
	left: 0;
	transition: all .3s ease 0s;
}

/// general
@mixin icon--chevron-down-grey {
	content: str-replace(url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 7'%3E%3Cpolygon fill='#{$grey-80}' points='11.3,0 6.1,5.5 0.7,0 0,0.7 6.1,7 12,0.7'/%3E%3C/svg%3E"), "#", "%23");
}

@mixin icon--chevron-right-black {
	content: str-replace(url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 11'%3E%3Cpolygon fill='#{$black}' points='0.7,11 0,10.3 4.6,5.5 0,0.7 0.7,0 6,5.5'/%3E%3C/svg%3E"), "#", "%23");
}

@mixin icon--chevron-right-grey60 {
	content: str-replace(url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 11'%3E%3Cpolygon fill='#{$grey-60}' points='0.7,11 0,10.3 4.6,5.5 0,0.7 0.7,0 6,5.5'/%3E%3C/svg%3E"), "#", "%23");
}


/// header


/// content



/// file icons - primary ///
@mixin icon--file {
	content: str-replace(url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'%3E%3Cpath fill='#{$body-color}' d='M369.9,97.9L286,14c-9-9-21.2-14.1-33.9-14.1H48C21.5,0,0,21.5,0,48v416c0,26.5,21.5,48,48,48h288c26.5,0,48-21.5,48-48V131.9C384,119.2,378.9,106.9,369.9,97.9z M347.3,120.6c2.1,2.1,3.5,4.6,4.2,7.4H256V32.5c2.8,0.7,5.3,2.1,7.4,4.2L347.3,120.6zM336,480H48c-8.8,0-16-7.2-16-16V48c0-8.8,7.2-16,16-16h176v104c0,13.3,10.7,24,24,24h104v304C352,472.8,344.8,480,336,480z'/%3E%3C/svg%3E"), "#", "%23");
}


///// icon as background-image ////
@mixin icon--bg--chevron-down-grey {
	background-image: str-replace(url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 7'%3E%3Cpolygon fill='#{$grey-80}' points='11.3,0 6.1,5.5 0.7,0 0,0.7 6.1,7 12,0.7'/%3E%3C/svg%3E"), "#", "%23");
}

@mixin icon--bg--chevron-left-black {
	background-image: str-replace(url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 11'%3E%3Cpolygon fill='#{$black}' points='0,5.5 5.3,0 6,0.7 1.4,5.5 6,10.3 5.3,11'/%3E%3C/svg%3E"), "#", "%23");
}