// Override if you use it in your theme

$paragraph-margin-bottom: 1.25rem;

/*
// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1rem !default;
*/
