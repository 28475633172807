.accordion{
	
}
.accordion-item{
	border: none;
	position: relative;
	//margin: 0 var(--bs-gutter-x);
	background-color: initial;
	&::after{
		content: "";
		position: absolute;
		bottom: -1px;
		height: 1px;
		background: var(--color-body);
		width: calc(100% - var(--bs-gutter-x) * 3);
		left: calc(var(--bs-gutter-x) * 1.5);
		z-index: 1;
	}
	&:last-child::after{
		content: none;
	}
}
.accordion-header{
	display: flex;
	align-items: center;
	background: var(--color-white);
	padding: 0 var(--bs-gutter-x);

}
.accordion-header-link{
	position: absolute;
	top: 25px;
	left: 50%;
	transform: translate(20px, 0);
	z-index: 90;
}
.accordion-body{
	padding: 45px 0 0;
	background: var(--color-white);
	padding: 0 var(--bs-gutter-x);
}
.accordion-collapse{
	display: flex;
	flex-direction: column;
}
.accordionsingle-collapse{
	//margin-top: -20px;
}

// content types
.accordion-image-big,
.accordion-text-image,
.accordion-title-text,
.accordion-grey-text,
.accordion-link{
	margin-bottom: 45px;
	@include media-breakpoint-up(md){
		margin-bottom: 60px;
	}
	&:first-child{
		margin-top: 45px;
	@include media-breakpoint-up(md){
		margin-top: 60px;
	}
	}
}
.accordion-link{
	margin: 0 0 45px auto;
}

.accordion-grey-text{

	background-color: var(--color-grey);
	padding: #{$spacer * 3} var(--bs-gutter-x);
	
	margin-left: 0;
	margin-right: 0;

	@include media-breakpoint-up(md){
		@include make-col(3,4);
		@include make-col-offset(1, 4);
		padding: #{$spacer * 2};
	}

	ul{
		li{
			margin-bottom: #{$spacer};
		}
	}
}

.accordion-item:last-child{
	.accordion-header{
		border-bottom: none;
	}
}
.accordion-button:not(.collapsed),
.accordion-button{
	border: none;
	background-color: initial !important;

	display: flex;
    justify-content: space-between;

	padding: 30px 0;
	box-shadow: none;
	&:focus{
		box-shadow: none;
	}
}
.accordion-button:not(.collapsed){
	background-color: var(--color-white);
}
.accordion-button::after{
	content: none;
}

// .accordion-body{
// 	ul{
// 		padding-left: 1.3em;	
// 	}
// 	li{
// 		display: list-item;
// 		list-style: disc;
// 		@include media-breakpoint-up(md){
// 			padding-bottom: 1em;
// 		}
// 	}
// }

.icon--collapse{
	--path-opacity-1: 1;
	--path-opacity-2: 0;
}
.collapsed .icon--collapse{
	--path-opacity-1: 1;
	--path-opacity-2: 1;
	.icon{
		transition: opacity 0.5s linear;
	}
}


// content elements
.accoriondownloadlist{
	list-style: none;
	padding-left: 0;
}
.accoriondownloadlist__item{
	display: inline-block;
	margin-bottom: 15px;
	float: left;
	clear: left;
	list-style: none;
	margin-bottom: 0;
	@include hoverDeviceQuery {
		&:hover{
			.accoriondownloadlist__icon{
				fill: var(--color-topic)
			}
		}
	}
}
.accoriondownloadlist__label{
	font-size: var(--body-sm);
	line-height: var(--body-low);
}
.accoriondownloadlist__meta{
	margin-left: 15px;
	font-size: var(--body-xxs);
}
.accoriondownloadlist__icon{
	flex-shrink: 0;
	width: 16px;
	height: 16px;
	margin-right: 20px;
}

.accoriondownloadlist__link{
	--color-active: var(--color-topic);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    color: var(--color-body);
	padding-bottom: 2px;
	margin-bottom: 13px;
}

// single accordion
.accordion-item--single{
	margin-bottom: 30px;

	@include media-breakpoint-down(md){
		margin-bottom: 15px;
	}
	&:nth-child(odd){
		@include media-breakpoint-up(xl){
			@include make-col-offset(1, 12);
		}
	}
	&::after{
		content: none;
	}
}
.accordionsingle-container{
	padding-top: 20px;
	padding-bottom: 20px;
	background-color: var(--color-white);
}
.accordion-button--single:not(.collapsed),
.accordion-button--single{
	padding: 0;
}
.accordionsingle__lessbutton,
.accordionsingle__morebutton{
	font-size: var(--body-xs);
	line-height: var(--body-low);
	font-weight: var(--body-medium);
	display: block;
	// safarie fix
	-webkit-appearance: none;
	.icon{
		width: 1.2em;
		height: 1.2em;
	}
}
.accordion-button:not(.collapsed){
	.accordionsingle__morebutton{
		opacity: 0;
	}
}
.accordionsingle__linkbutton{
	margin-left: 20px;
}
.accordionsingle-body > *{
	margin-left: 57px;
	margin-right: 39px;
	@include media-breakpoint-up(md){
		margin-left: 80px;
		margin-right: 80px;
	}
}
.accordionsingle-body > p{
	font-size: var(--body-xs);
}
