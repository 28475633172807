// switch
/*
html template
<label class="switch">
    <input type="checkbox" checked="">
    <span class="slider">
        <span class="switch-label-true">JA</span>
        <span class="switch-label-false">Nein</span>
    </span>
</label>
*/

$switchwidth: 48px;
$switchheight: 24px;
$sliderradius: 18px;
$slideroffset: math.div($switchheight - $sliderradius, 2);
$slidermovedist: $switchwidth - $sliderradius - $slideroffset * 2;

.switch {
    position: relative;
    display: inline-block;
}
.switch input { 
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
}
.slider {
    position: relative;
    display: flex;
    flex-direction: row;
    height: $switchheight;
    line-height: $switchheight;
    padding-left: $switchwidth + 8px;
}
.slider::after{
    content: "";
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    width: $switchwidth;
    height: $switchheight;
    background-color: #ABABAB;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: math.div($switchheight,2);
    z-index: -1;
}
.slider::before {
    position: absolute;
    content: "";
    height: $sliderradius;
    width: $sliderradius;
    top: $slideroffset;
    left: $slideroffset;
    background-color: white;
    transition: transform .18s cubic-bezier(0.02, 0.04, 0.05, 1);
    border-radius: 50%;
}

.switch-label-false,
.switch-label-true{
    font-size: var(--body-md);
    z-index: -1;
    transition: all .3s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.switch-label-false{
    transform: translate(0, 0);
    opacity: 1;
    max-width: 100px;
}
.switch-label-true{
    transform: translate(-56px, 0);
    max-width: 0;
    opacity: 0;
}
input:checked + .slider{
    .switch-label-false{
        transform: translate(-56px, 0);
        opacity: 0;
        max-width: 0;
    }
    .switch-label-true{
        transform: translate(0, 0);
        opacity: 1;
        max-width: 100px;
    }
    &::after {
        background-color: var(--color-inspiration);
    }
}

input:checked + .slider:before {
    transform: translateX($slidermovedist);
}
// radio switch
.switch [type="radio"]:checked + .slider{
    position: absolute;
    top: 0;
    width: 0;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0;
}
.switch--inverted{
    .switch-label-true{
        transform: translate(0, 0);
        opacity: 1;
        max-width: 100px;
    }
    .switch-label-false{
        transform: translate(-56px, 0);
        max-width: 0;
        opacity: 0;
    }
    input:checked + .slider{
        .switch-label-true{
            transform: translate(-56px, 0);
            opacity: 0;
            max-width: 0;
        }
        .switch-label-false{
            transform: translate(0, 0);
            opacity: 1;
            max-width: 100px;
        }
        &::after {
            background-color: #ABABAB;
        }
    }
    .slider::after{
        background-color: var(--color-inspiration); 
    }
}

