#mainnav{

}
.mainmenu__link-container-open{
	display: none;
	opacity: 0;
	position: absolute;
	top: 2px;
	left: calc( (#{divide(100%,6)} + var(--bs-gutter-x)) * -1);
	@include media-breakpoint-down(md) {
		left: calc( (#{divide(100%,12)} + var(--bs-gutter-x)) * -1);
	}
}
.mainmenu__link-container-close,
.mainmenu__link-container-open{
	height: 24px;
	border-radius: 12px;
	padding: 0 9px;
	margin-top: 14px;
	@include media-breakpoint-down(md) {
		margin-top: 0;
	}
	&::before{
		height: 24px;
		border-radius: 12px;
	}
	&:hover{
		&::before{
			border-width: 12px !important;

		}
		.icon{
			fill: var(--color-white);
		}
	}
	.icon{
		width: 18px;
		height: 18px;
	}
}
@include hoverDeviceQuery {
	.btn-parent:hover{
		.mainmenu__link-container-close,
		.mainmenu__link-container-open{
			&::before{
				border-width: 12px !important;
			}
			.icon{
				fill: var(--color-white);
			}
		}
	}
}


.mainmenu__link{
	display: flex;
	justify-content: space-between;
}
.mainmenu_item:not(.open){
	@include hoverDeviceQuery {
		.mainmenu__link-container--has-sub{
			&:hover a{
				transform: translate(20px, 0);
			}
		}
	}
}
.mainnav-opensub{
	.mainmenu{
		@include media-breakpoint-up(md) {
			transform: translateY(var(--center-offset));
			transition: transform .2s cubic-bezier(0.39, 0.575, 0.565, 1);
		}
	}

	.metaMenu{
		opacity: 0;
		display: none;
	}
	.row--mainnav {
		transition: transform .2s cubic-bezier(0.39, 0.575, 0.565, 1);
		@include media-breakpoint-up(md) {
			transform: translateX(-30%);
		}
	}
	.mainmenu_item:not(.open){
		opacity: 0;
		transition: all 0.18s cubic-bezier(0.39, 0.575, 0.565, 1);
		@include media-breakpoint-down(md) {
			height: 0;
			margin: 0;
		}
	}
}
.mainmenu__link-container--sub{
	opacity: 0;
	position: relative;
	width: 100%;
	display: none;
	left: 0;
	top: 100%;
	margin-top: 10px;
	@include media-breakpoint-up(md) {
		position: absolute;
		left: 100%;
		top: 50%;
		transform: translateY(-50%);
	}
	.mainmenu__link::before{
		content: none !important;
	}
}
.mainmenu_item.open{
	.mainmenu__link-container-close{
		opacity: 0;
		display: flex;
		transition: opacity .18s cubic-bezier(0.39, 0.575, 0.565, 1) 0.5;
	}
	.mainmenu__link-container-open{
		opacity: 1;
		display: flex;
		transition: opacity .18s cubic-bezier(0.39, 0.575, 0.565, 1) 0.5;
	}
	.mainmenu__link::before{
		content: none;
	}
	@include media-breakpoint-down(md) {
		.mainmenu__link-container--sub{
			opacity: 1;
			display: block;
			transition: opacity .18s cubic-bezier(0.39, 0.575, 0.565, 1) 0.2;
		}
	}
	// desktop
	@include media-breakpoint-up(md) {
		//transform: translateY(var(--center-offset));
		transition: all .18s cubic-bezier(0.39, 0.575, 0.565, 1);
		.mainmenu__link-container--sub{
			opacity: 1;
			display: block;
			transition: opacity .18s cubic-bezier(0.39, 0.575, 0.565, 1) 0.2;
		}
		.mainmenu__link-container--has-sub{
			@include hoverDeviceQuery {
				&:hover a,
				&:focus a{
					transform: translate(0, 0);
				}
			}
		}
	}
}

.mainmenu__link-sub{
	position: relative;
	display: inline-block !important;
	margin: 15px 0 0;
	@include media-breakpoint-up(md) {
		margin-top: 22.5px 0;
	}
	padding-left: 30px !important;
	font-size: var(--body-xl);
	font-weight: var(--title-medium);
	font-family: var(--bs-body-font-family);
	color: var(--color-body);
	.icon{
		position: absolute;
		left: 0;
		top: -6px;
		width: 30px;
		display: inline-block;
	}
	@include hoverDeviceQuery {
		&:hover{
			.icon{
				fill: var(--color-active);
			}
		}
	}
}
