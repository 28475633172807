.headersearch-container{
    margin: #{$spacer * 4} 0;
}
.headersearch{
    position: relative;
    padding: 38px 15px 38px 0;
    display: flex;
    @include media-breakpoint-down(lg){
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: #{$spacer * 2};
    }
    &::after{
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: calc(100% + var(--container-padding-x));
        background: var(--color-white);
        z-index: -1;
    }
}
.headersearch__inputgroup{
    border: 2px solid var(--color-primary);
    border-radius: 19px;
    height: 38px;
    margin: 0 auto;
    background: var(--color-white);
    display: flex;
    align-self: center;
    @include media-breakpoint-down(lg){
        margin: 0;
        align-self: flex-start;
    }
}
.headersearch__input{
    border: none;
    padding: 0 16px;
    font-size: var(--body-sm);
    font-weight: var(--body-mdeium);
    min-width: 240px;
    background: none;
    &:focus-visible{
        outline: none;
    }
}
.headersearch__submit{
    border: none;
    padding: 0 16px 0 0;
    font-size: var(--body-sm);
    font-weight: var(--body-mdeium);
    background: none;
    .icon{
        width: 24px;
        height: 24px;
    }
}
.headersearch__label{
    max-width: 200px;
    font-size: var(--body-lg);
    font-weight: var(--body-medium);
    @include media-breakpoint-down(lg){
        max-width: 200px;
        margin-bottom: #{$spacer};
    }
}