//download content
.filedownload{
    position: relative;
    padding: 45px 0;
    @include media-breakpoint-up(md){
        padding: 60px 0;
    }
}
.filedownload::before{
    content: "";
    position: absolute;
    top: 0;
    left: calc( var(--container-padding-x) * -1);
    width: calc(100% + var(--container-padding-x));
    height: 100%;
    background: var(--color-white);
    z-index: -1;
    @include media-breakpoint-up(md){
        left: 0;
        width: 100%;
    }
}
.downloadlist{
    padding: 0;
    margin: 0;
    @include media-breakpoint-down(md){
        margin-top: $spacer * 3;
    }
}
.downloadlist__item{
    // fake row
    list-style: none !important;
    margin: 0 calc(var(--bs-gutter-x) * -.5) 20px;
    &:last-child{
        margin-bottom: 0;
    }
    @include media-breakpoint-up(md){
        margin-bottom: 30px;
    }
}
.downloadlist__link{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    color: var(--color-body);
}
.downloadlist__icon{
    display: block;
    padding: 0 calc(var(--bs-gutter-x) * .5);
    @include media-breakpoint-up(md){
        @include make-col(1, 4);
    }
}
.downloadlist__text{
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 0 calc(var(--bs-gutter-x) * .5);

    line-height: var(--body-mid-low);
    @include media-breakpoint-up(md){
        @include make-col(3, 4);
    }
}
.downloadlist__filename{
    display: block;
    font-size: var(--body-sm);
}
.downloadlist__meta{
    display: block;
    font-size: var(--body-xs);
}

// download-warning
.dropdown-download-warning {
    .dropdown-toggle::after {
        content: none;
    }
    [aria-expanded="true"] {
        pointer-events: none;
        opacity: 0.5;
    }
    .dropdown-menu {
        border-radius: 0;
        border: none;
        padding: 30px 22.5px;
        max-width: 220px;
    }
    // override rte button style
    .dropdown-menu a{
        display: block;
        padding: 0;
        &:before,
        &:after{
            content: none;
        }
    }
}