.row--quote{
    position: relative;
}
.col--quoteimage{
    z-index: -1;
    @include media-breakpoint-up(md) {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
}

.col--quote,
.col--quotetext{
    @include media-breakpoint-up(md) {
        margin-top: 30vw;
    }
}
.col--quote{
    @include media-breakpoint-down(md) {
        margin-top: -67px;
    }
}

.quote__imagebox{
    width: 100%;
    margin: 0 auto;
    @include media-breakpoint-up(md) {
        position: relative;
        width: 56%;
        height: auto;
        margin: 0;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
.quote__titleinner{
    margin: calc(var(--box-padding-y) * 1.5) 0 var(--box-padding-y);
    @include media-breakpoint-up(md) {
        margin: 0 0 calc(var(--box-padding-y) * .5);
    }
}
.quote__textbox{
    @include media-breakpoint-down(md) {
		margin: 0 calc(var(--bs-gutter-x) * -.5);
	}
	
}
.quote__textinner{
    @include media-breakpoint-down(md) {
		@include make-col(4, 6);
		@include make-col-offset(2, 6);
        padding: 0 calc(var(--bs-gutter-x) * .5);
	}
    max-width: 600px;
}
// QUOTE
.quote__quotebox{
    position: relative;
    @include media-breakpoint-up(md) {
        margin: 25px -15px;
    }
}


.quote__quotebox::before{
	content: "";

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    background-color: var(--color-white);

    @include media-breakpoint-up(md) {
        left: calc(var(--bs-gutter-x) * .5);
        width: calc( 100% - var(--bs-gutter-x) + var(--container-padding-x));
    }
}


// variante
.quote__quotebox.quote__quotebox--cheese{
    position: relative;
    margin: #{$spacer} 0;
    &::before{
        left: calc(var(--bs-gutter-x) * .5);
        width: calc(100% - var(--bs-gutter-x));
        height: 100%; 
    }
    .icon-quote--close{
        bottom: -15px;
        right: calc(var(--bs-gutter-x) + 15px);
    }
    .icon-quote--open{
        top: -15px;
        left: calc(var(--bs-gutter-x) + 15px);
    }
    .icon-quote--open,
    .icon-quote--close{
        width: 34px;
    }
}
.quote__quoteinner--cheese{
    display: inline-block;
    position: relative;
    padding: 45px 60px;
}

.quote__quotebox--contactbox{
    @include media-breakpoint-down(md) {
        margin: 0 0 75px;
    }
}

.quote__quoteinner{

    position: relative;
    padding: calc(8% + var(--bs-gutter-x) * .5);
    
    @include media-breakpoint-up(md) {
        padding: calc(17% + var(--bs-gutter-x) * .5);
    }
    
    margin-left: calc(var(--bs-gutter-x) * .5);
    width: calc( 100% - var(--bs-gutter-x) + var(--container-padding-x));

    p{
        margin-bottom: 0;
        font-size: var(--body-sm);
        max-width: 800px;
        @include media-breakpoint-up(sm) {
            font-size: var(--body-md);
            line-height: var(--body-mid);
        }
        @include media-breakpoint-up(md) {
            font-size: var(--body-lg);
            line-height: var(--body-mid);
        }
        @include media-breakpoint-up(xl) {
            font-size: var(--body-xl);
            line-height: var(--body-mid);
        }
    }
}
.icon-quote{
    position: absolute;
    width: 32px;
    fill: var(--color-topic);
    @include media-breakpoint-up(md) {
        width: 57px;
    }
}
.icon-quote--open{
    top: -12px;
    left: 30px;
    @include media-breakpoint-up(md) {
        top: -20px;
        left: 90px;
    }
}
.icon-quote--close{
    bottom: -15px;
    right: 30px;
    @include media-breakpoint-up(md) {
        bottom: -25px;
        right: -15px;
    }
}