/* blacker display-bold - latin */
@font-face {
    font-display: swap;
	font-family: 'Blacker Display';
	font-style: normal;
	font-weight: 700;
	src: url('../Fonts/BlackerProDisplayBold/Blacker-Pro-Display-Bold.woff2') format('woff2'), /* Super Modern Browsers */
	url('../Fonts/BlackerProDisplayBold/Blacker-Pro-Display-Bold.woff') format('woff'), /* Modern Browsers */
    local('Blacker Display'), local('BlackerDisplay');
}
