// main menu
$mainmenu-1st-font-size: 1.2rem;
$mainmenu-1st-color: var(--color-body);
$mainmenu-1st-color-hover-active: var(--color-primary);

$mainmenu-2nd-font-size: $font-size-base;
$mainmenu-2nd-color: var(--color-body);
$mainmenu-2nd-color-hover-active: var(--color-primary);


// meta menu
$metamenu-font-size: .9rem;
$metamenu-color: var(--color-grey-60);
$metamenu-color-hover-active: var(--color-body);
$metamenu-padding: 9px 0;


// lang switch
$langswitch-font-size: .9rem;
$langswitch-color: var(--color-grey-60);
$langswitch-color-hover-active: var(--color-body);
$langswitch-padding: 0 4px;


// breadcrumb
$breadcrumb-font-size: .9rem;
$breadcrumb-color: var(--color-grey-60);
$breadcrumb-color-hover: $link-color;


// footer menu
$footermenu-color: $footer-color;
$footermenu-color-hover-active: var(--color-grey-60);
$footermenu-padding: 0 6px;


// mobile
$mobileMenu-toggle-color: var(--color-primary);
$mobileMenu-1st-color-hover-active: var(--color-primary);