.storyteaserbig__row{
    margin-top: 0;
    margin-bottom: 90px;
}
.storyteaserbig__imagebox{
    width: 100%;
    // margin-top: #{$spacer * 1.5};
    @include media-breakpoint-up(md) {
        width: calc(100% + var(--container-padding-x));
        margin-left: calc(var(--container-padding-x) * -1);
    }
}
.storyteaserbig__title{
    display: flex;
    align-items: center;
    @include media-breakpoint-up(md) {
        max-width: 50%;
        width: auto;
    }
}
.storyteaserbig__subtitle{
    font-weight: var(--body-regular);
    margin-bottom: 28px;
}
.storyteaserbig__textbox{
    position: relative;
    display: flex;
    @include media-breakpoint-up(md) {
        margin-right: calc(var(--bs-gutter-x) * -.5);
        margin-left: calc(var(--bs-gutter-x) * -.5);
        padding: 0;
    }

    &:after{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: var(--color-white);
        z-index: -1;
        @include media-breakpoint-up(md) {
            left: calc(-33.333333333% + var(--bs-gutter-x) * .5);
            width: calc( 133.333333333% - var(--bs-gutter-x));
        }
    }
}
.storyteaserbig__text{
    p:last-child{
        margin-bottom: 0;
    }
}
.storyteaserbig__row-splide{
    @include media-breakpoint-down(md) {
        margin-right: 0;
    }
}
.storyteaserbig__textinner{
    position: relative;
    padding: 30px calc(var(--bs-gutter-x) * 1.5);
    @include media-breakpoint-up(md) {
        @include make-col(6, 6);
        padding: 120px calc(var(--bs-gutter-x) * 1.5) 76px calc(var(--bs-gutter-x) * .5);
    }
	@include media-breakpoint-up(xl) {
        @include make-col(3, 5);
        @include make-col-offset(1, 5);
        padding: 120px calc(var(--bs-gutter-x) * .5) 76px calc(var(--bs-gutter-x) * .5);
	}
}