// paragraph sizes
$bodysizes: ("xxs": var(--body-xxs), "xs": var(--body-xs), "sm": var(--body-sm), "md": var(--body-md), "lg": var(--body-lg), "xl": var(--body-xl));
$fontweights: ("regular": 400, "medium": 500);
$lineheights: ("low": var(--body-low), "mid": var(--body-mid), "high": var(--body-high));

@each $sizename, $size in $bodysizes {
	.body-#{$sizename}{
		font-size: #{$size};
	}
}
.body-xl{
	line-height: var(--body-mid);
}
@each $weightame, $weight in $fontweights {
	.body-#{$weightame}{
		font-weight: #{$weight};
	}
}
@each $heightname, $height in $lineheights {
	.body-#{$heightname}{
		line-height: #{$height};
	}
}
.body-sm-xl{
	@include media-breakpoint-down(md){
		font-size: 21px;
		line-height: 120%;
	}
}

.body-lg-sm{
	@include media-breakpoint-down(md){
		font-size: var(--body-sm);
	}
}
