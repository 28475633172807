.infographic-point {
  position: absolute;
  z-index: 20;
  width: calc(var(--infographic-point-size-min) * var(--size));
  height: calc(var(--infographic-point-size-min) * var(--size));
  top: 50%;
  transform: translate(-100%, -50%);

  border-radius: 50%;
  border: 4px solid var(--infopoint-color);
  background: var(--color-grey);
  box-sizing: content-box;
  --display-render: block;

  &.infographic-point--intro,
  &.infographic-point--zero {
    --display-render: none;
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    border: 2px solid var(--color-grey);
    border-radius: 50%;
  }
}

.point_render {
  display: var(--display-render);
  width: 100%;
  height: 100%;
  background: var(--infopoint-color);
  border-radius: inherit;
  transform: scale(0);
  transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);
}

.infographic-point--intro,
.infographic-point--zero {
  width: var(--infographic-point-size-zero);
  height: var(--infographic-point-size-zero);
  border: 2px solid var(--color-black);
  background: var(--color-grey);
}
.infographic-point--intro {
  top: 0;
}

@include media-breakpoint-down(md) {
  .infographic-point--xs {
    width: var(--infographic-point-size-min);
    height: var(--infographic-point-size-min);
  }
  .infographic-point--sm {
    width: 22px;
    height: 22px;
  }
  .infographic-point--md {
    width: 36px;
    height: 36px;
  }
  .infographic-point--lg {
    width: 51px;
    height: 51px;
  }
  .infographic-point--xl {
    width: 69px;
    height: 69px;
  }
  .infographic-point--xxl {
    width: 88px;
    height: 88px;
  }
}

#infographicDot {
  position: absolute;
  transform: translate(0, 0);
  will-change: transform;
  width: 20px;
  height: 20px;
  border: 2px solid var(--color-black);
  background: var(--color-grey);
  top: -10px;
  left: -10px;
  z-index: 1;
  border-radius: 50%;
}

.textblock__intro p {
  font-size: var(--body-xl);
}
.textblock__text p {
  font-size: var(--body-xl);
  max-width: 510px;
  background-color: var(--color-grey);
}
.textblock__intro p > ul {
  margin-top: -1.25rem; // no extra space between p and ul
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-red {
  color: var(--color-infographic-red);
}

.row--infographic-item {
  --infopoint-color: var(--color-infographic-red);

  .slideX {
    opacity: 0;
    transition: opacity 0.6s cubic-bezier(0.16, 1, 0.3, 1),
      transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);
    transform: translateX(-15px);
  }

  .slideX-first {
    transition-delay: 0;
  }

  .slideX-second {
    transition-delay: 200ms;
  }

  &.intersect {
    .point_render {
      transform: scale(1);
    }

    .slideX {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

// hard set color
.row--infographic-item-last {
  --infopoint-color: var(--color-infographic-yellow);

  .text-red {
    color: var(--color-infographic-yellow);
  }
}
