textarea {
	&.form-control {
		min-height: 180px;

		@include media-breakpoint-up(md) {
			min-height: 260px;
		}
	}
}

// checkbox
.MultiCheckbox {
	.form-check {
		margin-bottom: 6px;
	}
}

// select
.custom-select {
	@include icon--bg--chevron-down-grey;
	background-size: 13px auto;
	background-position: right 16px top 25px;
	padding-right: 40px;
	color: var(--color-body);

	@include media-breakpoint-up(md) {
		background-size: 20px auto;
		background-position: right 21px top 24px;
		padding-right: 55px;
	}
}

// form description 
.help-block{
	font-size: var(--body-xs);
	max-width: 600px;
}

// admin errors
.form-control.f3-form-error{
	border-color: var(--color-active);
}
.validation-results{
	color: var(--color-active);
	font-size: var(--body-xs);
}