.topstory{
    // @include media-breakpoint-up(md) {
	// 	@include make-col-offset(1, 12);
    //     width: calc( #{math.div(100%, 12) * 11} + var(--container-padding-x));
    //     padding: 0;
	// }
}
.splide__slide{
    // @include media-breakpoint-down(md) {
    //     width: calc( #{math.div(100%,6) * 5} - var(--bs-gutter-x) * .5) !important;
    // }
}
.splide__track{
    left: calc(var(--bs-gutter-x) * -.5);
    @include media-breakpoint-up(md) {
        margin: 0 calc(var(--bs-gutter-x) * -.5) 0;
    }
    @include media-breakpoint-down(sm) {
        overflow: hidden;
    }
}
.splide__pagination{
    position: relative;
    margin: 30px 0 0;
}
.splide__pagination__page{
    opacity: 1;
    background: var(--color-body);
    &.is-active{
        background: var(--color-topic);
    }
}

.splide__coltitle{
    @include media-breakpoint-down(md) {
        width: calc( 100% - #{$btn-radius-medium} - var(--bs-gutter-x) );
    }
}

.splide__colbutton{
    @include media-breakpoint-down(md) {
        width: calc(#{$btn-radius-medium} + var(--bs-gutter-x));
    }
    @include media-breakpoint-up(md) {
        margin-left: 45px;
    }
}

.ptn-splide--prev,
.ptn-splide--next{
    svg{
        height: 20.2109px;
        width: 20.2109px;
    }
    @include media-breakpoint-up(md) {
        svg{
            height: 24.0078px;
            width: 24.0078px;
        }
    }
}
.ptn-splide--prev svg{
    transform: rotate(180deg);
    transform-origin: center;
}

/**************** CARD ***************/
.topstory__imagebox{
    margin-top: 30px;
    @include media-breakpoint-up(md) {
        width: calc(100% + var(--container-padding-x) + var(--bs-gutter-x));
        margin-left: calc( var(--container-padding-x) * -1);
    }
}
.topstory__textbox{
    
    position: relative;
}
.topstory__textbox:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    width:  100%;;
    height: 100%;

    background: var(--color-white);
    z-index: -1;

    @include media-breakpoint-up(md) {
        width: calc( #{math.div(100%, 5) * 7} );
        left: calc( #{math.div(100%, 5) * -2});
    }
}
.topstory__textinner{
    padding: 30px;
    @include media-breakpoint-up(md) {
        padding:  60px 0;
    }
}

