#infographic {
  position: relative;
}

#infographicSVG {
  position: absolute;
  top: var(--top);
  left: var(--left);
}

#infographicPathMobile {
  display: none;
}

.infographic-intro {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 50vh;

  .infographic-point {
    position: relative;
    display: inline-block;
    transform: none;
  }
}

.infographic-img-col {
  order: -1;
  background-color: var(--bs-body-bg);
}

.row--infographic-item {
  position: relative;
  min-height: 100vh;
  flex-direction: column;
  gap: 50px;
  .col {
    position: relative;
  }

  &:last-child {
    min-height: auto;
  }
}

.row--infographic-item-left {
  .col:first-child {
    margin-left: calc(100% / 4);
  }
  .infographic-point {
    left: var(--infographic-point-space-between);
  }
}
.row--infographic-item-right {
  .col:nth-child(2) {
    margin-left: calc(100% / 4);
  }
  .infographic-point {
    right: var(--infographic-point-space-between);
    transform: translate(100%, -50%);
  }
}

@include media-breakpoint-up(md) {
  #infographicPath {
    display: inline;
    stroke-width: 4;
    stroke-dasharray: 30 25;
  }

  .infographic-img-col {
    background: none;
    order: initial;
  }

  .row--infographic-item {
    min-height: 960px;
    flex-direction: row;
    gap: 0;
  }

  .row--infographic-item-left {
    .col:first-child {
      order: 2;
      padding-top: 10%;
      margin-left: calc(100% / 12);
    }
    .col:nth-child(2) {
      order: 1;
    }
    .infographic-point {
      left: var(--infographic-point-space-between);
    }
  }

  .row--infographic-item-right {
    .textblock__text p {
      margin: 0 0 0 auto; // push p to the right if  container bigger then max
    }
    .col:first-child {
      order: 1;
      padding-top: 10%;
    }
    .col:nth-child(2) {
      order: 2;
      margin-left: calc(100% / 12);
    }
    .infographic-point {
      right: var(--infographic-point-space-between);
    }
  }
}
