.imagetext-textsmallimage{
	p{
		max-width: 800px;
	}
}
.textsmallimage__title{
	margin-bottom: $spacer * 3;
	@include media-breakpoint-up(md) {
		margin-bottom: $spacer * 4;
	}
}
.textsmallimage__text p{
	@include media-breakpoint-up(md){
		font-size: var(--body-lg);
	}
}
.textsmallimage__imagebox{
    margin-top: $spacer * 3;
}