.form-element-multiselect{
  max-width: 600px;
}
.multiselect-dropdown {
  display: inline-block;
  padding: 13px 10px 12px;
  border-bottom: 2px solid var(--color-black);
  background-color: white;
  position: relative;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='37' height='25' viewBox='0 0 37 25' fill='none'%3E%3Crect x='0.75' y='1.55859' width='35.5' height='22.5' rx='11.25' stroke='%232D2D2D' stroke-width='1.5'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.3271 9.98823C13.5875 9.72788 14.0096 9.72788 14.2699 9.98823L18.5 14.2184L22.7301 9.98823C22.9905 9.72788 23.4126 9.72788 23.673 9.98823C23.9333 10.2486 23.9333 10.6707 23.673 10.931L18.9714 15.6326C18.8464 15.7576 18.6768 15.8278 18.5 15.8278C18.3232 15.8278 18.1536 15.7576 18.0286 15.6326L13.3271 10.931C13.0667 10.6707 13.0667 10.2486 13.3271 9.98823Z' fill='%232D2D2D'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-color: var(--bs-body-bg);
  width: 100%;
  .form-check-label {
    padding-left: 40px;
    margin-bottom: 11px;
  }
}
.optext {
  display: inline-block;
  font-size: var(--body-xs);
  background: #fff;
  padding: 1px 0.75em;
  margin: 2px;
}
.optdel {
  position: relative;
  display: inline-block;
  width: 16px !important;
  height: 16px;

  margin-right: 5px;
  padding: 0 !important;

  cursor: pointer;
  &::after,
  &::before {
    content: "";
    position: absolute;
  }

  &::after {
    content: "";
    top: #{$checkbox-border + $checkbox-whitespace};
    left: #{$checkbox-border + $checkbox-whitespace};
    height: #{$checkbox-width - $checkbox-border * 2 - $checkbox-whitespace * 2};
    width: #{$checkbox-width - $checkbox-border * 2 - $checkbox-whitespace * 2};
    background-color: var(--color-body);
    border-radius: 1px;

    transform: scale(1);
    transition: transform 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  &::before {
    content: "";
    top: 0;
    left: 0;
    height: $checkbox-width;
    width: $checkbox-width;
    border: $checkbox-border solid var(--color-body);
    border-radius: 3px;
  }
  &:hover {
    &::after {
      transform: scale(0.75);
    }
  }
}
.multiselect-dropdown-list-wrapper {
  box-shadow: var(--bs-body-bg) 1px 2px 2px;
  z-index: 100;
  padding: 2px;
  border: solid 1px #ced4da;
  display: none;
  margin: -1px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: white;
  .form-check-label {
    margin-bottom: 11px;
  }
}
.multiselect-dropdown-list {
  padding: 10px;
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.multiselect-dropdown-list::-webkit-scrollbar {
  width: 6px;
}
.multiselect-dropdown-list::-webkit-scrollbar-thumb {
  background-color: #bec4ca;
  border-radius: 3px;
}
