// max menu items that will animated
$elements: 4;

$nav_fadein_duration: .3s;
$nav_fadein_duration-opacity: .5s;

$nav_fadein_curve: cubic-bezier(0.26, 0.26, 0.01, 1);
$nav_fadein_delay_step: .1;
$nav_fadein_delay_min: .3;

.container-mainmenu{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	@include media-breakpoint-down(md) {
		height: auto;
		min-height: 100vh;
	}

	display: flex;

	z-index: 100;
	padding-top: 90px;

	background-color: var(--color-grey);
	transform: translateX(100%);
	opacity: 0;

	transition: transform 0s, opacity .3s cubic-bezier(0.26, 0.26, 0.01, 1);
}
.mainnav-scroll-content{
	width: 100%;
}
.menu-open-scaled{
	transition: transform .5s cubic-bezier(0.26, 0.26, 0.01, 1);
}
.menu-open{
	position: fixed;
	left: 0;
	right: 0;

	.menu-open-scaled{
		transform: scale(.98);
	}
	// show main menu
	.container-mainmenu{
		transform: translateX(0);
		opacity: 1;
		height: 100vh;
		overflow-y: auto;

		// allow scroll
		// @include media-breakpoint-down(lg){
		// 	position: relative;
		// }
	}
	// hide/show menu unsed menu buttons
	.d-mainmenu-none{
		display: none;
	}
	.d-mainmenu-block{
		display: block !important;
	}

	// scrollbar
	@include media-breakpoint-up(md) {
		padding-right: 15px;
	}
	// TODO: scrollbar header?
	.container-header{
		@include media-breakpoint-up(md) {
			width: calc(100% - 15px);
		}
	}

	.header{
		top: 0;
		z-index: 100;
		@include media-breakpoint-down(md) {
			overflow-y: scroll;
		}
	}

	// fade in container
	.mainmenu_item--fadein{
		opacity: 1;
		transform: translate(0, 0);
		@for $i from 0 to $elements {
			&:nth-child(#{$i + 1}) {
				transition:
				transform $nav_fadein_duration $nav_fadein_curve #{$i * $nav_fadein_delay_step + $nav_fadein_delay_min}s,
				opacity   $nav_fadein_duration-opacity $nav_fadein_curve #{$i * $nav_fadein_delay_step + $nav_fadein_delay_min}s;
			}
		}
	}
	.mainmenu_item--fadein-meta{
		opacity: 1;
		transform: translate(0, 0);
		transition:
			transform $nav_fadein_duration $nav_fadein_curve #{$elements * $nav_fadein_delay_step + $nav_fadein_delay_min}s,
			opacity   $nav_fadein_duration-opacity $nav_fadein_curve #{$elements * $nav_fadein_delay_step + $nav_fadein_delay_min}s;
	}
	.mainmenu_item--fadein-social{
		opacity: 1;
		transform: translate(0, 0);
		transition:
			transform $nav_fadein_duration $nav_fadein_curve #{($elements + 1) * $nav_fadein_delay_step + $nav_fadein_delay_min}s,
			opacity   $nav_fadein_duration-opacity $nav_fadein_curve #{($elements + 1) * $nav_fadein_delay_step + $nav_fadein_delay_min}s;
	}
	.mainmenu-video-mask{
		opacity: 1;
	}
}
.mainmenu-video-container{
	height: auto;
	display: block;
	margin: 0 auto;

	width: 100%;
	max-width: calc( 100vh - 180px );
}
.mainmenu-video-mask{
	opacity: 0;
	position: relative;

	width: 100%;

	background-color: var(--color-white);
	clip-path: circle(49% at 50% 50%);

	padding-top: 100%;

	transition: opacity 1s cubic-bezier(0.26, 0.26, 0.01, 1);
	@include media-breakpoint-down(md) {
		padding-top: 50%;
		clip-path: circle(30% at 50% 50%);
	}
}
.mainmenu-video-masked{
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
	object-fit: cover;
}
.metaMenu,
.social-links,
.mainmenu{
	@include media-breakpoint-down(md) {
		margin-left: calc( #{divide(100%,12)} + var(--bs-gutter-x) * .5);
	}
	margin-left: calc( #{divide(100%,6)} + var(--bs-gutter-x) * .5);
}
.social-links{

	width: calc( #{divide(100%,6)*4} + var(--bs-gutter-x) * .5);
	@include media-breakpoint-down(md) {
		width: auto;
		margin-right: calc( #{divide(100%,12)} + var(--bs-gutter-x) * .5);
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
}
.mainmenu_list{
	@include media-breakpoint-up(md) {
		height: 50vh;
	}
	display: flex;
	justify-content: space-around;
	flex-direction: column;

	margin: 0;
	padding: 0;

}
.mainmenu_item{

	font-family: var(--bs-headings-font-family);
	font-size: var(--title-md);
	@include fluid-type($headline-min-vw, $headline-max-vw, 28px, 50px, 28px);
	position: relative;
	list-style: none;

	@include media-breakpoint-down(md) {
		margin: 0 0 45px 0;
		&:first-child{
			margin-top: 38px;
		}
	}
	// hover animation
	.mainmenu__link-container{
		a{
			transition-delay: 0;
			transition-duration: .18s;
			transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
		}
		@include hoverDeviceQuery {
			&:hover a,
			&:focus a{
				transform: translate(20px, 0);
			}
		}
	}
}
.mainmenu_item--fadein{
	opacity: 0;
	transform: translate(0, 30px);
}

.mainmenu_item--fadein-meta,
.mainmenu_item--fadein-social,
.mainmenu_item--fadein{
	opacity: 0;
	transform: translate(0, 30px);
	transition: none;
	transition-duration: 0;
	transition-delay: 0;
}

.mainmenu__link{
	&:before{
		position: absolute;
		left: calc( -#{divide(100%,6)} - var(--bs-gutter-x) * .5);
		width: calc( #{divide(100%,6)} + var(--bs-gutter-x) * .5);
		text-align: center;
		display: block;
	}
}
.container-mainmenu--business{
	.mainmenu__link{
		&:before{
			content: none;
		}
	}
}

// link colors
.mainmenu__link {
	color: var(--color-body);
	line-height: var(--body-low);
	@include hoverDeviceQuery {
		&:focus,
		&:hover{
			color: var(--color-body);
		}
	}
	&:before{
		content: "\2022";
		color: var(--color-topic);
	}
}

.mainmenu__link--sub{
	height: 50px;
	&:before{
		position: absolute;
		left: calc( -#{divide(100%,6)} - var(--bs-gutter-x) * .5);
		width: calc( #{divide(100%,6)} + var(--bs-gutter-x) * .5);
		text-align: center;
		display: block;
	}
}


.mainmenu_item--sub{

}
.mainmenu_item--active{

}

// dropdown
.dropdown--mentamenu{
	display: inline-block;
	z-index: 5;
}
.dropdown--langmenu{
	border-radius: 17px;
	border: 2px solid var(--color-grey);
}


// dropdown
.dropdown-inline{
	position: relative;
	display: inline-block;
	width: 245px;
	margin: 10px 20px;
	border-top-right-radius: 0;
	border-top-left-radius: 0;

	z-index: 0;
	&.open{
		z-index: 1;
		.dropdown-inline__btn{
			z-index: 3;
			.icon{
				transform: rotate(180deg);

			}
		}
		.dropdown-inline__listbox{
			z-index: 2;
			display: block;
			height: auto;
			.dropdown-inline__list{
				transform: translateY(0);
			}
		}
	}
}
.dropdown-inline__label{
	font-size: $small-font-size;
	padding-left: 21px;
}
.dropdown-inline__btn{
	justify-content: space-between;
    width: 100%;
    margin-left: 0 !important;
    display: flex;
	z-index: 1;
	font-weight: var(--body-regular);
	background-color: var(--color-white);
	@include hoverDeviceQuery {
		&:hover{
			color: var(--color-body);
		}
	}
	.icon{
		margin: 0;
		transform-origin: 75% 50%;
		transition: transform .18s cubic-bezier(0.165, 0.84, 0.44, 1);
	}
}
.dropdown-inline__listbox{
	position: absolute;
	top: calc(100% - 17px);
	left: 0;
	width: 100%;
	height: 0;
	overflow: hidden;

	border-bottom-left-radius: 17px;
	border-bottom-right-radius: 17px;
}
.dropdown-inline__list{
	background: var(--color-white);
	transition: transform 0.25s ease-out;
	transform: translateY(-100%);
	list-style: none;
	font-size: var(--body-sm);

	padding:  19px 0 0;
	max-height: 220px;
    overflow-y: auto;

	border-left: 2px solid var(--color-topic);
	border-bottom: 2px solid var(--color-topic);
	border-right: 2px solid var(--color-topic);
	border-bottom-left-radius: 17px;
	border-bottom-right-radius: 17px;
}
.dropdown-inline__item,
.dropdown-inline__item--active{
	line-height: 36px;
	min-height: 36px;
	display: block;
	padding: 0 0 0 18px;
	color: var(--color-body);
}
.dropdown-inline__item--active{
	&::after{
		content: "\2022";
		padding-left: 16px;
	}
}

// only desktop view
@include media-breakpoint-up(lg) {
	.mainMenu {
		padding-top: 18px;

		ul {
			margin: 0;
			padding: 0;

			// 1st level
			.no,
			.act {
				position: relative;
				list-style: none;

				a {
					position: relative;
					display: inline-block;
					padding: 6px 28px 1px 30px;
					margin-bottom: 38px;
					font-size: $mainmenu-1st-font-size;
					color: $mainmenu-1st-color;
					font-weight: $font-weight-bold;
					@include hoverDeviceQuery {
						&:hover,
						&:focus {
							color: $mainmenu-1st-color-hover-active;
							cursor: pointer;
						}
					}
				}
			}

			.sub {
				&.toggled {
					a {
						color: $mainmenu-1st-color-hover-active;
					}
				}
			}

			.act {
				a {
					color: $mainmenu-1st-color-hover-active;
				}
			}

			// set first element active even if 2nd menu is hovered
			.sub {
				&:hover,
				&:focus,
				&.toggled {
					& > a {
						color: $mainmenu-1st-color-hover-active;
					}

					ul {
						visibility: visible;
						opacity: 1;
						z-index: 100;
					}
				}
			}

			// 2nd level
			ul {
				visibility: hidden;
				position: absolute;
				left: 0;
				z-index: 1;
				transition: visibility .3s ease-out, opacity .3s ease-out;
				opacity: 0;
				background-color: var(--color-secondary);
				width: 300px;
				padding: 12px 0;

				.no,
				.act {
					display: block;

					a {
						display: block;
						padding: 5px 5px 5px 30px;
						margin: 0;
						font-size: $mainmenu-2nd-font-size;
						color: $mainmenu-2nd-color;
						font-weight: $font-weight-base;
						@include hoverDeviceQuery {
							&:hover,
							&:focus {
								color: $mainmenu-2nd-color-hover-active;
							}
						}
					}
				}

				.act {
					a {
						color: $mainmenu-2nd-color-hover-active;
					}
				}
			}
		}
	}
}
