.cc_banner-wrapper {
	.cc_container {
		border-top: $cookie-border;
		background-color: $cookie-background;

		.cc_message {
			color: $cookie-color;
			font-size: $cookie-font-size;
		}

		.cc_btn,
		.cc_btn:visited {
			background-color: $cookie-button-background;
			color: $cookie-button-color;
		}
	}
}
