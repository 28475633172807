// default
.focusteaserlist,
.focusteaser{
    .col-12{
        position: relative;
    }
}
.focusteaser-text-col{
    z-index: 1; // firefox fix
}
.focusteaser-image-col{
    z-index: 2; // firefox fix
}
.focusteaser__box{
    position: relative;
    display: inline-block;
    @include media-breakpoint-up(md) {
        margin-right: calc(var(--bs-gutter-x) * -.5);
        margin-left: calc(var(--bs-gutter-x) * -.5);
    }
}
.focusteaser__box::before{
	content: "";
    background-color: var(--color-white);
    height: 100%;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    @include media-breakpoint-up(md) {
        width: calc(#{divide(100%,4) * 7} - var(--bs-gutter-x));
        left: calc(#{ divide(100%,4) * -3} + var(--bs-gutter-x) * 0.5);
    }
}
.focusteaser__titleinner{
    position: relative;
    padding: 0 calc(var(--bs-gutter-x) * 1.5);
    margin-top: 26px;
    @include media-breakpoint-up(md) {
        @include make-col(3, 4);
        padding: 0 calc(var(--bs-gutter-x) * .5);
        margin-top: 60px;
    }
}
.focusteaser__textinner{
    position: relative;
    padding: 0 calc(var(--bs-gutter-x) * 1.5) 0 calc(var(--bs-gutter-x) * 1.5);
    margin-bottom: #{$spacer * 2.5};
    margin-top: 30px;
    @include media-breakpoint-up(md) {
        padding: 0 calc(var(--bs-gutter-x) * 1.5) 0 calc(var(--bs-gutter-x) * .5);
    }
    @include media-breakpoint-up(lg) {
        @include make-col(4, 5);
        @include make-col-offset(1, 5);
        margin-bottom: 60px;
    }
    @include media-breakpoint-up(xl) {
        @include make-col(3, 4);
        @include make-col-offset(1, 4);
        margin-bottom: 60px;
    }
}


.focusteaserlist__slider{
    width: 100%;
    @include media-breakpoint-up(md) {
        width: calc(100% + var(--container-padding-x));
        margin-left: calc( var(--container-padding-x) * -1);
    }

    overflow: hidden;
}
.focusteaserlist__box{
    position: relative;
    display: flex;
    @include media-breakpoint-down(md) {
        padding: 30px;
        margin: 0;
    }
}
.focusteaserlist__box::before{
	content: "";
    background-color: var(--color-white);
    width: 100%;
    height: 100%;
    left: calc(var(--bs-gutter-x) * 0.5);
    left: 0;
    position: absolute;
    top: 0;
    @include media-breakpoint-up(md) {
        top: -128px;
        left:  calc(#{divide(100%,6) * -1} + var(--bs-gutter-x) * 0.5);
        height: calc(100% + 128px);
        width: calc(#{divide(100%,6) * 7} - var(--bs-gutter-x));
    }
    @include media-breakpoint-up(lg) {
        top: -128px;
        left:  calc(#{divide(100%,5) * -2} + var(--bs-gutter-x) * 0.5);
        height: calc(100% + 128px);
        width: calc(#{divide(100%,5) * 7} - var(--bs-gutter-x));
    }
}
.focusteaserlist__title{
    z-index: 3;
}
.focusteaserlist__titleinner{
    position: relative;
    margin-top: 26px;
    @include media-breakpoint-up(md) {
        @include make-col(4, 5);
        margin-top: 60px;
    }
    @include media-breakpoint-down(md) {
        padding: 0;
    }
}
.focusteaserlist__textinner{
    position: relative;
    margin-bottom: 30px;
    margin-top: 26px;
    @include media-breakpoint-down(md) {
        margin-bottom: 0;
        padding: 0;
    }
    @include media-breakpoint-up(md) {
        @include make-col(5, 6);
        margin-bottom: 60px;
    }
    @include media-breakpoint-up(xl) {
        @include make-col(3, 5);
        @include make-col-offset(1, 5);
        margin-bottom: 60px;
    }
}
.carousel-item--storyteaser{
    margin: calc(var(--bs-gutter-x) * -.5);
    width: calc(100% + var(--bs-gutter-x));
}
.focusteaserlist__bg{
    z-index: 1;
}


// animate
.focusteaserlist{
    // fix layout!
    .carousel-inner{
        overflow: initial;
        width: 100%;
    }
    .carousel-item.active,
    .carousel-item-next,
    .carousel-item-prev{
        display: flex;;
    }
    // before
    .carousel-item {
        opacity: 1;
        transform: none; // ignore default
        transition: all 1s ease-out;
        position: relative;
        .focusteaserlist__textinner,
        .focusteaserlist__titleinner{
            opacity: 0;
            transform: translateX(-100px);
            transition: all 1s ease-in-out;
        }
        .focusteaserlist__slider{
            transform: scale(1.1);
            clip-path: inset(0 100% 0 0);
            transition: all .8s cubic-bezier(0.34, 0.02, 0, 0.93) .2s;
        }
    }
    // active
    .carousel-item.active,
    .carousel-item-next.carousel-item-start,
    .carousel-item-prev.carousel-item-end {
        z-index: 1;
        opacity: 1;
        .focusteaserlist__textinner,
        .focusteaserlist__titleinner{
            transform: translateX(0);
            opacity: 1;
        }
        .focusteaserlist__slider{
            transform: scale(1);
            clip-path: inset(0 0 0 0);
        }
        
    }
    .carousel-item-next.active.carousel-item-start,
    .carousel-item-prev.active.carousel-item-end{
        .focusteaserlist__box::before{
            @include media-breakpoint-up(md) {
                display: none !important;
            }
        }
    }
    // end
    .active.carousel-item-start,
    .active.carousel-item-end {
        z-index: 0;
        opacity: 1;
        transition: all 1s ease-out;
        position: absolute;
        .focusteaserlist__textinner,
        .focusteaserlist__titleinner{
            transform: translateX(0);
            opacity: 0;
            transition-property: opacity; // nur fade
        }
        .focusteaserlist__slider{
            transform: scale(1);
            clip-path: inset(0 0 0 100%);
            transition: all .8s cubic-bezier(0.34, 0.02, 0, 0.93) .0s;
        }
    }
    // indicators
    .carousel-indicators{
        width: auto;
        height: auto;
        flex-wrap: wrap;
        @include media-breakpoint-down(md) {
            justify-content: flex-end;
        }
    }
    .focusteaser-indicator{
        position: relative;
        display: inline-flex;

        justify-content: center;
        align-items: center;

        width: 38px;
        height: 38px;
        margin: 2px;

        transform: scale(#{math.div(7,38)});
        background-color: var(--color-body);
        border: 2px solid var(--color-body);
        border-radius: 50%;

        cursor: pointer;

        opacity: 1;
        text-indent: initial;

        &::after{
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            border-radius: 50%;
            background-color: var(--color-body);
            border: 2px solid var(--color-body);
            box-sizing: content-box;
            top: -2px;
            left: -2px;
            transform-origin: center;
        }
        &::after{
            background: var(--color-body);
            transform: scale(0);
            transition: none;
        }

        &.active{
            transform: scale(1);
            transition: transform 0.15s ease-out;
            background-color: var(--color-white);
            &::after{
                transform: scale(1);
                transition: transform 5.5s linear;
            }
            .focusteaserlist__number{
                mix-blend-mode: difference;
                color: var(--color-white);
            }
        }
        &.first::after{
            animation-name: firstindicator;
            animation-duration: 5.5s;
            animation-timing-function: linear;
        }
    }
    @keyframes firstindicator {
        from {transform: scale(0);}
        to {transform: scale(1);}
      }
    // index
    .focusteaserlist__number{
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-content: center;
        color: var(--color-body);
    }
    @include hoverDeviceQuery {
        &:hover{
            .focusteaser-indicator{
                transform: scale(0.4);
                transition: transform .18s cubic-bezier(0.165, 0.84, 0.44, 1);
                &.active{
                    transform: scale(1);
                }
                // TODO transition?
                // &:not(.active):hover{
                //     transform: scale(1);
                //     border-width: 0;
                //     background: var(--color-white);
                //     transition: none;
                // }
            }
            .focusteaser-indicator.active::after{
                display: none;
            }
            .focusteaserlist__number{
            }
        }
    }
}