.sponsors-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;

  &.grid-layout-2 {
    grid-template-columns: repeat(2, 1fr);

    .sponsor {
      --sponsor-card-aspect-ratio: 1.5;
    }

    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(1, 1fr);
      gap: 30px;
    }
  }

  &.grid-layout-3 {
    .sponsor {
      --sponsor-card-aspect-ratio: 2;
    }
  }

  &.grid-layout-4 {
    grid-template-columns: repeat(4, 1fr);

    .sponsor {
      --sponsor-card-aspect-ratio: 2;

      @include media-breakpoint-down(xl) {
        --sponsor-card-aspect-ratio: 1.5;
      }

      @include media-breakpoint-down(lg) {
        --sponsor-card-aspect-ratio: 2;
      }
    }
  }

  &.grid-layout-3,
  &.grid-layout-4 {
    @include media-breakpoint-down(lg) {
      gap: 15px;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .sponsor {
    display: flex;
    min-height: 0;
    aspect-ratio: var(--sponsor-card-aspect-ratio);
  }
}

.sponsor-card {
  width: 100%;
  background-color: var(--color-white);
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: auto;
    width: auto;
    min-width: 0;
    max-height: 100%;
    transition: transform 0.3s;
  }

  &.linked:hover {
    img {
      transform: scale(1.05);
    }
  }

  @include media-breakpoint-down(lg) {
    padding: 30px;
  }

  @include media-breakpoint-down(md) {
    padding: 15px;
  }
}
