.imagetext-box2images{
    h4{
        max-width: 800px;;
    }
    p{
        max-width: 600px;;
    }
}
.box2images__text p{
    @include media-breakpoint-up(md){
        font-size: var(--body-lg);
    }
}