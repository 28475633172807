.footerMenu {
	padding: 0;
	ul {
		padding: 0;
		margin: 0;
		display: block;
		text-align: right;
	}

	.no,
	.act {
		list-style: none;
		display: inline-block;
		@include media-breakpoint-down(md){
			margin-bottom: 15px;
		}

		span,
		a {
			color: $footermenu-color;
			padding: 0;
			margin-left: 15px;
			@include media-breakpoint-up(md){
				margin-left: 45px;
			}
		}
		a {
			@include hoverDeviceQuery {
				&:hover,
				&:focus {
					color: var(--color-active);
				}
			}
		}
	}

	.act {
		a {
			color: $footermenu-color-hover-active;
		}
	}
	
	.icon{
		display: none;
	}
}
