//big image
.imagetext-bigimage{
	.title-xl,.title-lg{
		margin-bottom: #{$spacer * 3};
	}
	.title-md,.title-sm{
		margin-bottom: #{$spacer * 2};
	}
}
.bigimage__captionbox{
	background-color: var(--color-white);
	margin-right: var(--bs-gutter-x);
	display: flex;
	margin-top: -15px;
	@include media-breakpoint-up(md) {
		margin-left: calc(var(--bs-gutter-x) * -1);
		margin-right: var(--bs-gutter-x);
		margin-top: calc(var(--bs-gutter-x) * -1);
	}
}
.bigimage__captioninner{
	margin: 30px 0;
	padding: 0 40px;
	text-align: right;
	font-size: $small-font-size;
	p{
		font-size: var(--body-xs);
		&:last-child{
			margin-bottom: 0;
		}
	}
}


// big image
.col-container--imageCaption{
	
}