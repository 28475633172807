@include media-breakpoint-down(md) {
	.mm-ocd {
		//top: $mobile-header-height;
		top: 0;
	}

	.mm-ocd--open {
		overflow: initial;
		background: rgba($content-divider-color, .25);
		background: linear-gradient(to bottom, transparent 0%, transparent $mobile-header-height, rgba($grey-80, .25) $mobile-header-height, rgba(var(--color-grey-80), .25) 100%);

		.mm-ocd__content {
			width: 100%;
			overflow: hidden;
		}

		.mainMenu {
			// sub menu
			&.mm-spn--navbar {
				// back icon
				&::before {
					border: 0;
					opacity: 1;
					transform: none;
					@include icon--bg--chevron-left-black;
					background-repeat: no-repeat;
					background-size: 7px auto;
					background-position: left 0 top 3px;
					width: 100%;
					height: 48px;
					left: 21px;
					top: 11px;
					padding-left: 21px;
					color: var(--color-grey-60);
					font-size: 14px;
				}

				&:lang(de) {
					&::before {
						content: 'Zurück';
					}
				}

				&:lang(fr) {
					&::before {
						content: 'Retour';
					}
				}

				// parent element
				&::after {
					top: 48px;
					opacity: 1;
					text-align: left;
					padding: 15px 50px 14px 20px;
					font-size: 18px;
					font-weight: 500;
					color: $mainmenu-1st-color;
					line-height: 1.33;
					border-top: 1px solid $content-divider-color;
					height: auto;
				}

				ul {
					top: 102px;

					&::before {
						border-color: $content-divider-color;
						opacity: 1;
					}
				}
			}

			// 1st level
			&.mm-spn--main {
				&::after {
					display: none;
				}

				.mm-spn--open {
					top: 0;

					&::before {
						display: none;
					}
				}
			}
		}
	}

	.mm-ocd__content {
		top: $mobile-header-height;
	}

	.mm-spn {
		ul {
			z-index: 3;
			padding-bottom: 80px;
		}
	}

	.mm-spn.mm-spn--light {
		background-color: var(--color-white);
	}

	// default padding for 2nd level if closed
	.mm-spn a,
	.mm-spn span {
		padding: 17px 40px 17px 20px;
	}

	.mainMenu {
		background-color: var(--color-white);
		color: var(--color-body);
		padding-bottom: 80px;

		.mm-spn--open {
			// 1st level
			.no,
			.act {
				&::after {
					margin-left: 0;
					opacity: 1;
					border-color: $content-divider-color;
				}

				a {
					padding: 17px 50px 14px 20px;
					font-size: 18px;
					font-weight: 500;
					color: $mainmenu-1st-color;

					&::after {
						border-color: $content-divider-color;
						opacity: 1;
					}
				}
			}

			.act {
				a {
					color: $mobileMenu-1st-color-hover-active;
				}
			}

			.sub {
				&::before {
					border: 0;
					opacity: 1;
					transform: none;
					@include icon--default-settings;
					@include icon--chevron-right-black;
					width: 7px;
					left: inherit;
					right: 22px;
					top: 17px;
					z-index: 2;
				}
			}

			// 2nd level
			.mm-spn--open {
				.no,
				.act {
					a {
						padding: 17px 50px 17px 20px;
						font-size: 14px;
						color: $mainmenu-2nd-color;
					}
				}

				.act {
					a {
						color: $mainmenu-2nd-color-hover-active;
					}
				}

				// 3th level
				.mm-spn--open {
					.no {
						a {
							color: $mainmenu-2nd-color;
						}
					}
				}
			}
		}
	}

	.mm-ocd__content {
		// menu bottom
		.menuBottom {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			padding-top: 15px;

			background-color: var(--color-white);
			min-width: 284px;

			.metaMenu--mobileBottom {
				margin: 0;
				padding: 0 20px 16px;
				font-size: 14px;

				.no,
				.act {
					list-style: none;
					padding: 3px 0;

					a {
						color: var(--color-body);
					}
				}

				.act {
					a {
						color: var(--color-primary);
					}
				}
			}
		}

		.langSwitchWrap {
			display: inline-block;
			vertical-align: top;
		}

		.langSwitchMobile {
			margin: 0;
			padding: 5px 15px;
			text-align: center;
			font-size: 14px;
			border-top: 1px solid $content-divider-color;

			.no,
			.act {
				list-style: none;
				padding: 0 7px;
				display: inline-block;

				a {
					color: var(--color-body);
				}
			}

			.act {
				a {
					color: var(--color-primary);
				}
			}
		}
	}

	// close area
	.mm-ocd__backdrop {
		left: 50% !important;
		margin-left: 311px;
		height: $mobile-header-height;
		top: 0;
		width: 38px;
		z-index: 99999;
		display: inline-block;
		min-width: 38px;
	}
}

@include media-breakpoint-down(sm) {
	// close area
	.mm-ocd__backdrop {
		margin-left: 219px;
	}
}

@include media-breakpoint-down(xs) {
	// close area
	.mm-ocd__backdrop {
		left: initial !important;
		right: 10px;
	}
}
