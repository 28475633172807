.tx-solr-search-header{
    margin-top: 25vh;
}
.tx-solr-search-form__container{
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    @include media-breakpoint-down(md){
        margin-top: $spacer * 7;
    }
    .tx-solr-search-form{
        width: 100%;
    }
    .filter__search-text-input{
        font-size: var(--body-xl);
        margin-left: 0;
        margin-right: 0;
    }
}
.tx-solr-search-form__inner{
    display: block;
    width: 100%;
}
.result-found--search{
    font-size: var(--body-xs);
    padding-top: 10px;
    margin-bottom: $spacer * 2;
    @include media-breakpoint-up(md){
        margin-bottom: -10px;
    }
}
.searchresult__title{
    font-size: var(--body-lg);
    font-family: var(--bs-body-font-family);
    font-weight: var(--body-medium);
    margin-top: #{$spacer * 7};
    margin-bottom: #{$spacer * 3};
    @include media-breakpoint-down(md){
       display: none;
    }
}
.result-found{
    font-size: var(--body-xs);
    margin-top: 5px;
}

.is-loading-page{
    .page-link{
        pointer-events: none;
        opacity: .4;
    }
}

//CARD
.searchcard{
    margin-top: 20px;
    margin-bottom: $spacer * 3;
    position: relative;
}
.searchcard__image{
    @include make-col(1,2);
    margin-top: -20px;
    margin-bottom: 20px;

    img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.searchcard__image-ratio{
    position: relative;
    padding-bottom: 79.71%;
    img{
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}
.searchcard__content{
    @include make-col(1,2);
    position: relative;
    &:before{
        content: "";
        position: absolute;
        top: 0;
        right: calc(var(--bs-gutter-x) * .5);
        width: calc(200% - var(--bs-gutter-x)  - 20px);
        height: 100%;
        background-color: var(--color-white);
        z-index: -1;
    }
}
.searchcard__inner{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 20px 20px 0;
}
.searchcard__title{
    font-size: var(--body-xs);
    font-weight: var(--body-medium);
    line-height: var(--body-low);
}
.searchcard__inner-date{
    color: var(--color-body);
    font-size: var(--body-xxs);
    font-weight: var(--body-medium);
    line-height: var(--body-low);
    margin-bottom: #{$spacer};
    .icon{
        width: 13px;
    }
}

// autocomplete slr
.autocomplete-suggestions{
    background-color: var(--color-white);
    font-weight: var(--body-xs);
    padding: 10px;
}

// LOADING
#loading{

    display: none;
    text-align: center;

    font-weight: var(--body-lg);
    color: var(--color-default);

    &.show{
        display: block;
    }
}

// SPINNER
.spinner {
    display: inline-block;
    width: 80px;
    height: 80px;
}
.spinner:after {
    content: " ";
    display: block;
    width: 38px;
    height: 38px;
    margin: 8px;
    border-radius: 50%;
    border: 2px solid var(--color-default);
    border-color: var(--color-default) transparent var(--color-default) transparent;
    animation: spinner-anim 1.2s linear infinite;
}
@keyframes spinner-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}