.tooltip-inner{
	text-align: left;
	box-shadow: 0 0 10px rgb(0 0 0 / 10%);
	h5{
		font-size: var(--body-sm);
		font-weight: var(--body-medium);
		margin-bottom: 18px;
	}
	p{
		font-size: var(--body-sm);
		margin: 0;
	}
	.tooltip-header{
		font-size: var(--body-md);
		font-weight: 500;
		margin-bottom: 15px;
	}
	.tooltip-text{
		font-size: var(--body-md);
	}
}