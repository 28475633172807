// Override if you use it in your theme

$spacer: 15px;
$spacers: (
  0: 0,
  1: $spacer * .5, // 7.5px
  2: $spacer,
  3: $spacer * 1.5,
  4: $spacer * 2, // 30px
  5: $spacer * 2.5,
  6: $spacer * 3, // 45px
  7: $spacer * 4, // 60px
);

$spacebetween-elements: 150px;
$spacebetween-elements-md: 90px;

/*
// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;

$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null) !default;
// scss-docs-end spacer-variables-maps
*/
