.ce-image {
	.ce-gallery {
		margin-bottom: 0;
	}
}

.ce-gallery {
	&[data-ce-columns="2"] {
		.ce-column {
			@include media-breakpoint-up(md) {
				flex: 0 0 50%;
				max-width: 50%;
			}
		}
	}

	&[data-ce-columns="3"] {
		.ce-column {
			@include media-breakpoint-up(md) {
				flex: 0 0 33.3333%;
				max-width: 33.3333%;
			}
		}
	}

	&[data-ce-columns="4"] {
		.ce-column {
			@include media-breakpoint-up(md) {
				flex: 0 0 25%;
				max-width: 25%;
			}
		}
	}

	.ce-column {
		margin: 0;
		margin-bottom: math.div($grid-gutter-width, 2);

		@include media-breakpoint-up(md) {
			margin-bottom: $grid-gutter-width;
		}
	}

	figure {
		margin: 0;
	}
}

// image caption
.image-caption {
	background-color: var(--color-secondary);
	padding: 14px 15px;
	font-size: .8rem;

	@include media-breakpoint-up(md) {
		font-size: .73rem;
		padding: 22px 26px;
	}

	p {
		margin: 0;
	}
}