$triangle-size: 15px;
.dropdownShare{
    border-radius: 0;
    border: none;
    padding: #{$spacer * 2} #{$spacer * 1.5};
    &.show{
        margin-bottom: $triangle-size !important;
    }
    &--rate{
        min-width: 260px;
    }
}
.dropdownShare__title{
    display: block;
    font-size: var(--body-sm);
    font-weight: var(--body-medium);
    margin-bottom: 25px;
}
.dropdownShare__title--voted{
    display: none;
}
.voted{
    .dropdownShare__title{
        display: none;
    }
    .dropdownShare__title--voted{
        display: block;
    }
    .dropdownShare__list{
        pointer-events: none;
    }
}
.dropdownShare__list{
    display: flex;
    justify-content: space-between;
}
.dropdownShare::after{
    content: "";
    position: absolute;
    top: 100%;
    left: $triangle-size*2;
    width: 0; 
    height: 0; 
    border-left: $triangle-size solid transparent;
    border-right: $triangle-size solid transparent;
    
    border-top: $triangle-size solid var(--color-white);
}

.rated{
    fill: var(--color-cheese);
}