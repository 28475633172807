.tags{
    margin: #{$spacer * 3} -7px 0;
}

.tag{
	position: relative;
	height: $tag-height-md;
	// center content
	display: inline-flex;
    align-items: center;
    justify-content: center;
	flex-shrink: 0;

	box-sizing: border-box;
	padding: 6px 24px;
	border: none;

	font-size: var(--body-sm);
    font-weight: var(--body-medium);
	text-decoration: none;

	transition: transform $animation_duration $animation_curve;

	background-color: transparent;

	margin: 0 10px 10px 0;

	span{
		pointer-events: none;
		position: relative;
		white-space: nowrap;
		color: var(--color-body);
		z-index: 2;
		line-height: var(--body-low);
	}

	&::before{
		pointer-events: none;
		content: "";
		position: absolute;
		top: 0;
		left: 0;

		box-sizing: border-box;
		width: 100%;
		height: 100%;

		z-index: 1;

		border-width: $tag-border-width;
		border-radius: $tag-height-md * .5;
		border-style: solid;

		border-color: var(--color-cheese);
		transition: border $animation_duration $animation_curve;
	}
	@include hoverDeviceQuery {
		&:hover{
			&::before{
				border-width: $tag-height-md * .5;
			}
		}
	}

	&.tag--sm{
		height: $tag-height-sm;
		padding: 5px 14px;
		border-radius: $tag-height-sm * .5;
		font-size: var(--body-xs);
		&::before{
			border-radius: $tag-height-sm * .5;
		}
		@include hoverDeviceQuery {
			&:hover{
				&::before{
					border-width: $tag-height-sm * .5;
				}
			}
		}
	}
	&.tag--xs{
		height: $tag-height-xs;
		padding: 4px 12px;
		border-radius: $tag-height-xs * .5;
		font-size: var(--body-xxs);
		&::before{
			border-radius: $tag-height-xs * .5;
		}
		@include hoverDeviceQuery {
			&:hover{
				&::before{
					border-width: $tag-height-xs * .5;
				}
			}
		}
	}
}


.tag--disabled{
	pointer-events: none;
	&::before{
		border-color: var(--color-body);
	}
}

.tag--topic{
	&::before{
		border-color: var(--color-topic);
	}
	@include hoverDeviceQuery {
		&:hover{
			span{
				color: var(--color-white);
			}
		}
	}
}
@include hoverDeviceQuery {
	[style*="--color-cheese"],
	[style*="--color-story"],
	[style*="--color-inspiration"],
	[style*="--color-white"],
	[style*="--color-cheese"]{
		.tag--topic:hover{
			span{
				color: var(--color-body);
			}
		}
	}
}