.teasercheesfinder__imagebox{
	position: relative;
	display: block;
	width: calc(100% + var(--container-padding-x));
	margin-left: calc(var(--container-padding-x) * -1);
	img{
		display: block;
		min-width: 90%;
		max-width: 90%;
		margin: 0 auto;
	}
    @include media-breakpoint-down(md) {
		margin-bottom: 30px;
		width: calc(83.3% + var(--container-padding-x));
	}
}
.teasercheesfinder__imagebox::before{
    content: "";
    position: absolute;
    top: 18%;
    left: calc(var(--container-padding-x) * -1);
    width: calc(100% + var(--container-padding-x));
    height: 64%;
    z-index: -1;

    background: var(--color-white);
}
.teasercheesfinder__headerbox{
    margin: #{$spacer * 3} 0;
}
.teasercheesfinder__contentbox{
	p{
		max-width: 600px;
	}
	@include media-breakpoint-up(md) {
		@include make-col(3, 4);
	}
}
.teasercheesfinder__text{
	@include media-breakpoint-down(md) {
		@include make-col(5, 6);
		@include make-col-offset(1, 6);
	}
}
.teasercheesfinder__tags{
	margin-top: #{$spacer * 2};
	@include media-breakpoint-up(md) {
		margin-top: #{$spacer * 3};
	}
}