$navHeight : 100px;
$main-page-fade-in-dur: 0.25s;

// HEADER
.headercontent{
	position: relative;
	--body-size: var(--body-lg);
	@include media-breakpoint-down(md) {
		margin-bottom: #{$spacer * 4};
	}
	// > .row{
	// 	min-width: 100%;
	// }
	> .row{
		@include media-breakpoint-down(md) {
			margin-top: 90px;
		}
		@include media-breakpoint-up(md) {
			//min-height: 100vh;
		}
	}
	.row-header-cheesfinder{
		@include media-breakpoint-up(md) {
			min-height: calc(80vh - 240px);
			margin-top: 120px;
			margin-bottom: 90px;
		}
	}

	.col-header-level-1{
		@include media-breakpoint-down(md) {
			margin-top: #{$spacer * 3};
			margin-bottom: #{$spacer * 3};
		}
	}

	.col-header-level-2{
		@include media-breakpoint-down(md) {
			margin-top: #{$spacer * 3};
			margin-bottom: #{$spacer * 3};
		}
		@include media-breakpoint-up(md) {
			height: 100vh;
			margin-bottom: 0;
		}
	}

	.col-header-level-3{
		margin-bottom: #{$spacer * 4};
		@include media-breakpoint-down(md) {
			margin-top: #{$spacer * 4};
		}
		@include media-breakpoint-up(md) {
			max-height: 80vh;
		}
		& + .col-12{
			margin-bottom: #{$spacer * 4};
		}
	}
}

header{
	.headercontent{
		padding-top: 0;
	}
}
.btn-row--scroll{
	position: absolute;
	bottom: 0;
	left: calc(50% - 35px);
	justify-content: center;
	width: 70px;
	padding: 10px;
}
.headercontent__title-half-height{
	@include media-breakpoint-up(md) {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		height: 50vh;
	}
}
.headercontent__title-half-height--lv3{
	@include media-breakpoint-up(md) {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		height: 40vh;
	}
}
.headercontent__title-half-height--lv3--height{
	@include media-breakpoint-up(md) {
		height: 30vh;
	}
}
.headercontent__bodytext{
	@include make-col(5, 6);
	@include make-col-offset(1, 6);

	max-width: 600px;

	@include media-breakpoint-up(md) {
		@include make-col(4, 6);
		@include make-col-offset(1, 6);
	}
}
.business-header__title{
	margin-bottom: #{$spacer * 4};
	@media screen and (min-height: 768px) and (max-height: 920px){
		font-size: min(
			calc(57px + 48 * ((100vw - 768px) / 744)),
			calc(48px + 57 * ((100vh - 768px) / 152))
		);
	}
	@media screen and (max-height: 843px){
		font-size: 48px;
		margin-bottom: #{$spacer * 3};
	}
	@media screen and (max-height: 768px){
		font-size: 48px;
		margin-bottom: #{$spacer * 2};
	}
}
.headercontent__bodytext--business{
	@include media-breakpoint-down(md) {
		@include make-col(1, 1);
		margin-left: 0;
		margin-bottom: #{$spacer * 4};
	}
}
.headercontent__bodytext--recipes{
	p{
		max-width: 600px;
	}
}

.headercontent__bodytext--error,
.headercontent__bodytext--cheesbrand,
.headercontent__bodytext--theme,
.headercontent__bodytext--readmore,
.headercontent__bodytext--share,
.headercontent__bodytext--story{
	@include media-breakpoint-down(md) {
		@include make-col(5, 6);
		@include make-col-offset(1, 6);
	}
	@include media-breakpoint-up(xl) {
		@include make-col(4, 5);
		@include make-col-offset(1, 5);
	}
	p{
		max-width: 600px;
	}
}
.headercontent__bodytext--error,
.headercontent__bodytext--cheesbrand,
.headercontent__bodytext--theme{
	@include media-breakpoint-up(md) {
		margin-bottom: 90px;
	}
}

.headercontent__bodytext--readmore{
	position: relative;
}
// read more
// .readmore-col{
// 	@include media-breakpoint-up(md) {
// 		margin-top: calc(50vh - 200px);
// 	}
// }
.collpase--readmore:not(.show) {
	display: block;
	height: 150px;
	overflow: hidden;
	&::after{
		bottom: 0;
	}
}
.collpase--readmore{
	position: relative;
	&::after{
		content: "";
		height: 2rem;
		width: 100%;
		position: absolute;
		left: 0;
		bottom: -2rem;
		width: 100%;
		background: linear-gradient(rgba(255,255,255,0), var(--color-grey));
	}
}
.show-collapsed,
.hide-collapsed{
	font-size: 12px; // fexed font size!
	font-weight: var(--body-medium);
}
.show-collapsed + svg,
.show-collapsed{
	display: none;
}
.collapsed{
	.show-collapsed + svg,
	.show-collapsed{
		display: block;
	}
	.hide-collapsed + svg,
	.hide-collapsed{
		display: none;
	}
}

.readMore--fixed{
	button.readmore__button{
		display: none !important;
	}
	.collpase--readmore{
		height: auto;
		&::after{
			content: none;
		}
	}
}
// header images
.content-header__wrap{
	position: relative;
	width: 100%;
	max-width: 50vh;
	margin: 0 auto;
}
.content-header__image{
	width: 100%;
	@include media-breakpoint-up(md){
		margin-top: 50vh;
		transform: translateY(-50%);
	}
}
.content-header__image--level-3{
	width: 100%;
	@include media-breakpoint-up(md){
		margin-top: 40vh;
	}
}
.content-header__mask{
	clip-path: circle(49% at 50% 50%);
  transform: translateZ(0);
  overflow: hidden;
	padding: 100% 0 0;
	width: 100%;
	position: relative;
	background: var(--color-white);

	img{
		width: 100%;
		height: 100%;
		object-fit: cover;
		position: absolute;
		top: 0;
		left: 0;
	}
}

.content-header__wrap{
	width: 100%;
	max-width: calc(100vh - 180px);
	margin: 0 auto;
	@include media-breakpoint-down(md){
		margin-bottom: 0;
	}
}
.content-header__wrap--level-3{
	@include media-breakpoint-up(xl){
		width: 83.333%;
		max-width: calc(80vh - 180px);
	}
}
.content-header__wrap--quat-img{
	clip-path: none;
}
.content-header__video{
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
	background: var(--color-grey);
}
.content-header__label{
	position: absolute;
	right: calc( var(--bs-gutter-x) * -.5);
	top: 5px;
	width: 35%;
	padding-bottom: 35%;
	background: var(--color-white);
	border-radius: 50%;

	img{
		width: 100%;
		height: 100%;
		object-fit: cover;
		position: absolute;
		top: 0;
		left: 0;
	}
}
// CONTENT HEADER
.recipes-header{
	margin-top: 135px; // header height
}
.recipes-header-image{
	margin-left: auto;
	margin-right: auto;
	@include media-breakpoint-down(md) {
		margin-bottom: #{$spacer*5};
	}
}
// HOME HEADER
.header--Home{
	margin-bottom: $spacebetween-elements-md;
	@include media-breakpoint-up(md) {
		margin-bottom: $spacebetween-elements;
	}
}
.header{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	@include media-breakpoint-down(md) {
		overflow: hidden;
		position: absolute;
		top: 0;
		width: 100%;
	}
}
.menu-open .header{
	@include media-breakpoint-down(md) {
		height: 100vh;
	}
}

.video-header{
	position: relative;
	height: 100vh;
	width: 100vw;

	video{
		object-fit: cover;
	}
}

.container-header {
	position: fixed;
	top: 0;
	left: 0;
	height: 90px;
	z-index: 110;
	background-color: transparent;
	transition: transform 0.35s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.35s linear;
}
@include media-breakpoint-up(lg) {
	.body-scroll-down {
		.container-header {
			transform: translateY(-90px);
			transition: transform 0.35s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.35s linear 0.35s;
		}
	}
}
.body-scrolled {
	.container-header {
		background-color: var(--color-grey);
	}
}
@include media-breakpoint-down(lg) {
	.header--Business .container-header {
		background-color: var(--color-grey);
	}
}


.headerLogo{
	height: 60px;
	display: flex;
	align-items: center;
}
.menu-open{
	@include media-breakpoint-up(md){
		.mainNav__business{
			display: inline-flex !important;
		}
	}
	@include media-breakpoint-down(md){
		.header--Main{
			// position: fixed;
			z-index: 1;
			// top: 0;
			// background: var(--color-white);
		}
	}

	@include media-breakpoint-down(lg){
		.headerLogo{
			display: none;
		}
		.mainNav{
			width: 100%;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-items: flex-start;
			display: flex;
		}
		.mainNav__search,
		.mainNav__lang{
			display: inline-flex !important;
		}
		.mainNav__search{
			margin: 0;
		}
		.mainNav__lang{
			margin-left: 0;
		}
		.mainNav__menu{
			margin-left: auto;
		}
	}
	@include media-breakpoint-down(md){
		.mainNav__business-mobile{
			display: inline-flex;
		}
	}

}

.header--Main{
	@include media-breakpoint-up(md){
		.headercontent > .row{
			height: 100vh;
			max-height: var(--viewport-height);  // browserzoom
		}
	}
}
// THEME
.header--Theme{
	margin-bottom: #{$spacer * 2};
}


// BRANCHE
.mainNav__business-mobile,
.mainNav__business{
	margin: 0 auto;
	display: none;
	span{
		line-height: var(--body-mid);
		border-bottom: 2px solid var(--color-default);
	}
}
.mainNav__business-mobile{
	margin-left: -18px;
}
.mainNav__business{
	margin-right: 90px;
}
.mainNav__search,
.mainNav__lang{
	@include media-breakpoint-down(lg){
		display: none !important;
	}
	span{
		text-transform: lowercase;
	}
}
.row--mainnav{
	position: relative;
	margin: 0;
	width: 100%;
	padding-bottom: 60px;
	@include media-breakpoint-up(lg){
		height: 100%;
	}
}

// video overlay
.container-header-overlay{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;

	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	color: var(--color-white);

	padding-bottom: 0;
	@include media-breakpoint-up(md){
		padding-bottom: #{$spacer * 6};
	}
}
.row-header-overlay--title{
	h1{
		color: var(--color-white);
		margin-bottom: 56px;
	}
}
.row-header-overlay--text{
	justify-content: space-between;
	@include media-breakpoint-down(md) {
		margin-bottom: 100px;
	}
}
.row-header-overlay--scroll{
	justify-content: center;
	margin: -30px 0 50px;
	@include media-breakpoint-down(md) {
		margin: 25px 0;
	}
}
// Business
.business-header__image-container{
	height: calc(100vh - 77.5px); // half foooter scoll button height
	margin-bottom: 77.5px;
	width: calc(100% + var(--container-padding-x) + var(--bs-gutter-x));
	margin-left: calc(-.5 * var(--bs-gutter-x));
	@include media-breakpoint-down(md) {
		height: calc(100vh - #{$spacer * 6});
		width: calc(100% + var(--container-padding-x) * 2);
		margin-left: calc(-1 * var(--container-padding-x));
		margin-bottom: 0;
	}
	img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.business-header__text-container{
	@include media-breakpoint-down(md) {
		position: absolute;
		bottom: 0;
		color: var(--color-white);
		h1{
			color: var(--color-white);
		}
	}
}


// home and home animation
.home-text{
	max-width: 600px;
	font-size: var(--body-lg);
	margin: 0;
}
.home-fade-in-video{
	clip-path: inset(0 0 100% 0);
	transition: clip-path 1.05s cubic-bezier(0.02, 0.04, 0.05, 1);
	transition-delay: var(--delay);
	&.video-ready{
		clip-path: inset(0 0 0 0);
	}
}

.home-fade-in-title,
.home-fade-in-text{
	opacity: 0;
	&.video-ready{
		opacity: 1;
		transform: translateY(0);
	}
}
.home-fade-in-text{ // and CTA Button
	opacity: 0;
	transform: translateY(-20vh);
	transition: transform .8s cubic-bezier(0.02, 0.04, 0.05, 1) var(--delay), opacity 1s ease-in-out var(--delay);
}
.home-fade-in-title{
	opacity: 0;
	transform: translateY(-15vh);
	transition: transform .8s cubic-bezier(0.02, 0.04, 0.05, 1) var(--delay), opacity 1s ease-in-out var(--delay);
}
.home-fade-in-scoll{
	transform: scale(0);
	&.video-ready{
		transform: scale(1);
		transition: transform 0.18s cubic-bezier(0.02, 0.04, 0.05, 1) var(--delay);
	}
}

.col-topic-data{
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 50%;

	transform: translateX(-100%);
	opacity: 0;
	transition:
		transform 1s ease-out,
		opacity 2.2s ease-out 0.5s;
}
.col-mainnav{
	opacity: 1;
}

// animate main nav point videos
.fadeout{
	// topic column
	// .col-topic-data{
	// 	transform: translateX(0);
	// 	opacity: 1;
	// 	transition: transform 0.5s ease-out, opacity 0.5s ease-out;
	// 	transition-delay: 1s;
	// }
	// video column -> fadeout
	.mainmenu-video-mask{
		transition: transform 0s, opacity 0.3s cubic-bezier(0.26, 0.26, 0.01, 1);
		padding-top: 100%;
		opacity: 0 !important;
		clip-path: circle(49% at 50% 50%);
	}
	.col-mainnav{
		opacity: 0;
		transform: translateY(100%);
		transition: transform .25s cubic-bezier(0.165, 0.84, 0.44, 1), opacity .25s cubic-bezier(0.165, 0.84, 0.44, 1);
	}

	@include media-breakpoint-up(md){
		.col-header-video{
			opacity: 0;
			transform: translateY(10%);
			transition: transform .25s cubic-bezier(0.165, 0.84, 0.44, 1), opacity .25s cubic-bezier(0.165, 0.84, 0.44, 1);
		}
		// menu column -> fadeout
		.col-mainnav{
			opacity: 0;
			transform: translateY(10%);
			transition: transform .25s cubic-bezier(0.165, 0.84, 0.44, 1), opacity .25s cubic-bezier(0.165, 0.84, 0.44, 1);
		}
	}
}

// main header
.col-main-nav-animation-video{
	opacity: 0;
	z-index: 1;
	transition: all $main-page-fade-in-dur cubic-bezier(0.02, 0.04, 0.05, 1);
}
.col-main-nav-animation-text{
	opacity: 0;
	// transform: translate(0, -50%);
	transition: all $main-page-fade-in-dur cubic-bezier(0.02, 0.04, 0.05, 1);
}
.col-main-nav-animation-scroll-btn{
	opacity: 0;
	transform: scale(0);
	transition: all $main-page-fade-in-dur cubic-bezier(0.02, 0.04, 0.05, 1) $main-page-fade-in-dur;
}
// @include media-breakpoint-up(md){
	.col-main-nav-animation-video{
		opacity: 0;
		transform: translateY(20%);
	}
	.col-main-nav-animation-text{
		opacity: 0;
		transform: translateY(20%);
	}
// }

.loaded{
	.col-main-nav-animation-video{
		opacity: 1;
		transform: translateY(0);
	}
	.col-main-nav-animation-text{
		opacity: 1;
		transform: translateY(0);
	}
	.col-main-nav-animation-scroll-btn{
		opacity: 1;
		transform: scale(1);
	}
}
