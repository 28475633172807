// include bootstrap configuration before overrides
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/maps";

// include bootstrap overrides variables
@import '10_Overrides/Overrides';

@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

// responsive body sizes
@include media-breakpoint-down(md){
	:root {
		--body-xl: 18px;
		--body-lg: 16px;
		--body-md: 14px;
		--body-xxs: 12px;
		// default
		--body-size: var(--body-sm);
	}
}


@import '_Mixin/Typography';
@import '_Mixin/Hover';

@import '00_Font/BlackerDisplay';
@import '00_Font/ABCMonumentGrotesk';

// include own color variables
@import '20_Theme/Colors';



// include own variables
@import '20_Theme/Theme';
