.vision__container{
    margin-top: 0;
    margin-bottom: #{$spacer * 4};
    justify-content: flex-end;
    @include media-breakpoint-up(md){
        margin-top: #{$spacer * 8};
        margin-bottom: #{$spacer * 4};
    }
}
.vision__textbox{
    order: 2;
    @include media-breakpoint-up(md){
        order: 1;
        @include make-col(1,2);
    }
}
.vision__imagebox{
    order: 1;
    @include make-col(1,2);
    margin-right: calc(var(--bs-gutter-x) * .5);
    margin-bottom: #{$spacer * -3};
    @include media-breakpoint-up(md){
        order: 2;
        @include make-col(1,2);
        @include make-col-offset(0,2);
        margin-top: #{$spacer * -4};
        margin-bottom: 0;
        margin-right: 0;
    }
}
.vision__imageinner{
    padding-bottom: 100%;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    background: var(--color-white);
    img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.vision__textinner{
    padding: #{$spacer * 3} #{$spacer * 2};
    position: relative;
    @include media-breakpoint-up(md){
        padding: #{$spacer * 4} 0 #{$spacer * 4} #{$spacer * 4};
    }

    &::before{
        content: "";
        height: 100%;
        background: var(--color-white);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        @include media-breakpoint-up(md){
            width: calc((100% - var(--bs-gutter-x) * 5) / 6 * 11 + var(--bs-gutter-x) * 10 );
        }
        @include media-breakpoint-up(lg){
            width: calc((100% - var(--bs-gutter-x) * 2) / 3 * 5 + var(--bs-gutter-x) * 4 );
        }
    }
}
.vision__title{
    margin-bottom: #{$spacer * 3};
    font-weight: var(--body-normal);

    hyphens: auto;
}
.vision__text{
    font-size: var(--body-sm);
    margin-bottom: #{$spacer * 3};
}
.vision__btn{
    margin-right: -100%;
}