//@import "bootstrap/scss/buttons";

$topics: "primary", "inspiration", "story", "cheese", "body", "white";

$animation_duration: .18s;
$animation_curve: cubic-bezier(0.02, 0.04, 0.05, 1);

/********************** GROUP BUTTONS **********************/
// btn group
.btn-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  margin: calc(var(--bs-gutter-x)) 0;

  margin-top: $space-before-after-small-xs;
  margin-bottom: $space-before-after-small-xs;

  @include media-breakpoint-up(md) {
    margin-top: $space-before-after-small-lg;
    margin-bottom: $space-before-after-small-lg;
  }

  .btn {
    margin: 0 10px;
    max-width: 100%;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.btn-row--center {
  justify-content: center;
}

.btn-row--right {
  justify-content: flex-end;
}

.btn-row--form {
  margin-top: $spacer * 4;
  margin-bottom: 0;
  margin-top: $spacer * 4;
  flex-wrap: wrap;
  @include media-breakpoint-down(md) {
    margin-top: $spacer * 3;
    .btn {
      margin: 0 0 $spacer $spacer;
    }
  }
}


// default button
.btn--rte a, // rte button no span or svg inside
.btn {
  position: relative;
  height: $btn-height;
  border-radius: $btn-height * 0.5;

  // center content
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  box-sizing: border-box;
  padding: 0 20px;
  border: 0;
  // font size
  font-size: var(--body-sm);
  font-weight: var(--body-medium);
  text-decoration: none;
  // animate TODO: set var
  transition: transform $animation_duration $animation_curve;

  border-color: var(--color-white);
  background-color: transparent;
  // safarie
  -webkit-appearance: none;

  &:disabled {
    opacity: .4;
    cursor: no-drop;
  }
}

.btn {
  svg,
  span {
    pointer-events: none;
    position: relative;
    line-height: var(--body-low);
    //white-space: nowrap; // TODO test

    color: var(--color-body);
    z-index: 1;
  }

  svg {
    flex-shrink: 0;
  }

  svg + svg,
  span + svg,
  svg + span {
    margin-left: 10px;
  }
}

/********************** BUTTON AND ICON SIZE **********************/
.btn--rte a,
.btn {
  svg {
    width: 24px;
    height: 24px;
  }

  @include media-breakpoint-down(md) {
    height: $btn-height-md;
    border-radius: $btn-height-md * 0.5;
    padding: 0 18px;
    svg {
      width: 22px;
      height: 22px;
    }
  }

  &.btn-lg {
    height: $btn-height-large;
    padding: 0 36px;
    border-radius: $btn-height-large * 0.5;

    &::before {
      border-radius: $btn-height-large * 0.5;
    }

    @include media-breakpoint-down(md) {
      height: $btn-height;
      border-radius: $btn-height * 0.5;
      --body-sm: 12px; //TODO CHECK!
      &::before {
        border-radius: $btn-height * 0.5;
      }
    }
  }
}

/********************** BUTTON COLOR **********************/
.btn--filled {
  background-color: var(--color-topic);

  svg,
  span {
    fill: var(--color-white);
    color: var(--color-white);
  }
}

@include hoverDeviceQuery {
	.btn-outline--ignore-parent,
	.btn-outline,
	.btn-primary{
		&:hover,
		&:focus{
			svg{
				fill: var(--color-white);
			}
			span{
				color:var(--color-white);
			}
		}
	}

	.btn-parent:hover{
		.btn-circle,
		.btn--filled,
		.btn-primary,
		.btn-outline:not(.btn-ignore-parent){
			svg{
				fill: var(--color-white);
			}
			span{
				color:var(--color-white);
			}
		}
	}
	.btn-circle:hover,
	.btn--filled:hover,
	.btn-primary:hover,
	.btn-outline--ignore-parent:hover,
	.btn-outline:hover{
		svg{
			fill: var(--color-white);
		}
		span{
			color:var(--color-white);
		}
	}
	.btn-plain:hover{
		svg{
			fill: var(--color-body);
		}
		span{
			color:var(--color-body);
		}
	}
}

[style*="--color-cheese"],
[style*="--color-story"],
[style*="--color-inspiration"],
[style*="--color-white"],
[style*="--color-cheese"]{
	.btn--filled{
		svg,
		span{
			fill: var(--color-body);
			color:var(--color-body);
		}
	}
	@include hoverDeviceQuery {
		.btn-parent:hover{
			.btn-circle,
			.btn--filled,
			.btn-primary,
			.btn-outline{
				svg{
					fill: var(--color-body);
				}
				span{
					color:var(--color-body);
				}
			}
		}
		.btn-circle:hover,
		.btn--filled:hover,
		.btn-primary:hover,
		.btn-outline--ignore-parent:hover,
		.btn-outline:hover{
			svg{
				fill: var(--color-body);
			}
			span{
				color:var(--color-body);
			}
		}
	}
}

@include hoverDeviceQuery {
  .btn-parent:hover {
    .btn-circle,
    .btn--filled,
    .btn-primary,
    .btn-outline {
      svg {
        fill: var(--color-body);
      }

      span {
        color: var(--color-body);
      }
    }
  }
  .btn-circle:hover,
  .btn--filled:hover,
  .btn-primary:hover,
  .btn-outline:hover {
    svg {
      fill: var(--color-body);
    }

    span {
      color: var(--color-body);
    }
  }
}

.btn--body {
  --color-topic: var(--color-body);
}

// insert
.btn--rte a,
.btn-circle,
.btn-primary,
.btn-outline--ignore-parent,
.btn-outline {
  &::before {
    pointer-events: none;
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    box-sizing: border-box;
    width: 100%;
    height: 100%;

    z-index: 1;

    border-width: $btn-border-width;
    border-radius: $btn-border-radius;
    border-style: solid;
  }
}

// set border
.btn--rte a,
.btn-primary,
.btn-outline--ignore-parent,
.btn-outline {
  &::before {
    border-color: var(--color-topic);
    transition: border $animation_duration $animation_curve;
  }
}

@include hoverDeviceQuery {
	.btn--rte a:hover,
	.btn-parent:hover .btn-outline:not(.btn-ignore-parent),
	.btn-primary:hover,
	.btn-primary:focus,
	.btn-outline--ignore-parent:hover,
	.btn-outline--ignore-parent:focus,
	.btn-outline:hover,
	.btn-outline:focus{
		&:not(.btn-plain) {
			&::before{
				border-width: $btn-height * .5;
				@include media-breakpoint-down(md) {
					border-width: $btn-height-md * .5;
				}
			}
			&.btn-lg::before{
				border-width: $btn-height * .5;
				@include media-breakpoint-up(md) {
					border-width: $btn-height-large * .5;
				}
			}
			// span{
			// 	color: var(--color-white);
			// }
			// .icon{
			// 	fill: var(--color-white);
			// }
		}
		&.btn-lg::before{
			border-width: $btn-height * 0.5;
			@include media-breakpoint-up(md) {
				border-width: $btn-height-large * .5;
			}
		}
	}
}

/********************** HOVER FX **********************/
.btn--move-left,
.btn--move-right {
  transition: transform 0.18s cubic-bezier(0.02, 0.04, 0.05, 1);
}

.btn--move-left {
  margin-left: 0.5rem;
  @include hoverDeviceQuery {
    &:hover {
      transform: translateX(-0.5rem);

      &:disabled {
        transform: translateX(0);
      }
    }
  }
}

.btn--move-right {
  margin-right: 0.5rem;
  @include hoverDeviceQuery {
    &:hover {
      transform: translateX(0.5rem);

      &:disabled {
        transform: translateX(0);
      }
    }
  }
}

.btn--scale {
  transition: transform 0.18s cubic-bezier(0.26, 0.26, 0.01, 1);
  @include hoverDeviceQuery {
    &:hover {
      transform: scale(1.05);
    }
  }
}

// responsiv button
.btn--md-crop-text {
  @include media-breakpoint-down(md) {
    span:not(.icon) {
      display: none;
    }
    svg.icon {
      margin-left: 0 !important;
    }
    // force resize
    height: $btn-height-md !important;
    &::before {
      height: $btn-height-md !important;
      border-radius: $btn-height-md * 0.5 !important;
    }
    @include hoverDeviceQuery {
      &:hover {
        &::before {
          border-width: $btn-height-md * 0.5 !important;
        }
      }
    }
    &.btn-lg {
      padding: 0 18px;
    }
  }
}

/********************** MORE BUTTONS **********************/
// button social link
.btn-social-link {
  padding: 0;
  height: 43px;
  width: 43px;
  max-width: 43px;
  border-radius: 50% !important;
  border: 1px solid var(--color-body);
  background: none;
  @include media-breakpoint-up(md) {
    margin-right: 12px;
  }

  .icon {
    margin: 0;
    height: 50%;
  }

  &::before {
    content: none;
  }
}

//@include outline
.icon-outline {
  border: 2px solid var(--color-topic);
  border-radius: 12px;
  height: 24px;
  padding: 1px 7px;
  margin-left: 10px;
  margin-right: -6px;

  svg {
    width: 20px;
    height: 20px;
  }
}

/********************** MORE BUTTONS **********************/
// spezial buttons
// circle
// circle
.btn-circle-plain,
.btn-circle {

  border-color: var(--color-topic);
  background-color: var(--color-topic);

  padding: 0;
  height: $btn-radius;
  width: $btn-radius;
  max-width: $btn-radius;
  white-space: initial;
  border-radius: 50% !important;

  transform: scale(1); // safari hover fix

  &::before {
    transform: scale(1);
    border-radius: 50% !important;
    border-color: var(--color-topic);
    background-color: var(--color-white); // TODO: check if all grey bg?
    transition: transform 0.25s cubic-bezier(0.02, 0.04, 0.05, 1.02);
  }

  &.btn-circle--grey::before {
    background-color: var(--color-grey); // TODO: check if all grey bg?
  }

  span {
    white-space: initial;
    text-align: center;
    width: 90%;
    font-weight: var(--body-medium);;
    font-size: var(--body-sm);
    line-height: var(--body-mid-low);
    @include media-breakpoint-down(md) {
      font-size: var(--body-xs);;
    }
  }

  &.btn-number {
    width: 50px;
    height: 50px;
    max-width: 50px;
    margin-right: 30px;

    span {
      font-size: var(--body-md);
      font-weight: var(--body-medium);
    }

    @include media-breakpoint-down(md) {
      width: 37px;
      height: 37px;
      max-width: 37px;
      margin-right: 20px;
    }
  }

  &.btn-lg {
    height: $btn-radius-large;
    width: $btn-radius-large;
    max-width: $btn-radius-large;
    padding: 12px;

    @include media-breakpoint-down(md) {
      height: $btn-radius-medium;
      width: $btn-radius-medium;
      max-width: $btn-radius-medium;

    }
  }
}

@include hoverDeviceQuery {
  .btn-parent:focus,
  .btn-parent:hover,
  .btn-circle:hover {
    .btn-circle:not(.btn-plain)::before,
    &:not(.btn-plain)::before {
      transform: scale(0);
    }
  }
}

.btn-circle-plain {
  border-color: var(--color-white);
  background-color: var(--color-white);
}

// button scroll down header
.btn-row--scroll .btn--scroll {
  --color-topic: var(--color-white);
  width: 70px;
  height: 70px;
  max-width: 70px;
  margin: 10px;

  .icon {
    width: 36px;
    height: 36px;
  }

  &::before {
    background-color: var(--color-white);
  }

  @include hoverDeviceQuery {
    &:hover {
      .icon {
        fill: var(--color-body);
      }
    }
  }
}

.btn--home {
  span {
    color: var(--color-white);
  }

  svg {
    fill: var(--color-white);
  }
}

// header navigation
.btn--mainnav {
  font-size: var(--body-xs);
  border-color: var(--color-grey);
  background-color: var(--color-grey);
  height: 34px;
  border-radius: 17px;
  padding: 0 18px;
  margin-left: 20px;
}

.btn--mainnav-admin {
  padding: 0 10px;
  margin: 0;
}

// tags
.btn-tag {
  height: 24px;
  border-radius: 12px;
  font-size: 12px;
  margin: 0 12px 12px 0;

  span {
    padding-top: 1px;
  }
}

// header collapse readmore button
.btn--header-readmor {
  height: $btn-height-small;

  background: none;
  border: none;
  background: none;
  font-weight: var(--body-medium);
  font-size: var(--body-xs);
}

// ignore mouse
.btn--no-pointer {
  pointer-events: none;
  --color-topic: var(--color-body);
}

// colored buttons
.btn-body {
  --color-topic: var(--color-body);
  @include hoverDeviceQuery {
    &:hover {
      span {
        color: var(--color-white);
      }

      svg {
        fill: var(--color-white);
      }
    }
  }
}

// submit
[type=submit].btn-text {
  position: relative;
  line-height: var(--body-low);
  font-size: var(--body-sm);
  font-weight: var(--body-medium);
  z-index: 1;
  background: none;
  border: none;
  color: var(--color-body);
  padding: 16px 36px;
  margin: 0 -36px;
  @include hoverDeviceQuery {
    :hover {
      color: var(--color-white);
    }
  }
  @include media-breakpoint-down(md) {
    padding: 12px 36px;
  }
}

[type=submit].btn-primary {
  color: var(--color-body);
  z-index: 1;
  background: none;

  &::before {
    z-index: -1;
  }

  @include hoverDeviceQuery {
    &:hover {
      color: var(--color-white);
    }
  }
}

@include hoverDeviceQuery {
  .btn:hover [type=submit].btn-text {
    color: var(--color-white);
  }
}

.icon--default {
  fill: var(--color-default) !important;
}

.icon--body {
  fill: var(--color-body) !important;
}

.icon-outline--body {
  border-color: var(--color-body) !important;
}

.icon-outline--default {
  border-color: var(--color-default) !important;
}

// RTE
.btn--rte {
  margin-top: $space-before-after-small-xs;
  margin-bottom: $space-before-after-small-xs;

  @include media-breakpoint-up(md) {
    margin-top: $space-before-after-small-lg;
    margin-bottom: $space-before-after-small-lg;
  }
}

.btn--rte a {
  color: var(--color-body);
  z-index: 1;

  &:hover {
    color: var(--color-white);
  }

  &::after {
    content: "";
    width: 24px;
    height: 24px;
    display: inline-block;
    margin: 0 0 0 10px;
    overflow: hidden;
    background-color: var(--color-body);
  }

  &:hover {
    &::after {
      background-color: var(--color-white);
    }
  }

  &::before {
    z-index: -1;
  }
}

.btn--rte a,
.btn--rte a.link-external {
  &::after {
    clip-path: url(#icon-redirect-clip-path);
  }
}

.btn--rte a.link-internal {
  &::after {
    clip-path: url(#icon-arrow-right-clip-path);
  }
}

.btn--rte a.link-warning-download,
.btn--rte a.link-download {
  &::after {
    clip-path: url(#icon-download-clip-path);
  }
}

.btn--rte a.link-mail {
  &::after {
    clip-path: url(#icon-mail-clip-path);
  }
}

[style*="--color-cheese"],
[style*="--color-story"],
[style*="--color-inspiration"],
[style*="--color-white"],
[style*="--color-cheese"] {
  .btn--rte a {
    &:hover {
      color: var(--color-body);
    }

    &:hover {
      &::after {
        background-color: var(--color-body);
      }
    }
  }
}
