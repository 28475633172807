footer {

	background-color: none;

	margin-top: 150px;
	margin-left: auto;
	margin-right: auto;

	@include media-breakpoint-up(md) {
		margin-top: 195px;
	}

	h1, h2, h3, h4 ,h5{
		font-size: var(--body-xl);
		font-weight: var(--body-regular);
	}
	a{
		color: var(--color-body);
	}
	// 
	.imagetext-textblock{
		.textblock__text{
			margin: 0;
			width: 100%;
			p{
				font-size: var(--body-size);
				font-weight: var(--body-weight);
				line-height: var(--body-height);
			}
		}
	}
}

.container--footercontact{
	overflow: hidden;
	padding-bottom: 40px;
	@include media-breakpoint-up(md){
		padding-bottom: 80px;
	}
}
.col--footerimage{
	position: relative;
	display: flex;
	&[data-footer-newsletter-subscribe="success"]{
		.footer-email-registration{
			display: none;
		}
		.footer-image.d-none{
			display: block !important;
		}
	}
}
.footer-image{
	position: absolute;
	top: 0;
	right: 0;
	width: 150%;
	padding: 150% 0 0;
	clip-path: circle(49% at 50% 50%);
	img{
		object-fit: cover;
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}
	@include media-breakpoint-down(md){
		display: none;
	}
	@include media-breakpoint-down(xl){
		top: 20%;
		width: 200%;
		padding: 200% 0 0;
	}
	@include media-breakpoint-down(lg){
		top: 40%;
	}
}
.footer-email-registration{
	align-self: center;
	margin-right: 80px;
	@include media-breakpoint-down(md){
		padding-top: 80px;
		padding-bottom: 80px;
	}
	&:before{
		content: "";
		background: var(--color-white);
		position: absolute;
		top: 0;
		left: calc( var(--container-padding-x) * -1);
		width: calc(100% + var(--container-padding-x) );
		height: 100%;
		z-index: -1;
	}
}

.container--contact {

}
.container--footermenu {
	@include media-breakpoint-up(md){
		margin-top: -11px;
	}
}
.container--footerbrand {
	z-index: 1;
    position: relative;
	background-color: var(--color-primary);
	border-top: var(--color-white) solid 3px;
	width: 100%;
	height: 58px;

	@include media-breakpoint-up(md){
		height: 85px;
		border-top-width: 6px;
	}
	.row{
		height: 100%;
	}
}

.col--footertext{
	margin-top: 90px;
	margin-bottom: 90px;
	@include media-breakpoint-up(md){
		margin-top: 90px;
		margin-bottom: 45px;
	}
}
.footerbrandleft__logo{
	margin-top: -40px;
	
	@include media-breakpoint-down(md){
		margin-top: -23px;
		svg{
			width: 38px;
			height: 38px;
		}
	}
}
.footerbrandright{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.footerbrandleft__slogan{
	display: inline-block;
	margin-top: 8px;
	color: var(--color-white);

	font-size: 6px;
	font-weight: var(--body-medium);
	line-height: var(--body-low);
	white-space: nowrap;
	@include media-breakpoint-up(sm){
		font-size: var(--body-xxs);
	}
	@include media-breakpoint-up(md){
		font-size: var(--body-xs);
	}
}
.footerbrandright__slogan{
	margin-top: 10px;
	max-width: 80%;
	float: right;
	svg{
		max-width: 100%;
	}
	@include media-breakpoint-up(md){
		margin-top: 15px;
	}
}
.footerbrandright__separator{
	height: 100%;
	display: inline-block;
	border-left: 2px solid var(--color-white);
	@include media-breakpoint-up(md){
		border-left-width: 6px;
	}
}
.social-link{
	color: var(--color-body);
	line-height: 2;
}