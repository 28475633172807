
// Deatil page
// .cheesedetail{
// 	margin-top: #{$spacer * 4};
// 	@include media-breakpoint-up(md) {
// 		margin-top: #{$spacer * 8};
// 	}
// }
.description{
	display: flex;
	align-items: flex-start;

	margin-top: 15px;
	margin-bottom: 15px;

	@include media-breakpoint-up(md) {
		margin-top: 30px;
		margin-bottom: 30px;
	}
}
.description__icon{
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;

	width: 32px;
	height: 32px;
	margin-right: 25px;

	@include media-breakpoint-up(md) {
		width: 62px;
		height: 62px;
	}
	border-radius: 50%;
	border: 2px solid var(--color-cheese);
	

	.icon{
		width: 62.08%;
		height: 62.08%;
	}
}
.description__key,
.description__value{
	display: block;
    p{
        margin: 0;
    }
}
.description__key{
	font-size: var(--body-xs);
	font-weight: var(--body-medium);
}
.description__value{
	font-size: var(--body-lg);
}
