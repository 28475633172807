// Override if you use it in your theme

$font-size-base: 14px;
$font-size-sm: 14px;
$font-size-md: 18px;
$font-size-lg: 18px;

$small-font-size: 12px;

// $h1-font-size: 7.125rem;
// $h2-font-size: 5.4375rem;
// $h3-font-size: 4.125rem;
// $h4-font-size: 3.125rem;

// $h1-font-size-md: 4.125rem;
// $h2-font-size-md: 4.125rem;
// $h3-font-size-md: 4.125rem;
// $h4-font-size-md: 4.125rem;

$h1-font-size: 114px;
$h2-font-size: 87px;
$h3-font-size: 66px;
$h4-font-size: 50px;

$h1-font-size-md: 44px;
$h2-font-size-md: 44px;
$h3-font-size-md: 44px;
$h4-font-size-md: 44px;



$xxl-font-size: 105px; 
$xl-font-size: 80px;  
$lg-font-size: 66px; 
$md-font-size: 50px;

$xxl-font-size-min: 87px;
$xl-font-size-min: 66px;
$lg-font-size-min: 50px;
$md-font-size-min: 44px;



// fluid headline sizes
$headline-min-vw: 768px;
$headline-max-vw: 1512px;

$h5-font-size: 2.375rem; // TODO: body font family
$h6-font-size: 1rem; // TODO: body font family

$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.2;
$headings-color: var(--color-body);
$headings-font-family: var(--bs-headings-font-family);
$headings-font-weight: 500;

$font-weight-bold: var(--body-medium);


/*
//
// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
$font-family-sans-serif:      "Inconsolata";
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
// stylelint-enable value-keyword-case
$font-family-base:            var(--#{$variable-prefix}font-sans-serif) !default;
$font-family-code:            var(--#{$variable-prefix}font-monospace) !default;

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root:              null !default;
$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm:                $font-size-base * .875 !default;
$font-size-lg:                $font-size-base * 1.25 !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;

$font-weight-base:            $font-weight-normal !default;

$line-height-base:            1.5 !default;
$line-height-sm:              1.25 !default;
$line-height-lg:              2 !default;

$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.75 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base !default;
// scss-docs-end font-variables

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size
) !default;
// scss-docs-end font-sizes

// scss-docs-start headings-variables
$headings-margin-bottom:      $spacer * .5 !default;
$headings-font-family:        null !default;
$headings-font-style:         null !default;
$headings-font-weight:        500 !default;
$headings-line-height:        1.2 !default;
$headings-color:              null !default;
// scss-docs-end headings-variables

// scss-docs-start display-headings
$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem
) !default;

$display-font-weight: 300 !default;
$display-line-height: $headings-line-height !default;
// scss-docs-end display-headings

// scss-docs-start type-variables
$lead-font-size:              $font-size-base * 1.25 !default;
$lead-font-weight:            300 !default;

$small-font-size:             .875em !default;

$sub-sup-font-size:           .75em !default;

$text-muted:                  $gray-600 !default;

$initialism-font-size:        $small-font-size !default;

$blockquote-margin-y:         $spacer !default;
$blockquote-font-size:        $font-size-base * 1.25 !default;
$blockquote-footer-color:     $gray-600 !default;
$blockquote-footer-font-size: $small-font-size !default;

$hr-margin-y:                 $spacer !default;
$hr-color:                    inherit !default;
$hr-height:                   $border-width !default;
$hr-opacity:                  .25 !default;

$legend-margin-bottom:        .5rem !default;
$legend-font-size:            1.5rem !default;
$legend-font-weight:          null !default;

$mark-padding:                .2em !default;

$dt-font-weight:              $font-weight-bold !default;

$nested-kbd-font-weight:      $font-weight-bold !default;

$list-inline-padding:         .5rem !default;

$mark-bg:                     #fcf8e3 !default;
// scss-docs-end type-variables

 */