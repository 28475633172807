h2.recipetitle{
    font-weight: var(--body-regular);
    max-width: 600px;
}
p.recipeyield{
    margin: 60px 0 45px;
}
.recipe__preparation,
.recipe__ingredient{
    margin-top: 45px;
    @include media-breakpoint-up(md){
        margin-top: #{$spacer * 10};
    }
}
.recipe__preparation,
.recipe__ingredient-block{
    font-size: var(--body-md);
    position: relative;
    padding-top: 45px;
    padding-bottom: 45px;
    @include media-breakpoint-up(md){
        padding-top: 80px;
        padding-bottom: 80px;
    }
    p{
        max-width: 600px;
    }
}

.recipe__ingredient-block{
    margin-bottom: 5px;
    padding-right: calc(var(--bs-gutter-x) * .5);
    &:last-child{
        margin-bottom: 0;
    }
    &::before{
        content: "";
        position: absolute;
        z-index: -1;

        top: 0;
        left: calc( (-20%) - var(--container-padding-x));
        width: calc( 120% + var(--container-padding-x));
        height: 100%;
        background: var(--color-white);
    }
}

p + ul.recipe__ingredientlist,
ul.recipe__ingredientlist{
    line-height: 2.5;
    margin: 0;
    padding: 0;
}
ul li.recipe__ingredientitem{
    padding: 0;
    &::before{
        content: none;
    }
}

.recipe-subtitle {
    font-size: var(--body-font);
    font-weight: var(--body-medium);
    margin-top: 60px;
}

.recipe-subtitle + .recipe__Instructionslist .recipeInstructions__item:first-child {
    margin-top: 30px;
}
.recipeInstructions__item{
    margin-top: 40px;

    &::before {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 48px;
        height: 48px;
        margin-bottom: 15px;

        border: 2px solid var(--color-body);
        border-radius: 50%;
        
        font-size: var(--body-lg);
        font-weight: var(--body-medium);
    }
    &::marker{
        font-size: 0;
        opacity: 0;
    }
    @for $i from 1 through 16 {
        &:nth-child(#{$i})::before {
            content: "#{$i}";
        }
    }
      
}
ol.recipe__Instructionslist{
    padding: 0;
    max-width: 600px;
}

// recipe additional
.recipe-detail__additional {
    margin-top: 90px;
}

.recommendation__title {
    font-size: var(--body-size);
    font-weight: var(--body-medium);
}

.recipe-detail__additional-icon {
    display: flex;
    justify-content: center;
    width: 48px;
    height: 48px;
    margin-bottom: 15px;

    border: 2px solid var(--color-topic);
    border-radius: 50%;

    svg {
        fill: var(--color-topic);
    }
}

.recipeend{
    display: flex;
    align-items: flex-end;
    position: relative;
    margin: 0 calc(var(--container-padding-x) * -1) 90px;
    height: calc(80vw - 7.5px);
    @include media-breakpoint-up(md){
        height: calc(50vw - 7.5px);
        margin-bottom: 150px;
    }
}
.recipeend__image{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    img{
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    :after{
        content: "";
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: linear-gradient(10deg, rgba(0,0,0,.3), rgba(0,0,0,.2) 20%, rgba(0,0,0,0) 45%);
    }
}
.recipeend__text{
    font-family: var(--bs-headings-font-family);
    color: var(--color-white);
    display: block;
    margin-bottom: 45px;
    @include media-breakpoint-up(md){
        margin-bottom: 127px;
    }
}
.recipeslead_wrap{
    @include make-col(5, 6);
    @include make-col-offset(1, 6);
    margin-top: 60px;
    @include media-breakpoint-up(md){
        @include make-col(4, 7);
        @include make-col-offset(1, 7);
        margin-top: 90px;
    }
}
.recipes__metalist{
    display: flex;
    flex-direction: row;
    margin-top: 45px;
    @include media-breakpoint-down(md){
        justify-content: space-around;
    }
}
.recipes__metaitem{
    display: inline-flex;
    flex-direction: column;
    @include make-col(1, 5);
    @include media-breakpoint-down(md){
        width: 57px;
        padding: 0;
    }
}
.recipes__metaicon{
    width: 62px;
    height: 62px;
    @include media-breakpoint-down(md){
        width: 57px;
        height: 57px;
    }
    border: 2px solid var(--color-topic);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-bottom: 15px;
    svg{
        width: 75%;    
    }
}
.reciperesult{
    display: flex;
    align-items: center;
    font-size: var(--body-xxs);
    font-weight: var(--body-medium);
}

// category list
.recipe__categorylist{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0;
    margin: 20px 0;
}
.recipe__categoryitem{
    margin-right: 28px;
    font-size: var(--body-xs);
    font-weight: var(--body-medium);
    color: var(--color-body);
    white-space: nowrap;
    svg{
        height: 24px;
        margin-right: 8px;
    }
}

// video
.recipe__playicon{
    position: absolute;
    top: 85%;
    left: 85%;
    width: 70px;
    height: 70px;
    background: var(--color-topic);
    z-index: 1;
    border-radius: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    justify-content: center;
    align-items: center;
    .icon{
        width: 60%;
        height: 60%;
    }

    @include media-breakpoint-down(md){
        width: 45px;
        height: 45px;
    }
}