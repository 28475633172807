b,
strong {
	font-weight: $font-weight-bold;
}

p {
	margin: 0 0 $paragraph-margin-bottom;
}

a {
	transition: all .3s ease;
}

iframe {
	max-width: 100%;
}

img {
	max-width: 100%;
	height: auto;

	// need for multicolumn images
	min-width: 100%;
}
ul {
	overflow: visible;
	padding: 0;
	margin: 0;
	li {
		list-style: none;
	}
}
figure {
	margin: 0;
}
figcaption{
	margin-top: 0.5rem;
	font-size: var(--body-xs);
	line-height: var(--body-mid);
}

// mouse selection
::selection {
	background: $mouse-selection-background;
	color: $mouse-selection-color;
}
