.infographic_progresbar {
  display: flex;
  position: sticky;
  top: 90px + 20px; // TODO header height set variable
  left: 40px;
  width: 100%;
  height: 40px;
  z-index: 100;
  transition: top 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  border-radius: 5px;
  overflow: hidden;
  @include media-breakpoint-up(md) {
    height: 50px;
  }
}

.infographic_progresbar__item {
  display: inline-block;
  width: calc(var(--width) * 100%);
  background-color: var(--color-infographic-yellow);
  transition: all 1s cubic-bezier(0.16, 1, 0.3, 1);
  border-right: var(--infographic-progressbar-min-width) solid var(--color-white);
  cursor: pointer;

  &:last-child {
    border-right: 0;

    &:hover {
      background-color: var(--color-infographic-yellow-hover);
      border-left-color: var(--color-infographic-yellow-hover);
    }
  }

  &:not(:last-child) {
    &[data-progress-style='initial'] {
      background-color: var(--color-infographic-yellow);
      border-left-color: var(--color-infographic-yellow);
    }
    &[data-progress-style='active'] {
      background-color: var(--color-infographic-red);
      border-left-color: var(--color-infographic-red);
    }
    &[data-progress-style='past'] {
      background-color: var(--color-infographic-red-past);
      border-left-color: var(--color-infographic-red-past);
    }
    &:hover {
      background-color: var(--color-infographic-red-hover);
      border-left-color: var(--color-infographic-red-hover);
    }
  }
}
