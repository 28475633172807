.modal-infographic{
  padding-right: 0 !important;
}
.infographic-infobox-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  display: block;
  z-index: 9;
  overflow: hidden;

  &.show {
    height: var(--text-modal-height);
  }
}

.infobox-text-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--color-white);
  padding: var(--bs-gutter-x);
  transform: translateY(var(--text-modal-height));
  transition: transform 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.show .infobox-text-content {
  transform: translateY(0);
}

.infobox-text-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  flex-wrap: nowrap;
}

.modal-dialog-infographic {
  max-width: calc(100% - var(--bs-gutter-x) * 2);
  transform: translate(0, -50px);
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.modal-content-infographic {
  background-color: var(--color-white);
  padding: var(--bs-gutter-x);
}

.modal-header-infographic {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  flex-wrap: nowrap;
  padding: 0;
  margin-bottom: var(--bs-gutter-x);
}

.modal-content-infographic {
  border: none;
}

.modal-content-title {
  font-size: var(--title-md);
  padding: 50px 0 0 0;
}

@include media-breakpoint-down(md) {
  .modal-header-infographic {
    font-size: var(--body-xl);
  }
}
