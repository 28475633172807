.submit--popup{
	display: none;
}
.minimal-newsletter-registration{
	.email-wrap{
		position: relative;
		input{
			padding-right: 70px; //button space
		}
	}
	.form-control{
		background-color: var(--white);
	}
	.form-group.Email{
		.control-label{
			display: none;
		}
	}
	.frame-type-form_formframework form{
		padding: 0 calc(var(--bs-gutter-x) * 0.5);
		width: 100%;
	}
	.form-group:not(.form-group--required){
		display: none;
	}
	.submit--popup{
		display: block !important;
		position: absolute;
		right: 0;
		bottom: 0;
	}
	.control-label-select-themes{
		font-size: var(--body-xs);
    	font-weight: var(--body-medium);
	}
	.form-check-select-themes{
		width: 50%;
		@include media-breakpoint-between(md,lg){
			width: 100%;
		}
	}
}
.wrapper-select-themes{
	display: flex;
	margin-bottom: 20px;
	flex-wrap: wrap;
}
.form-check-select-themes{
	width: 50%;
}