body.popup-open{
    // prevent background scrolling on mobile
    @include media-breakpoint-down(md){
        position: fixed;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
        &:not(.menu-open){
            #popup{
                z-index: 200;
            }
        }
    }
}
#popup{
    position: fixed;
    bottom: 0;

    z-index: 10;
    
    width: 100%;
    max-height: calc(100vh - 110px);
    overflow-y: auto;
    overflow-x: hidden;

    padding-right: var(--container-padding-x, 60px);
    padding-left: var(--container-padding-x, 60px);
    padding-bottom: 20px;
    margin-right: auto;
    margin-left: auto;

    transform: translateY(100%);
    transition: transform 0.18s cubic-bezier(0.165, 0.84, 0.44, 1);

    @include media-breakpoint-up(md){
        width: calc(100% - var(--container-padding-x));
        max-width: 860px;
        background: var(--color-white);
        padding-right: 0;
        padding-bottom: 0;
    }

    &.open{
        transform: translateY(0);
    }
    .form-group--required{
        margin-bottom: 1px;
    }
    .frame-type-form_formframework:last-child{
        margin-bottom: -30px;
    }
    .frame-type-form_formframework form:before{
        content: none;
    }
    .form-control{
        max-width: initial;
    }

}
.popup__column{
    
    &.--text{
        @include make-col(12,12);
        @include media-breakpoint-up(md){
            @include make-col(5,9);
        }
    }
    &.--image{
        display: none;
        @include media-breakpoint-up(md){
            display: block;
            @include make-col(4,9);
        }
    }
}
.popup__image{
    position: relative;
    width: 100%;
    height: 100%;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.popup__closebutton{
    position: absolute;
    top: 24px;
    right: 32px;
    width: 32px;
    height: 32px;

    z-index: 1;
    padding: 0;

    background: var(--color-white);
    cursor: pointer;

    @include media-breakpoint-down(md){
        top: 10px;
    }
}
.popup__text{
    background: var(--color-white);
    padding: #{$spacer*3} 20px;
    @include media-breakpoint-up(md){
        padding: #{$spacer*4} 20px;
    }
}