.cheesecards-splide{

    @include media-breakpoint-down(md){
        margin-left: 0;
        .splide__track{
            overflow: visible;
        }
    }

    @include media-breakpoint-up(md){
        @include make-col(12,12);
        @include make-col-offset(1,12);

        &.splide-last{
            .splide__track{
                margin-left: -8.33333333%;
            }
        }
    }
    .btn-row{
        @include make-col(11, 12);
    }
}

.splide__track--card{
    @include media-breakpoint-up(md){
        overflow: visible;
        margin-right: calc(var(--container-padding-x) * -1);
        margin-left: 0;
        width: calc(100% + var(--bs-gutter-x));
    }
}
.splide__slide--card{
    padding-left: calc(var(--bs-gutter-x) * .5);
    padding-right: calc(var(--bs-gutter-x) * .5);
}
.card--cheese-carousel{
    margin-top: 0;
    max-width: 100%;
    width: 100%;
}
// min two row height
.card__text--carousel{
    @include media-breakpoint-up(md){
        min-height: 44px;
    }
}
