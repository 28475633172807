.breadcrumb {
  padding: 2rem math.div($grid-gutter-width, 2) 3rem;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    overflow: initial;

    li {
      padding: 0;
      list-style: none;
      display: inline-block;

      &::before {
        content: '';
      }
    }
  }
}

.breadcrumb-item {
  font-size: $breadcrumb-font-size;

  a {
    color: $breadcrumb-color;
    text-decoration: none;

    &:hover,
    &:focus {
      color: $breadcrumb-color-hover;
    }
  }
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 36px;
  position: relative;

  &::before {
    padding: 0;

    @include icon--default-settings;
    @include icon--chevron-right-grey60;
    width: 8px;
    left: 12px;
    top: 1px;
  }
}
