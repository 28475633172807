.langSwitch {
	padding: 13px 0 14px;

	ul {
		margin: 0;
		padding: 0;
	}

	.no,
	.act {
		list-style: none;
		display: inline-block;

		a {
			font-size: $langswitch-font-size;
			color: $langswitch-color;
			padding: $langswitch-padding;

			&:hover,
			&:focus {
				color: $langswitch-color-hover-active;
			}
		}
	}

	.act {
		a {
			color: $langswitch-color-hover-active;
		}
	}
}
