// list and single view
.news {
	.footer {
		color: var(--color-grey-60);
		font-size: .82rem;
	}
}

// list-view
.news-list-view {
	.article {
		margin-bottom: 2rem;
		padding-bottom: 1.5rem;
		border-bottom: $content-divider;
	}

	.news-img-wrap {
		@include media-breakpoint-down(sm) {
			margin-bottom: math.div($grid-gutter-width, 2);;
		}
	}

	h3 {
		margin-top: .4rem;
		margin-bottom: 1.6rem;
	}

	.footer {
		p {
			margin-bottom: 0;
		}
	}
}

// news-single-view
.news-single {
	.teaser-text {
		font-weight: bold;
		font-size: 1.2rem;
	}

	.news-img-wrap {
		margin-bottom: math.div($grid-gutter-width, 2);;

		@include media-breakpoint-up(md) {
			margin-bottom: $grid-gutter-width;
		}
	}
}
