// TEST
.js-animate-trigger.in-view{
	.bg-grid{
		position: relative;
		border: 1px solid red;
		&:after{
			position: absolute;
			content: "in view";
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: red;
		}
	}
}