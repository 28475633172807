.btn-box{
    cursor: pointer;
}
.rate {
    float: left;
    padding: 0;
}
.rate > label {
    width: 20px;
    height: 20px;
    @include media-breakpoint-up(md){
        width: 25.3px;
        height: 25.3px;
    }
    svg{
        fill: transparent;
        stroke: var(--color-cheese);
    }
}
.rate:not(:checked) > input {
    position:absolute;
    top:-9999px;
}
.rate:not(:checked) > label {
    float:right;
    // width:1em;
    // overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    // font-size:30px;
    // color:#ccc;
}
.rate:not(:checked) > label:before {
    // content: '★ ';
    color: red;
    svg{
        fill: var(--color-cheese);
    }
}
.rate > input:checked ~ label {
    color: yellow;
    svg{
        fill: var(--color-cheese);
    }
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
    color: firebrick;
    svg{
        fill: var(--color-cheese);
    }
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
    color:cyan; 
    svg{
        fill: var(--color-cheese);
    }
}

// stats
#rateState.rate > label {
    float:left;
}
[data-total-rating="1"]{
    label:nth-child(-n+1) .icon{
        fill: var(--color-cheese);
    }
}
[data-total-rating="2"]{
    label:nth-child(-n+2) .icon{
        fill: var(--color-cheese);
    }
}
[data-total-rating="3"]{
    label:nth-child(-n+3) .icon{
        fill: var(--color-cheese);
    }
}
[data-total-rating="4"]{
    label:nth-child(-n+4) .icon{
        fill: var(--color-cheese);
    }
}
[data-total-rating="5"]{
    label:nth-child(-n+5) .icon{
        fill: var(--color-cheese);
    }
}

.pointer{
    white-space: nowrap;
}