// content


// footer
$footer-bg: var(--bs-body-bg);
$footer-color: var(--color-body);
$footer-font-size: .9rem;


// Divider
$content-divider-height: 1px;
$content-divider-color: $border-color;
$content-divider: $content-divider-height solid $content-divider-color;


// space-before and space-after
$space-before-after-small-xs: $spacer;
$space-before-after-large-xs: $spacer * 2;

$space-before-after-small-lg: $spacer * 1.5;
$space-before-after-large-lg: $spacer * 2.5;


// Mouse selection
$mouse-selection-background: var(--color-primary);
$mouse-selection-color: var(--color-white);


// Cookie Hint
$cookie-background: var(--color-primary);
$cookie-border: 1px solid var(--color-white);
$cookie-color: var(--color-white);
$cookie-font-size: 1rem;
$cookie-button-background: var(--color-white);
$cookie-button-color: var(--color-body);


// narrow gutter
$grid-gutter-narrow-width: 5px;
$grid-gutter-narrow-width-md: 8px;


// mobile
$mobile-header-height: 55px;