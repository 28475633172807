.splide__track--reciep{
    margin: 30px 0 0;
    @include media-breakpoint-down(xl){
        overflow: visible;
    }

    @include media-breakpoint-down(sm) {
        overflow: hidden;
    }

    @include media-breakpoint-up(md){
        left: 0;
        right: calc(var(--container-padding-x) * -1);
        &:not(.splide){
            width: 109.0909%;
            margin: 0 calc(var(--bs-gutter-x) * -.5);
        }
        &.splide::after{
            content: "";
            position: absolute;
            top: 0;
            left: 0;

            width: 160px;
            height: 100%;
            background: linear-gradient(90deg, var(--color-grey) 0%, rgba(245, 245, 247, 0) 100%);
        }
    }
    .card--cheese{
        max-width: 100%;
        width: auto;
    }
}
.splide__slide--reciep{
    padding-left: calc(var(--bs-gutter-x) * .5);
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-top: calc(var(--bs-gutter-x) * .5);
}
.content-cheesereference.splide--no-slider{
    .splide__list--cheeseReference{
        justify-content: center;
    }
    .splide__slide{
        width: 33.333333%;
        max-width: 325px !important;
    }
    .splide__track--reciep{
        width: 100%;
    }
}

//splide__track--inspiration
.splide__track--inspiration{
    margin-right: calc(var(--container-padding-x) * -1);
    margin-left: 0;
    width: calc(100% + var(--bs-gutter-x));
    .card--recipe{
        margin-top: 0;
    }

    @include media-breakpoint-up(sm) {
        overflow: visible;
    }
}
.inspiration-splide{
    .btn-row{
        margin-top: -60px;
        @include make-col(11, 12);
    }
    @include media-breakpoint-down(md){
        width: calc(100% + var(--container-padding-x) * .5);
    }
    @include media-breakpoint-up(md){
        &.splide-last{
            .splide__track{
                margin-left: -8.33333333%;
            }
        }
    }
}

.content-cheesereference{
    .splide__track-container{
        position: relative;
        @include media-breakpoint-up(xl){
            clip-path: inset(0 -100% 0 0);
            &:not(.splide-first)::before{
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                background: linear-gradient(90deg, var(--color-grey), transparent);
                width: 15%;
                height: 100%;
                z-index: 1;
            }
        }
    }
    .splide__track{
        left: 0;
    }
    .splide-last .splide__track{
        margin-left: -9.0909%;
    }

    .splide__slide .card{
        max-width: 325px;
    }
}

