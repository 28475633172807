.anchorlink__list{
    display: inline-block;
    padding: 28px 80px 28px 40px;
    margin: 0 0 #{$spacer * 4};
    background-color: var(--color-white);
    position: sticky;
    top: 0;
    z-index: 125;
}
.menu-open{
    .anchorlink__list{
        z-index: 99;
    }
}

.anchorlink__item{
    display: inline-block;
}
.anchorlink__link{
    position: relative;
    padding-left: 45px;
    color: var(--color-body);
    font-size: var(--body-xs);
    font-weight: var(--body-medium);

    &::before{
        content: "";
        position: absolute;
        top: 2px;
        left: 20px;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: var(--color-topic);
        transform: scale(0);
        transform-origin: center;
        transition: transform 0.18s cubic-bezier(0.02, 0.04, 0.05, 1);
    }

    &.in-view{
        color: var(--color-body);
        &::before{
            transform: scale(1);
        }
    }

    @include hoverDeviceQuery {
        &:hover{
            color: var(--color-body);
            &::before{
                transform: scale(1);
            }
        }
    }
    
}