@import "Switch";
@import "Checkbox";

// default settings
label,
.input-group__label, // käser admin
.control-label{     // form
    font-size: var(--body-md);
    width: 100%;
    max-width: 600px;
    margin-bottom: 5px;
}
// fix required offset
.required{
    padding: 0 !important;
}


input{
    font-size: var(--body-md);
}
.form-control {
    border: none;
    border-bottom: 2px solid var(--color-black);
    padding: $input-padding-top $input-padding-x $input-padding-bottom;
    background-color: var(--bs-body-bg);
    max-width: 600px;
}

//disabled
.form-control:disabled, .form-control[readonly]{
    background-image: none;
}
// multi col input



// select
select{
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='37' height='25' viewBox='0 0 37 25' fill='none'%3E%3Crect x='0.75' y='1.55859' width='35.5' height='22.5' rx='11.25' stroke='%232D2D2D' stroke-width='1.5'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.3271 9.98823C13.5875 9.72788 14.0096 9.72788 14.2699 9.98823L18.5 14.2184L22.7301 9.98823C22.9905 9.72788 23.4126 9.72788 23.673 9.98823C23.9333 10.2486 23.9333 10.6707 23.673 10.931L18.9714 15.6326C18.8464 15.7576 18.6768 15.8278 18.5 15.8278C18.3232 15.8278 18.1536 15.7576 18.0286 15.6326L13.3271 10.931C13.0667 10.6707 13.0667 10.2486 13.3271 9.98823Z' fill='%232D2D2D'/%3E%3C/svg%3E");    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 50%;
}


// spaces
.form-group{
    font-size: var(--body-md);
    margin-bottom: $spacer;
}
form{
    // first level group
    > .form-group{
        margin-bottom: $spacer * 2;
    }
    p{
        max-width: 600px;
    }
}
.form-group--large{
    margin-bottom: #{$spacer * 4};
}
.form-group--space{
    margin-bottom: #{$spacer * 2};
}

.form-check-label{
    position: relative;
}
.form-check input[type=checkbox]:required {
    width: 1px;
    height: 1px;
    display: inline;
    position: absolute;
    top: 1px;
    left: 1px;
}

// form extension
.tx-is-prizegame,
.frame-type-form_formframework{
    @include make-row;

    .form-group{
        // @include media-breakpoint-up(md){
        //     max-width: calc(50% - var(--bs-gutter-x) * .5);
        // }
    }
    .form-check{
        position: relative;
    }
    header{
        padding-left: calc(var(--bs-gutter-x) * .5);
        padding-right: calc(var(--bs-gutter-x) * .5);
        @include media-breakpoint-up(md){
            @include make-col(11, 12);
            @include make-col-offset(1, 12);
        }
    }

    form{
        position: relative;
        display: block;

        flex-shrink: 0;
        margin-top: var(--bs-gutter-y);
        padding: #{$spacer * 4} calc(var(--bs-gutter-x) * 0.5);

        @include make-col(11, 12);

        // use as container
        @include media-breakpoint-up(md){
            padding: #{$spacer * 4} var(--bs-gutter-x) #{$spacer * 4} calc(var(--bs-gutter-x) * 0.5);
            @include make-col(9, 12);
        }
        @include media-breakpoint-up(xl){
            @include make-col(6, 12);
        }

        &:before{
            content: "";
            background: var(--color-white);
            position: absolute;
            top: 0;
            left: calc( var(--container-padding-x) * -1);
            width: calc(100% + var(--container-padding-x) ); // 100 / 9 / 10
            height: 100%;
            z-index: -1;
        }

        // title and description insde a form
        h2{
            font-size: var(--body-xl);
            font-weight: var(--body-medium);
            font-family: var(--bs-font-sans-serif);
            margin: 45px 0 30px;
        }
        h2 ~ p{
            font-size: var(--body-sm);
            margin: -15px 0 30px;
            max-width: 600px;
        }

        .form-check-input{
            border: none;
            width: initial;
        }
        .form-check .form-check-input{
            float: initial;
        }
        .form-element-check{
            .form-check .form-check-input{
                margin-left: 0;
                float: initial;
                span{
                    padding: 0;
                }
            }
        }
        
        > .row{
            max-width: 616px; // 16px equalize negatve margin
        }
    }
}

// login form
.frame-type-felogin_login{
	@include make-row;
    header{
        width: 100%;
    }
    & > * {
        flex-shrink: 0;
        padding-right: calc(var(--bs-gutter-x) * .5);
        padding-left: calc(var(--bs-gutter-x) * .5);
        margin-top: var(--bs-gutter-y);
    }
    & > .typo3-messages{
        flex-shrink: 1;
        margin-bottom: #{$spacer * 3};
    }
    fieldset{
        margin-top: 75px;
    }
}

// käserAdmin form
.login-form-wrapper,
.admin-form-wrapper{
    position: relative;
    padding-top: 60px;
    padding-bottom: 60px;
    margin-bottom: 3px;

    &:before{
        content: "";
        background: var(--color-white);
        position: absolute;
        top: 0;
        left: calc( var(--container-padding-x) * -1);
        width: calc(#{math.div(100%, 9) * 10} + var(--container-padding-x) ); // 100 / 9 / 10
        height: 100%;
        z-index: -1;
    }
}
.login-form-wrapper{
    position: relative;
    padding-left: 30px;
    padding-right: 30px;
    &::before{
        left: 0;
        width: 100%;
    }
}
.edit-producer-images,
.producer-add-cheese-2{
    &::before{
        width: calc( 100% + var(--container-padding-x) - var(--bs-gutter-x) * .5 ); // 100 / 12 / 10
        @include media-breakpoint-up(md){
            width: calc(#{math.div(100%, 12) * 10} + var(--container-padding-x) - var(--bs-gutter-x) * .5 ); // 100 / 12 / 10
        }
        @include media-breakpoint-up(lg){
            width: calc(#{math.div(100%, 12) * 10} + var(--container-padding-x) - var(--bs-gutter-x) * .5 ); // 100 / 12 / 10
        }
    }
}

.input-group__feedback{
    font-size: var(--body-xs);
}
.producer-add-cheese{
    position: relative;
    padding: #{$spacer * 4} calc( #{$spacer * 2} + var(--bs-gutter-x) * .5 );

    &:before{
        content: "";
        background: var(--color-white);
        position: absolute;
        top: 0;
        left: calc( var(--bs-gutter-x) * .5);
        width: calc( 100% - var(--bs-gutter-x) * 1);
        height: 100%;
        z-index: -1;
    }
}
.producer-home__images{

}
.producer-cheese-item:not(:last-child){
    margin-bottom: 60px;
}
.producer-cheese-item__image-wrapper,
.producer-home__img-wrapper{
    position: relative;
    display: inline-block;
    width: 176px; 
    height: 176px;
    z-index: 3;
    border-radius: 50%;
    overflow: hidden;
    &:nth-child(2){
        transform: translateX(-90px);
        z-index: 2;
    }
    &:nth-child(3){
        transform: translateX(-180px);
        z-index: 1;
    }
}
.producer-cheese-item__image-wrapper{
    border: 1px solid var(--color-body);
}
.producer-cheese-item__image,
.producer-home__img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.producer-add-cheese__example-image{
    position: relative;
    div{
        position: relative;
        width: 100%;
        padding-bottom: 100%;
        border-radius: 50%;
        overflow: hidden;
        img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
.admin__info-box-title{
    margin-bottom: $spacer;
}
.icon__info-box{
    width: 24px;
    height: 24px;
    display: inline-block;
    margin: -4px 20px 0 0;
}

// TODO: create new file
.header--Admin + main{
    margin-top: 195px;
}
.admin-form-header{
    margin-bottom: 60px;
}
.admin-form-header__breadcrump{
    --color-active: var(--color-body);
    padding-right: 30px;
    font-size: var(--body-xs);
    a{
        color: var(--color-body);
    }
    &:not(:last-child)::after{
        content: "/";
        padding-left: 30px;
    }
}
.producer-image{
    display: block;
}
.producer-image__placeholder{
    position: relative;
    width: 100%;
    padding-top: 75%;
    background: var(--color-grey);
}
.icon-image-placeholder{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 25%;
    height: auto;
    transform: translate(-50%, -50%);
    fill: var(--color-body);
}

// login form
.resetPasswordLink,
.usernamePopupLink{
    margin-top: 5px;

    font-size: var(--body-xs);
    color: var(--color-body);
    font-weight: var(--body-medium);
}

// password
.password__wrapper{
    position: relative;
    max-width: 600px;
}
.btn-toggle-pw{
    position: absolute;
    right: 0;
    top: 0;

    display: flex;
    height: 100%;
    padding-right: 10px;
    
    .icon{
        margin: 0;
        width: 16px;
        height: 16px;;
    }
}

.col--form-footer{
    margin-top: $spacer * 4;
    @include media-breakpoint-down(md){
        margin-top: $spacer * 3;
    }
    &::before{
        content: "";
        display: block;
        position: relative;
        top: 0;
        margin-left: calc(var(--container-padding-x) * -1);

        width: 100vw;
        height: 3px;

        background-color: var(--color-grey);
    }
}

.error-message{
    color: var(--color-primary);
}

// is_prizegame
.tx-is-prizegame{
    .typo3-messages{
        display: block;
        width: 100%;
    }
    .alert-message{
        font-size: calc(23px + 27 * ((100vw - 768px) / 744));
        font-family: var(--bs-headings-font-family);
        margin: 45px 0;
    }

    .error-box {
      background-color: var(--color-primary);
      color: var(--color-white);
    }
}
