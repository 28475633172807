.finderfilter{
	position: fixed;
	bottom: 0;
	left: 0;
    z-index: 1052;

	width: 386px;
	max-width: 90%;
}
.menu-open .finderfilter{
    display: none;
}
.dropdown--finderfilter{
	margin: 0;
}
.dropdown-menu--cheesfinder{
	width: 100%;
	border-radius: 0 !important;
	padding: 0;
	margin: 0;

	background: none !important;
    border: none !important;

	max-height: calc(100vh - 82px - 90px);
	overflow-y: auto;
}

.finderfilter__count{
    justify-content: space-around;
    align-items: center;
    display: flex;

	width: calc(100% - 164px);
    height: 82px;
    background: var(--color-white);
    border: 2px solid var(--color-grey);
	border-right: 0;

	font-size: var(--body-sm);
	font-weight: var(--body-medium);
	@include media-breakpoint-down(md){
        height: 63px;
		width: calc(100% - 129px);
    }
}

.finder__container{
    transition: transform 0.5s ease-in-out;
	//margin-top: #{$spacer * -12};
    @include media-breakpoint-down(md){
        margin-top: 0;
    }
}

// custom scrollbar
.template-Recipefinder,
.template-Cheesefinder{
	.modal-dialog-scrollable .modal-body{
		scrollbar-color: var(--color-topic) var(--color-grey);
  		scrollbar-width: thin;
		&::-webkit-scrollbar {
			width: 2px;
		}
		&::-webkit-scrollbar-track {
			background: var(--color-grey);
		}
		&::-webkit-scrollbar-thumb {
			background: var(--color-topic);
		}
		&::-webkit-scrollbar-thumb:hover {
			background: var(--color-topic);
		}
	}
}


// loading icon
.icon-loading{
	animation: rotating 2s linear infinite;
}
.amount-of-finders{
	.icon-loading{
		display: none;
		width: 20px;
		height: 20px;
	}
}

.is-loading{
	.amount-of-finders{
		.amount-of-finders-number{
			display: none;
		}
		.icon-loading{
			display: inline-block;
		}
	}
	// loading next page
	&.page-link{
		opacity: 0.4;
		pointer-events: none;
		.icon-loading{
			animation: rotating 2s linear infinite;
		}
	}
}
@keyframes rotating {
	from {
	  transform: rotate(0deg);
	}
	to {
	  transform: rotate(360deg);
	}
}
// on load next page
.page-link .icon-loading{
	animation: none;
}

// overlay stat handling
[data-modal-mode="search"],
[data-modal-mode="facet"]{
    .finder-list{
        transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
        transform: translateX(386px);
    }
}
.show-search-open{
    display: none;
    margin: 0 !important;
}
.show-filter-open{
    display: none;
    margin: 0 !important;
}
[data-modal-mode="search"]{
	.hide-search-open{
        display: none;
    }
    .show-search-open{
        display: block;
    }
}
[data-modal-mode="facet"]{
	.hide-filter-open{
        display: none;
    }
    .show-filter-open{
        display: block;
    }
}

// accordion
.finderfilter__accordionitem{
	border-bottom: 2px solid var(--color-grey);
	&:last-child{
		border-bottom: none;
	}
}
.finderfilter__accordionheader{
	margin: 0;
}
.finderfilter__accordionbody{
	padding: 15px 60px 45px;
	background: var(--color-white);
}
.finderfilter__search{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;

	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 9999;
}
.finderfilter__button{
	position: relative;
	padding: 0 60px;
	width: 100%;
	height: 82px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: none;
	background: var(--color-white);
	@include media-breakpoint-down(md){
		height: 63px;
	}
	.icon{
		height: 20px;
		width: 20px;
	}
	.icon:nth-child(2){
		display: none;
	}
	.icon:nth-child(3){
		display: block;
	}
	&.collapsed{
		.icon:nth-child(2){
			display: block;
		}
		.icon:nth-child(3){
			display: none;
		}
	}

}
.hasSelected{
    .finderfilter__button::before{
        content: "\2022";
        position: absolute;
        left: 30px;
        top: 42px;
        font-size: 40px;
        line-height: 0;
        color: var(--color-topic);
	}
}
.btn-block.hasSelected::before{
    content: "\2022";
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 40px;
    line-height: 0;
    color: var(--color-topic);
}


// main reset button
#finderReset{
	margin-right: 100%;
	transform: translateX(-100%);
	display: none;
}
#finderReset.hasSelected{
	transform: translateX(0);
	display: block;
	&::before {
		content: none;
	}
}
[data-modal-mode="search"],
[data-modal-mode="facet"]{
	#finderReset.hasSelected{
		transform: translateX(-100%);
		display: none;
	}
}
.solr-pagination-list{
	margin-top: -90px;
}



/********* input fields ********/
body.modal-open {
    // overflow: hidden;
    // position:fixed;
    // width: 100%;
    // height: 100%;
}
.is-loading{
	.inputgroup--check{
		pointer-events: none;
		opacity: 0.4;
	}
	.card--cheese{
		opacity: 0.4;
	}
}

// show hide filter
#finderfilterModal{
	position:fixed;
	top: 0;
	left: -100%;
	width: 100%;
	height: 100%;
	overscroll-behavior: contain;
}
[data-modal-mode="facet"],
[data-modal-mode="search"]{
	#finderfilterModal{
		left: 0;
	}
}




.finderfilterModal__content{
    border: none;
	border-radius: 0;
}
.finderfilterModal__dialog{
    position: absolute;
    left: 0;
    bottom: 82px;
    margin: 0;
    width: 386px;
	max-width: 90%;
    height: calc(100vh - 82px);
    overflow-y: auto;
    display: flex;
    align-items: flex-end;
	transform: translateX(-100%);
	transition: transform .18s cubic-bezier(0.165, 0.84, 0.44, 1);
	z-index: 0;
	@include media-breakpoint-down(md){
		bottom: 63px;
		width: 335px;
	}
}
[data-modal-mode="search"] .finderfilterModal__dialog--search{
	transform: translateX(0);
	z-index: 1;
}
[data-modal-mode="facet"] .finderfilterModal__dialog--filter{
	transform: translateX(0);
	z-index: 1;
}
.finderfilterModal__body{
    padding: 0;
	max-height: 60vh;
}
.findersearchModal__body{
	background: var(--color-white);

}
.finderfilterModal__header{
    padding: 0;
}
.finderfilterModal__footer{
    padding: 0;
}

/********* input fields ********/
.inputgroup--check{
	position: relative;
}

.inputgroup__label--check{
	position: relative;
	padding-left: 35px;
	margin: 9px 0;
	cursor: pointer;
	font-size: var(--body-xs);
	font-weight: var(--body-medium);
	line-height: var(--body-low);
	&::after,
	&::before{
		display: inline-block;
		content:  "";
		width: 12px;
		height: 12px;

		position: absolute;

		border-radius: 3px;
	}
	&::before{
		content:  "";
		width: 12px;
		height: 12px;

		top: 0;
		left: 0;

		border: 2px solid var(--color-body);
		border-radius: 3px;
	}
	&::after{
		display: none;
		content:  "";
		width: 6px;
		height: 6px;

		top: 3px;
    	left: 3px;

		border-radius: 1px;
		background: var(--color-body);
	}
}
.inputgroup__input--check{
	width: 0;
	height: 0;
	opacity: 0;
	display: block;
}
.inputgroup__label--check:hover::after{
	display: block;
	background: var(--color-topic);
}
.inputgroup__input--check:checked + .inputgroup__label--check::after{
	display: block;
}
// text input
.filter__search-text-input{
	border: none;
	width: 100%;
	padding: 12px 5px;
	margin: 0 -5px;
	&:focus{
		outline: 0;
	}
}