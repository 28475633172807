.dpnglossary{
    position: relative;
    min-height: 50vh;
}

// glossar menu
.glossary-pagination{
    
    top: 0;
    left: 0;
    margin-left: calc(var(--container-padding-x) * -1);
    width: 261px;
    z-index: 110;

    overflow-x: auto;
    overflow-y: hidden;

    padding: 30px 25px 30px var(--container-padding-x);
    background: var(--color-white);
    @include media-breakpoint-up(lg){
        position: absolute;
        width: 280px;
    }
    @include media-breakpoint-up(xl){
        width: 376px;
    }

    &.sticky{
        position: fixed;
        margin-left: calc(var(--bs-gutter-x) * -.5);
    }
    // hide scrollbar
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.glossary-pagination__character{
    width: 37px;
    height: 34px;
    display: inline-flex;
    font-size: var(--body-sm);
    font-weight: 500;
    a{
        color: var(--color-body);
        padding: 11px 13px;
    }
    a.empty{
        opacity: .5;
        pointer-events: none;
    }
    a.in-view{
        color: var(--color-primary);
        .character--element{
            border-bottom: 2px solid var(--color-primary);
        }
        
    }
}

// glossar list
.glossary-list{
    display: inline-flex;    
    @include media-breakpoint-up(md){
        display: flex;
        flex-wrap: wrap;
    }
}
.glossary-list__character{
    width: 100%;
    margin-top: 90px;
    margin-bottom: 0;
    padding: 0 0 15px;
    border-bottom: 1px solid var(--color-body);

    &:first-child{
        margin-top: 40px;
    }

    @include media-breakpoint-up(md){
        margin-top: 150px;
    }
}
.glossary-list__row{
    display: flex;
    flex-wrap: wrap;
}
.glossary-list__name,
.glossary-list__description{
    margin: 0;
}
.glossary-list__name{
    font-weight: 500;
    padding: 30px 0 15px;
    @include media-breakpoint-up(md){
        border-bottom: 1px solid var(--color-body);
        padding: 30px 0;
    }
}
.glossary-list__description{
    font-weight: 400;
    border-bottom: 1px solid var(--color-body);
    padding: 0 0 30px;
    @include media-breakpoint-up(md){
        padding: 30px 0;
    }
}

// tool tip
.dpnglossary.tooltip--link{
    font-weight: 500;
    color: var(--color-primary);
    border-bottom: 2px dotted var(--color-primary);
    @include hoverDeviceQuery {
		&:hover{
            color: var(--color-primary);
            border-bottom: 2px dotted var(--color-primary);
        }
    }
}
