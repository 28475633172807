.frame-type-table{
	.title-xl,
	.title-lg,
	.title-md{
		margin-bottom: #{$spacer * 3};
	}
	.title-sm{
		margin-bottom: #{$spacer * 2};
	}
}
.ce-table {
	margin-bottom: $spacer;
	background-color: var(--color-white);
	font-size: var(--body-sm);
	th{
		background-color: var(--color-grey);
	}
	th,
	td {
		padding: 16px 10px 14px;
		border-color: var(--color-body) !important;
		p{
			font-size: var(--body-sm);
			margin: 0;
		}
	}
	th p{
		font-weight: var(--body-medium);
	}
}

.ce-table-striped {
	tbody {
		tr {
			&:nth-of-type(even) {
				background-color: var(--color-grey);
			}
		}
	}
}

.ce-table-bordered {
	th,
	td {
		border: $table-border-width solid $table-border-color;
	}
}

.ce-table{
	caption{
		font-size: var(--body-xs);
    	line-height: var(--body-mid);
		padding-left: 10px;
		color: var(--color-body);
	}
}
