$card_hover_animation_dur: 0.3s;
$card_hover_animation_curve: cubic-bezier(0.21, 0.8, 0.37, 0.96);
/**************** CARD ***************/
.card{
    display: block;
    position: relative;
    padding: 0 calc(var(--bs-gutter-x) * .5);
    cursor: pointer;
}
.card__imagelabal{
    position: absolute;
    right: calc(var(--bs-gutter-x) * -.5);
    top: 0;
    
    width: math.div(100%,3);
    height: 0;
    padding-bottom: math.div(100%,3);

    background: var(--color-white);
    border-radius: 50%;
    overflow: hidden;
    z-index: 3;
    box-sizing: content-box;

    img{
        width: 80%;
        min-width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.card__image{
    position: relative;
    width: 100%;
    padding-bottom: 77%;
    z-index: 1;
    img{
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
        height: 100%;
        object-fit: cover;
        // hover
        transition: transform $card_hover_animation_dur $card_hover_animation_curve;
    }
}
.card__content{
    background: var(--color-white);
    margin-top: calc(var(--bs-gutter-x) * -3);

    padding: calc(var(--bs-gutter-x) * 3) 25px 20px 25px;

    transform: translateX(15px);

    @include media-breakpoint-down(md) {
        transform: translateX(10px);
        padding: calc(var(--bs-gutter-x) * 3) 20px 18px 20px;
    }
    // hover
    transition: transform $card_hover_animation_dur $card_hover_animation_curve;
}
.card__metalist{
    display: flex;
    flex-direction: row;
    justify-content: flex-end; // ?
    padding: 0;
    margin: 10px 0 8px;
    height: 37px;
    @include media-breakpoint-down(md) {
        margin: 10px 0 8px;
    }
}
.card__metaitem{
    @include make-col(1, 3);
    align-self: center;

    font-size: var(--body-xs);
    font-weight: var(--body-medium);
    color: var(--color-body);
    height: 24px;
    align-self: center;
    @include media-breakpoint-down(md) {
        width: auto;
        margin-right: 24px;
    }
    svg{
        height: 24px;
        margin-right: 8px;
    }
}
.card__text{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    min-height: 38px;
}

.card__title{
    margin: 0;
    font-size: var(--body-lg);
    font-weight: var(--body-medium);
    line-height: var(--body-mid-low);
}
.card__button{
    flex-shrink: 0;
    margin-left: 32px;
}

// hover
@include hoverDeviceQuery {
    .card:hover{
        .card__content{
            transform: translateX(0);
        }
        .card__image{
            img{
                transform-origin: center;
                transform: scale(0.9);
                @include media-breakpoint-up(lg) {
                    transform: scale(0.95);
                }
            }
        }
    }
}
.card--nolink{
    pointer-events: none;
    --color-topic: var(--color-grey);
}

// ANIMATE
.content-cheesereference,
.content-storycards,
.content-reciepecards,
.content-cheesecards{
    --animation-delay: 0;

    .js-animate-item{
        opacity: 0;
        transform: scale(0.85);
        transition: opacity 0.3s cubic-bezier(0.21, 0.8, 0.37, 0.96) var(--animation-delay), transform 0.5s cubic-bezier(0.21, 0.8, 0.37, 0.96) var(--animation-delay);
    }

    .js-animate-item.animation-done,
    &.in-view .js-animate-item{
        opacity: 1;
        transform: scale(1);
    }

    .js-animate-title{
        clip-path: inset(0 -1000% 100% 0);
        transition: clip-path 0.3s cubic-bezier(0.21, 0.8, 0.37, 0.96);
    }

    .js-animate-title.animation-done,
    &.in-view .js-animate-title{
        clip-path: inset(0 -1000% -100% 0);
    }
}


.card__tags{
    width: 100%;
    max-width: calc(325px + var(--bs-gutter-x));
    margin: 15px auto 0;
    @include media-breakpoint-down(md) {
        margin-top: 12px;
    }
    .tags{
        margin-top: 15px;
    }
}


