.textimages__imagebox{

}
.textimages__infographic,
.textimages__titlebox,
.textimages__textbox{
	position: relative;
    display: flex;
    margin-right: calc(var(--bs-gutter-x) * -.5);
    margin-left: calc(var(--bs-gutter-x) * -.5);	
}
.textimages__titlebox{
    margin-bottom: #{$spacer};
    @include media-breakpoint-up(md) {
        margin-bottom: #{$spacer * 3};
    }
}

.textimages__textbox::before{
	content: "";
    position: absolute;
    top: 0;
    height: 100%;
    background-color: var(--color-white);

    width: calc(83.33333333% - var(--bs-gutter-x) * .5 + var(--container-padding-x));
    left: calc(16.66666667% + var(--bs-gutter-x) * .5);
    max-width: 800px;
    @include media-breakpoint-up(md) {
        width: calc(100% - var(--bs-gutter-x));
        left: calc(var(--bs-gutter-x) * .5);
    }
}
.textimages__titleinner,
.textimages__textinner{
    position: relative;
    padding: var(--box-padding-y) calc(var(--bs-gutter-x) * .5);
	@include media-breakpoint-up(md) {
		@include make-col(4, 6);
		@include make-col-offset(1, 6);
	}
    h2,
    p{
        max-width: calc(800px - 40%);
    }
}
.textimages__titleinner{
    padding-bottom: 0 calc(var(--bs-gutter-x) * .5);
}

.textimages__textinner{
    @include media-breakpoint-down(md) {
        padding: 30px 40px;
        width: calc(83.33333333% - var(--bs-gutter-x) * .5 + var(--container-padding-x));
        margin-left: calc(16.66666667% + var(--bs-gutter-x) * .5);
    }
    p:last-child{
        margin-bottom: 0;
    }
}
.textimages__textinner--infographic{
    p, li{
        font-size: var(--body-xl);
    }
    p:last-child{
        margin-bottom: 0;
    }
}