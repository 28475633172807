.mask-mask_infographic {
  --infographic-point-size-zero: 19px;
  --infographic-point-size-min: 15px;
  --infographic-point-space-between: -10px;

  --infographic-progressbar-min-width: 2px;

  @include media-breakpoint-up(md) {
    --infographic-point-space-between: -20px;
  }
  --color-infographic-red: #d97d65;
  --color-infographic-red-hover: #df907c;
  --color-infographic-red-past: #e6e6e6;
  --color-infographic-yellow: #ffcc73;
  --color-infographic-yellow-hover: #e0af58;
  --text-modal-height: 100vh;

  --color-default: var(--color-infographic-red);
  --color-topic: var(--color-story);

  max-width: 1800px;
  margin: 0 auto;

  @import './Layout';
  @import './Modal';
  @import './Progress';
  @import './Sections';
}

// Globals affect outer scope
@import './Global';
