.ce-uploads {
	span {
		display: block;
	}

	.ce-uploads-fileName {
		text-decoration: none;
		font-weight: $font-weight-bold;
	}

	[class*='ce-uploads-element-'] {
		padding-left: 32px;
		padding-top: 3px;
		position: relative;
		margin: 1em 0;

		// default file-icon
		&::before {
			@include icon--default-settings;
			@include icon--file;
			width: 20px;
			top: 0;
		}
	}
}
