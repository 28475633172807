
.gallery .carousel-item{
    transition: transform 0.25s cubic-bezier(0.02, 0.04, 0.05, 1.02);
}
.indicatorsbox{
    position: absolute;
    display: flex;
    height: 22px;
    top: 7px;
    @include media-breakpoint-up(md){
        top: 15px;
    }
}
// current number wrapper
.carousel-indicators{
    position: relative;
    display: flex;
    top: 0;
    bottom: initial;
    margin: initial;
    align-content: center;
    justify-content: flex-start;
    width: 22px;
    height: 22px;
    overflow: hidden;
}
// current slide index
.carousel__indicator[data-bs-target]{
    position: absolute;

    margin: initial;
    background: none;
    border: none;
    height: initial;
    width: 22px;
    display: inline-block;
    text-indent: initial;
    text-align: center;

    // animate
    transform: translateY(-100%);
    opacity: 0;

    transition: transform 0.25s cubic-bezier(0.02, 0.04, 0.05, 1.02), opacity 0s linear 0.3;

    &.active{
        transform: translateY(0);
        opacity: 1;
        transition: transform 0.25s cubic-bezier(0.02, 0.04, 0.05, 1.02);
    }
    &.active + .carousel__indicator[data-bs-target]{
        transform: translateY(100%);
    }
   
    // &:first-child:not(.active){
    //     transform: translateY(100%);
    // }
}
.carousel__indicator:last-child{
    &.active .carousel__indicator[data-bs-target="1"]{
        transform: translateY(100%);
    }
}
// line between separator
.carousel__separator{
    display: inline-block;
    width: 38px;
    height: 2px;
    background-color: var(--color-body);
    margin: 8px 12px;
}
.carousel__total{
    display: inline-block;
    width: 18px;
    text-align: center;
    height: 22px;
}

.carousel-inner{
    width: calc(100% + var(--container-padding-x));
}