.video-small__title {
  margin-bottom: 30px;
  @include media-breakpoint-up(md) {
    margin-bottom: 60px;
    margin-top: 150px;
  }
}

.video-small__text {
  p {
    font-size: var(--body-lg);
    max-width: 650px;
    @include media-breakpoint-down(md) {
      font-size: var(--body-md);
    }
  }
}

// portrait
.video-small__video {
  video,
  iframe {
    width: 100%;
  }
}

// video ratio
.video-small__video--portrait {
  iframe {
    aspect-ratio: 9 / 16;
  }
}
.video-small__video--landscape {
  iframe {
    aspect-ratio: 16 / 9;
  }
}

// landsacape left right
@include media-breakpoint-up(md) {
  .video-small__row--left {
    .video-small__video {
      order: -1;
      @include make-col-offset(1, 12);
    }
  }

  .video-small-portrait {
    .video-small__text-or-video {
      @include make-col-offset(1, 12);

      .video-small__video {
        // only video no text
        @include make-col-offset(1, 6);
      }
    }

    .video-small__video {
      // only video no text
      @include make-col-offset(1, 12);
    }

    .video-small__row--right {
      .video-small__text {
        @include make-col-offset(1, 12);
      }

      .video-small__text-or-video-nobodytext {
        @include make-col-offset(1, 2);

        .video-small__title {
          @include make-col-offset(1, 6);
        }

        .video-small__video {
          @include make-col-offset(1, 3);
        }
      }
    }
  }

  .video-small-landscape {
    .video-small__row--right {
      .video-small__text {
        @include make-col-offset(1, 12);
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .video-small__text {
    @include make-col-offset(2, 12);
  }
}
