// default
.frame-type-form_formframework,
.frame-type-table,
.content-element,
.mask-element{
	margin-bottom: $spacebetween-elements-md;
	@include media-breakpoint-up(md) {
		margin-bottom: $spacebetween-elements;
	}
	&:last-child{
		margin-bottom: 0;
	}
}
// space-before and space-after
.frame-space-before-small {
	margin-top: $space-before-after-small-xs;

	@include media-breakpoint-up(lg) {
		margin-top: $space-before-after-small-lg;
	}
}

.frame-space-after-small {
	margin-bottom: $space-before-after-small-xs;

	@include media-breakpoint-up(lg) {
		margin-bottom: $space-before-after-small-lg;
	}
}

.frame-space-before-large {
	margin-top: $space-before-after-large-xs;

	@include media-breakpoint-up(lg) {
		margin-top: $space-before-after-large-lg;
	}
}

.frame-space-after-large {
	margin-bottom: $space-before-after-large-xs;

	@include media-breakpoint-up(lg) {
		margin-bottom: $space-before-after-large-lg;
	}
}
