// Title classes

// variable size max
$xxl-font-size: 105px; 
$xl-font-size: 80px;  
$lg-font-size: 66px; 
$md-font-size: 50px;
$sm-font-size: 38px;
$xs-font-size: 28px;
// variable size min
$xxl-font-size-min: 57px;
$xl-font-size-min: 57px;
$lg-font-size-min: 48px;
$md-font-size-min: 23px;
$sm-font-size-min: 21px;
$xs-font-size-min: 21px;
// variable size mobile fixed
$xxl-font-size-mobile: 48px;
$xl-font-size-mobile: 48px;
$lg-font-size-mobile: 37px;
$md-font-size-mobile: 28px;
$sm-font-size-mobile: 21px;
$xs-font-size-mobile: 21px;

$titlesizes: (
	"xxl":($xxl-font-size-min,$xxl-font-size,$xxl-font-size-mobile),
	"xl": ($xl-font-size-min, $xl-font-size, $xl-font-size-mobile),
	"lg": ($lg-font-size-min, $lg-font-size, $lg-font-size-mobile),
	"md": ($md-font-size-min, $md-font-size, $md-font-size-mobile ),
	"sm": ($sm-font-size-min, $sm-font-size, $sm-font-size-mobile),
	"xs": ($xs-font-size-min, $xs-font-size, $xs-font-size-mobile),
);

h1,
h2,
h3,
h4 {
	color: $headings-color; 
	font-weight: $headings-font-weight;
	line-height: 1;

	a {
		color: $headings-color;
		@include hoverDeviceQuery {
			&:hover,
			&:focus {
				color: $headings-color;
			}
		}
	}
}


h1 {
	margin: 0 0 1.8rem;
}
h2 {
	margin: 0 0 .7rem;
}
h3 {
	margin: 0 0 $paragraph-margin-bottom;
}

h4 {
	margin: 0 0 $paragraph-margin-bottom;
}
h5,h6{
	line-height: 130%;
	font-family: var(--bs-body-font-family);
	font-size: var(--body-lg);
	@include media-breakpoint-up(md){
		font-size: var(--body-xl);
	}
}
h6{
	font-weight: 400;
}
.body{
	font-family: var(--bs-body-font-family);
}

.title-xxl,
.title-xl,
.title-lg,
.title-md{
	font-family: var(--bs-headings-font-family);
}

$i: 0;
@each $titlesizename, $titlesizes in $titlesizes {
	$i: $i + 1;
	.title-#{$titlesizename}{
		@include fluid-type($headline-min-vw, $headline-max-vw, nth($titlesizes,1), nth($titlesizes,2), nth($titlesizes,3));
		line-height: var(--body-low);
	}
	.title-mobile-#{$titlesizename}{
		@include media-breakpoint-down(md){
			font-size: nth($titlesizes,3) !important;
		}
		// @include media-breakpoint-up(md){
		// 	@include fluid-type($headline-min-vw, $headline-max-vw, nth($titlesizes,1), nth($titlesizes,2), nth($titlesizes,3));
		// 	line-height: var(--body-low) !important;
		// }
	}
	h#{$i}{
		@include fluid-type($headline-min-vw, $headline-max-vw, nth($titlesizes,1), nth($titlesizes,2), nth($titlesizes,3));
		line-height: var(--body-low);
	}
	code.title-#{$titlesizename},
	code.h#{$i}{
		display: flex;
		flex-direction: column;
		font-size: var(--body-sm) !important;
		--current-size: "?";
		position: relative;
		order: 0;
		line-height: 1.5;
		margin: 20px 0 40px;
		padding-left: 40px;
		border-top: 1px solid #d63384;
		&::before,
		&::after{
			content: "Desktop: #{nth($titlesizes,1)} - #{nth($titlesizes,2)} ( #{$headline-min-vw} - #{$headline-max-vw} ) | mobile: #{nth($titlesizes,3)}  ( < #{$headline-min-vw} ) ) ";
			font-size: 0.875em;
			word-wrap: break-word;
			color: #d63384;
			padding: 0;
			order: 1;
		}
		&::after{
			content: "current size: " attr(current-size);
			padding-right: var(--current-size);
			display: block;
			font-weight: var(--body-medium);
			order: 2;
		}
	}
}

.title-sm{
	font-family: var(--bs-font-sans-serif);
	&--serif{
		font-family: var(--bs-headings-font-family);
	}
}

.title--word-break{
	@include media-breakpoint-down(md){
		word-wrap: break-word;
	}
}
