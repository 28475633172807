#photlist{
    display: none;
    &.show{
        display: flex;
    }
}
.contestoverlay{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    background: var(--color-white);
    z-index: 1060;
    padding-bottom: 80px;
    transition: transform 0.18s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: translateY(100%);
    @include media-breakpoint-down(md) {
        padding-bottom: #{$spacer * 3};
    }
    .btn-row.dropup{
        margin: 0 !important;
        align-self: flex-end;
    }
    .dropdownShare{
        box-shadow: 0 0 5px var(--color-grey);
    }
}
#contestoverlay-backdrop{
    display: none;
    position: absolute;
    background: rgba(0,0,0,.01);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1059;
    inset: 0;
}

.contestoverlay.open{
    transform: translateY(0);
    & + #contestoverlay-backdrop{
        display: block;
    }
}

.col--competitor-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .btn-row{
        margin-top: auto;
        margin-bottom: 0;
    }
}
.competitor-image{
    opacity: 0;
    width: 100%;
    height: calc(80vh - 160px);
    object-position: center;
    object-fit: contain;
    transition: opacity 0.18s cubic-bezier(0.165, 0.84, 0.44, 1), transform 0.18s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: translateY(20px);
    @include media-breakpoint-down(md) {
        height: calc(60vh - 160px);
        margin-top: #{$spacer * 3};
    }
    &.show{
        opacity: 1;
        transform: translateY(0);
    }
}
[data-voting-status="notStarted"]{
    .contestoverlay-vote-button,
    .card__metaitem--vote{
        display: none;
    }
    .card__metalist--vote{
        height: 0;
        margin: 0;
    }
}
[data-voting-status="closed"]{
    .btn--vote-big,
    .card__metaitem--vote{
        pointer-events: none;
    }
    .btn--vote-big::before{
        border-width: 0;
    }
    .has-voted-info-box{
        display: none !important;
    }
}
.contestcards__container{
    margin-bottom: #{$spacer * 6};
    @include media-breakpoint-up(lg) {
        margin-bottom: #{$spacer * 12};
    }
}
.card--contest{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}
.card__content--contest{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
}
.card__image--contest{
    padding-bottom: 100%;
}
.has-voted{
    .has-voted-info-box{
        display: flex;
    }
}
.has-voted-info-box{
    display: none;
    max-width: 130px;
    justify-content: center;
    margin: 0 0 0 10px;;
    .icon{
        width: 21px;
        height: 21px;
        flex-shrink: 0;
        flex-grow: 0;
        margin-right: 10px;
    }
    span{
        font-size: var(--body-xs);
        line-height: var(--body-low);
        flex-grow: 1;
    }
}
.no-scroll{
    position: fixed;
    left: 0;
    right: 0;
    padding-right: 15px;
}

.btn-row--contestoverlay{
    @include media-breakpoint-down(md) {
        justify-content: space-between;
    }
    @include media-breakpoint-up(md) {
        .btn{
            margin-top: #{$spacer};
        }
    }
}

.btn--vote{
    .icon{
        transition: transform 0.18s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    &:disabled{
        opacity: 1;
    }
}
.btn--voted{
    pointer-events: none;
    .icon--vote{
        transform: scale(1.5);
    }
    &:disabled{
        opacity: 1;
    }
}
.btn--vote-big{
    .icon{
        fill: none;
        stroke-width: 1.5px;
    }
    @include hoverDeviceQuery {
        &:hover{
            .icon{
                fill: none;
                stroke: var(--color-white);
            }
        }
    }
}

.is-loading,
.has-voted{
    .btn--vote-big,
    .btn--vote{
        pointer-events: none;
        &:disabled{
            opacity: 1;
        }
    }
}