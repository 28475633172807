// colors
:root {
	--color-white:#FFFFFF;
	--color-grey: #F5F5F7;
	--color-black:#2D2D2D;

	--color-default: #E30613;
	--color-inspiration: #58B882;
	--color-story: #B2C9D5;
	--color-cheese: #FFD373;

	--color-grey-10: #e5e5e5;
	--color-grey-20: #dadada;
	--color-grey-40: #b2b2b2;
	--color-grey-60: #878787;
	--color-grey-80: #575756;

	--color-primary: var(--color-default);
	--color-secondary: var(--color-grey-10);


	--color-body: var(--color-black);

    // tobics colors
    --color-topic: var(--color-default);
    --color-active: var(--color-topic);
}

// backup colors for icons
$black: #000;
$grey-60: #878787;
$grey-80: #575756;

$body-color: $black;

// TEST
.bg-grid{
	border: 1px solid black;
	background:
        linear-gradient(-90deg, rgba(0,0,0,.05) 1px, transparent 1px),
        linear-gradient(rgba(0,0,0,.05) 1px, transparent 1px), 
        linear-gradient(-90deg, rgba(0, 0, 0, .04) 1px, transparent 1px),
        linear-gradient(rgba(0,0,0,.04) 1px, transparent 1px),
        linear-gradient(transparent 3px, #f2f2f2 3px, #f2f2f2 78px, transparent 78px),
        linear-gradient(-90deg, #aaa 1px, transparent 1px),
        linear-gradient(-90deg, transparent 3px, #f2f2f2 3px, #f2f2f2 78px, transparent 78px),
        linear-gradient(#aaa 1px, transparent 1px),
        #f2f2f2;
    background-size:
        4px 4px,
        4px 4px,
        80px 80px,
        80px 80px,
        80px 80px,
        80px 80px,
        80px 80px,
        80px 80px;
}