.content-cheesecards{
    .cheesecards__container:nth-child(1){
        .card{
            margin-top: 0;
        }   
    }
    @include media-breakpoint-up(md){
        .cheesecards__container:nth-child(2){
            .card{
                margin-top: 0;
            }   
        }
    }
    @include media-breakpoint-up(lg){
        .cheesecards__container:nth-child(3){
            .card{
                margin-top: 0;
            }   
        }
    }
}
.card--cheese{
    display: block;
    width: 325px;
    max-width: calc(100% - var(--bs-gutter-x));
    // TODO: animation opacity: 0;
    transition: opacity 1s $card_hover_animation_curve var(--delay);

    margin: #{$spacer*12} auto 0;
    @include media-breakpoint-down(md){
        margin: #{$spacer*6} auto 0;
    }
    &.item-in-view{
        opacity: 1;
    }
}
.card__image--cheese{
    position: relative;
    width: 100%;
    padding-bottom: 100%;

    background: var(--color-white);
    overflow: hidden;
    border-radius: 50%;

    z-index: 1;
    img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &::after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 3px solid var(--color-grey);
        border-radius: 50%;
}   
}
.card__content--cheese{
    position: relative;

    padding-top: 40px;
    margin: -15px calc(var(--bs-gutter-x) * -.5) 0;

    background: var(--color-white);

    transform: translateX(0);
}
// hover
.card__image--cheese{
    transition: transform 0.3s $card_hover_animation_curve;
    img{
        transition: transform 0.6s $card_hover_animation_curve;
    }
}
@include hoverDeviceQuery {
    .card--cheese:hover{
        .card__image--cheese{
            transform: scale(1.05);
            transition: transform 0.3s $card_hover_animation_curve;
            img{
                transform: scale(1.1);
                transition: transform 0.6s $card_hover_animation_curve;
            }
        }
    }
}