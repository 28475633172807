/* ABCMonumentGrotesk- latin */
@font-face {
	font-display: swap;
	font-family: 'ABC Monument Grotesk';
	font-style: normal;
	font-weight: 400;
	src: url('../Fonts/ABCMonumentGrotesk/ABCMonumentGrotesk-Regular.woff2') format('woff2'), /* Super Modern Browsers */
	url('../Fonts/ABCMonumentGrotesk/ABCMonumentGrotesk-Regular.woff') format('woff'), /* Modern Browsers */
	local('ABC Monument Grotesk');
}
@font-face {
	font-display: swap;
	font-family: 'ABC Monument Grotesk';
	font-style: normal;
	font-weight: 500;
	src: url('../Fonts/ABCMonumentGrotesk/ABCMonumentGrotesk-Medium.woff2') format('woff2'), /* Super Modern Browsers */
	url('../Fonts/ABCMonumentGrotesk/ABCMonumentGrotesk-Medium.woff') format('woff'), /* Modern Browsers */
	local('ABC Monument Grotesk');
}
