:root{
	--container-padding-x: 20px;
	@include media-breakpoint-up(md){
		--container-padding-x: 60px;
	}
}
.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm{
	width: 100%;
    padding-right: var(--container-padding-x, 60px);
    padding-left: var(--container-padding-x, 60px);
    margin-right: auto;
    margin-left: auto;
}
